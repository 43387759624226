import React, { useState, useEffect } from "react";
import { Dialog, Flex, Button, Input, Text } from "@mightybot/web-ui";

interface EditProfileDialogProps {
	isOpen: boolean;
	onClose: () => void;
	userName: string;
	jobTitle: string;
	updateUserInfo: (name: string, title: string) => Promise<void>;
	setToastData: (data: {
		title: string;
		description: string;
		status: "success" | "error" | "info";
	}) => void;
	setOpenToast: (open: boolean) => void;
}

const EditProfileDialog: React.FC<EditProfileDialogProps> = ({
	isOpen,
	onClose,
	userName,
	jobTitle,
	updateUserInfo,
	setToastData,
	setOpenToast,
}) => {
	const [name, setName] = useState(userName);
	const [title, setTitle] = useState(jobTitle);
	const [errors, setErrors] = useState<{ name?: string; title?: string }>({});

	const handleSave = async () => {
		if (validate()) {
			try {
				await updateUserInfo(name, title);
				onClose();
				setToastData({
					title: "Profile Updated",
					description: "Your profile has been successfully updated.",
					status: "success",
				});
				setOpenToast(true);
			} catch (error) {
				console.error("Profile update failed:", error);
				setToastData({
					title: "Update Failed",
					description:
						"An error occurred while updating your profile. Please try again.",
					status: "error",
				});
				setOpenToast(true);
			}
		}
	};

	useEffect(() => {
		if (isOpen) {
			setName(userName);
			setTitle(jobTitle);
			setErrors({});
		}
	}, [isOpen, userName, jobTitle]);

	const validate = (): boolean => {
		const newErrors: { name?: string; title?: string } = {};
		if (!name.trim()) newErrors.name = "Name cannot be empty.";
		if (!title.trim()) newErrors.title = "Job title cannot be empty.";
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	return (
		<Dialog.Root open={isOpen} onOpenChange={onClose}>
			<Dialog.Content style={{ maxWidth: 400, padding: 20 }}>
				<Text size="4" weight="medium">
					Edit Profile
				</Text>
				<Flex direction="column" style={{ gap: "10px", marginTop: "20px" }}>
					<label htmlFor="name">User Name</label>
					<Input
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder="Enter your name"
						error={errors.name}
					/>
					<label htmlFor="title">Job Title</label>
					<Input
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						placeholder="Enter your job title"
						error={errors.title}
					/>
					<Flex justify="end" style={{ gap: "10px", marginTop: "20px" }}>
						<Button variant="outline" onClick={onClose}>
							Cancel
						</Button>
						<Button
							onClick={handleSave}
							disabled={!name.trim() || !title.trim()}
						>
							Save Changes
						</Button>
					</Flex>
				</Flex>
			</Dialog.Content>
		</Dialog.Root>
	);
};

export default EditProfileDialog;
