import React from "react";
import { Flex, Icon, Text } from "@mightybot/web-ui";

type FilterCardProps = {
	label: string;
	onRemove: (filterKey: string) => void;
	filterKey: string;
};

export const FilterCard = ({ label, onRemove, filterKey }: FilterCardProps) => {
	return (
		<Flex
			key={filterKey}
			direction="row"
			justify="center"
			align="center"
			style={{
				paddingLeft: "14px",
				gap: "10px",
				borderRadius: "15px",
				height: "30px",
				backgroundColor: "var(--gray-3)",
			}}
		>
			<Text>{label}</Text>
			<Flex
				justify="center"
				align="center"
				style={{
					cursor: "pointer",
					borderRadius: "50%",
					width: "30px",
					height: "30px",
					backgroundColor: "var(--gray-4)",
				}}
			>
				<Icon.X
					onClick={() => {
						onRemove(filterKey);
					}}
				/>
			</Flex>
		</Flex>
	);
};
