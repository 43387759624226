import React from "react";
import People from "../components/People";
import Layout from "../components/Layout";

const PeopleRoute: React.FC = () => {
	return (
		<Layout>
			<People />
		</Layout>
	);
};

export default PeopleRoute;
