import React, { useEffect, useRef, useState } from "react";
import { UUID } from "crypto";
import {
	ChatInputArea,
	GoToEndButton,
	GoToEndButtonContainer,
	SearchDialogContainer,
	SearchDialogActions,
	AddButton,
	CancelButton,
} from "./styled";
import ChatBox from "../ChatBox";
import {
	useSearchSelection,
	useConversation,
	useModelSelection,
} from "@mightybot/core";
import { useNavigate, useParams } from "react-router-dom";
import { useStreaming } from "../../contexts/StreamingContext";
import { Icon, Dialog, Text, Flex } from "@mightybot/web-ui";
import Search from "../Search/Search";

interface ChatsInputProps {
	showGoToEnd: boolean;
	handleGoToEnd: () => void;
}

const ChatsInput: React.FC<ChatsInputProps> = ({
	showGoToEnd,
	handleGoToEnd,
}) => {
	const { selectedResults, clearSelection, setInitialSelectedResults } =
		useSearchSelection();
	const { getSelectedModel } = useModelSelection();
	const navigate = useNavigate();
	const inputRef = useRef<HTMLDivElement>(null);

	const { chatId: activeConversationId } = useParams();
	const {
		sendMessage: storeConversation,
		updateMessage,
		conversation,
	} = useConversation(activeConversationId as UUID);

	const { startStreaming, isStreaming } = useStreaming();

	const [inputHeight, setInputHeight] = useState(0);
	const [showSearchDialog, setShowSearchDialog] = useState(false);

	useEffect(() => {
		if (!inputRef.current) return;

		const updateInputHeight = () => {
			const inputArea = inputRef.current;
			if (inputArea) {
				setInputHeight(inputArea.offsetHeight);
			}
		};

		// Initial measurement
		updateInputHeight();

		// Set up resize observer to track height changes
		const resizeObserver = new ResizeObserver(updateInputHeight);
		const inputArea = inputRef.current;
		if (inputArea) {
			resizeObserver.observe(inputArea);
		}

		return () => resizeObserver.disconnect();
	}, [inputRef.current]);

	useEffect(() => {
		if (
			conversation?.selected_context_items &&
			conversation.selected_context_items.length > 0
		) {
			setInitialSelectedResults(conversation.selected_context_items);
		}
	}, [conversation]);

	const handleSendMessage = async (message: string) => {
		if (isStreaming) return;

		const context = selectedResults.map((result) => ({
			type: "metadata",
			sub_type: result.app?.toLowerCase().replace(" ", "_") || "",
			value: {
				metastore_id: result.id,
			},
		}));

		try {
			let currentConversationId = activeConversationId;

			// If no active conversation, create one and navigate
			if (!currentConversationId) {
				currentConversationId = await storeConversation({
					selected_items: selectedResults,
				});

				// Ensure we have a conversation ID before proceeding
				if (!currentConversationId) {
					throw new Error("Failed to create conversation");
				}

				// Navigate to new conversation
				navigate(`/chats/${currentConversationId}`);
			}

			const selectedModel = getSelectedModel();
			// Start streaming only after navigation
			await startStreaming({
				context,
				user_question: message,
				llm_model: selectedModel.id,
				collection_type: "CONVERSATION",
				collection_id: currentConversationId,
			});
		} catch (error) {
			console.error("Error sending message:", error);
		}
	};

	const handleSelectData = () => {
		setShowSearchDialog(true);
	};

	const newChat = !activeConversationId;

	const handleCancelSearch = () => {
		setShowSearchDialog(false);
	};

	const handleClearSelection = () => {
		clearSelection();
	};

	const handleAddSelection = async () => {
		if (activeConversationId && conversation) {
			try {
				await updateMessage({
					selected_items: selectedResults,
				});
			} catch (error) {
				console.error(
					"Failed to update conversation with new selections:",
					error,
				);
			}
		}
		setShowSearchDialog(false);
	};

	return (
		<ChatInputArea
			newchat={newChat ? "true" : "false"}
			id="chats-input-area"
			ref={inputRef}
		>
			<GoToEndButtonContainer
				className={showGoToEnd ? "visible" : ""}
				style={{ bottom: `${inputHeight}px` }}
			>
				<GoToEndButton onClick={handleGoToEnd}>
					<Icon.ArrowDown
						fill="#333"
						style={{ width: "20px", height: "20px", flexShrink: 0 }}
					/>
				</GoToEndButton>
			</GoToEndButtonContainer>
			<ChatBox
				onSend={handleSendMessage}
				placeholder="Type a message..."
				showSelectedCount={true}
				onSelectData={handleSelectData}
				disabled={isStreaming}
			/>

			<Dialog.Root open={showSearchDialog} onOpenChange={setShowSearchDialog}>
				<SearchDialogContainer>
					<Dialog.Title style={{ display: "none" }} />
					<Search isDialog={true} />
					<SearchDialogActions>
						<Text onClick={handleClearSelection} style={{ cursor: "pointer" }}>
							Clear Selection
						</Text>
						<Flex gap="10px">
							<CancelButton variant="white-bg" onClick={handleCancelSearch}>
								Close
							</CancelButton>
							<AddButton
								hasselection={selectedResults.length > 0 ? "true" : "false"}
								disabled={selectedResults.length === 0}
								onClick={handleAddSelection}
							>
								{selectedResults.length > 0 ? "Update" : "Add"}
							</AddButton>
						</Flex>
					</SearchDialogActions>
				</SearchDialogContainer>
			</Dialog.Root>
		</ChatInputArea>
	);
};

export default ChatsInput;
