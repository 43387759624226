import { useIntegrationConnect } from "@mightybot/core";
import React from "react";
import { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import Loading from "../components/Loading";

const getIntegrationAppId = (appPath: string) => {
	const appId = appPath.split("/")[0];
	return appId;
};

export default function AuthRedirectRoute() {
	const [params] = useSearchParams();
	const { "*": appPath } = useParams();

	const { connectIntegration } = useIntegrationConnect();

	useEffect(() => {
		const sendAuthCode = async () => {
			try {
				await connectIntegration({
					appPath: appPath!,
					params: Object.fromEntries(params.entries()),
				});
				const appId = appPath ? getIntegrationAppId(appPath) : undefined;
				if (appId) localStorage.setItem(appId, "true");
				// @ts-ignore
				if (chrome.runtime?.sendMessage) {
					// @ts-ignore
					chrome.runtime.sendMessage(
						process.env.REACT_APP_PUBLIC_EXTENSION_ID,
						"app-connected",
					);
				}
				// Close the current window
				window.close();
			} catch (error) {
				console.error("Error connecting integration:", error);
			}
		};
		void sendAuthCode();
	}, []);
	return <Loading />;
}
