import React from "react";
import PageSelector from "../PageSelector";
import { Flex, Icon, Text } from "@mightybot/web-ui";

interface PaginationProps {
	page: number;
	total: number;
	perPage: number;
	onPageChange: (newPage: number) => void;
}

export default function Pagination({
	page,
	total,
	perPage,
	onPageChange,
}: PaginationProps) {
	if (total <= perPage) {
		return null;
	}
	const totalPages = Math.ceil(total / perPage);

	const handlePrevious = () => {
		if (page > 1) {
			onPageChange(page - 1);
		}
	};

	const handleNext = () => {
		if (page < totalPages) {
			onPageChange(page + 1);
		}
	};
	return (
		<Flex
			align="center"
			justify="center"
			style={{ gap: "20px", padding: "12px 26px" }}
		>
			<Flex justify="center" style={{ gap: "6px" }} onClick={handlePrevious}>
				<Icon.CaretLeft
					size="20px"
					color={page === 1 ? "var(--gray-6)" : "var(--gray-8)"}
					style={{ cursor: "pointer" }}
				/>
				{/* @ts-ignore */}
				<Text
					style={{
						marginTop: 2,
						cursor: "pointer",
						color: page === 1 ? "var(--gray-6)" : "var(--gray-8)",
					}}
				>
					Previous
				</Text>
			</Flex>
			<Flex
				align="center"
				justify="between"
				style={{ gap: "10px", flexWrap: "nowrap", width: "10%" }}
			>
				<PageSelector
					value={page}
					data={Array.from(
						{ length: Math.ceil(total / perPage) },
						(_, i) => i + 1,
					)}
					onChange={onPageChange}
					placeholder="Page"
				/>
				<Text style={{ width: "40%" }}>of {Math.ceil(total / perPage)}</Text>
			</Flex>
			<Flex justify="center" style={{ gap: "6px" }} onClick={handleNext}>
				{/* @ts-ignore */}
				<Text
					style={{
						marginTop: 2,
						cursor: "pointer",
						color: page * perPage >= total ? "var(--gray-6)" : "var(--gray-8)",
					}}
				>
					Next
				</Text>
				<Icon.CaretRight
					size="20px"
					color={page * perPage >= total ? "var(--gray-6)" : "var(--gray-8)"}
					style={{ cursor: "pointer" }}
				/>
			</Flex>
		</Flex>
	);
}
