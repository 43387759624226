import React, { useState, RefObject } from "react";
import { Flex } from "@mightybot/web-ui";
import MeetingChats from "../MeetingChats";
import {
	MeetingContentScrollerOuterContainer,
	MeetingContentScrollerInnerContainer,
} from "./styled";
import MeetingContentDetails from "./MeetingContentDetails";
import MeetingContentPreview from "./MeetingContentPreview";
import { useParams } from "react-router-dom";

interface MeetingContentScrollerProps {
	endOfContentRef: RefObject<HTMLDivElement>;
}

export interface WidgetPosition {
	top: number;
	left: number;
}

const MeetingContentScroller: React.FC<MeetingContentScrollerProps> = ({
	endOfContentRef,
}) => {
	const { view } = useParams();
	const [showWidget, setShowWidget] = useState(false);

	return (
		<MeetingContentScrollerOuterContainer
			id="meeting-content-scroller"
			direction="column"
			justify="between"
		>
			<MeetingContentScrollerInnerContainer>
				<Flex
					direction="column"
					onMouseEnter={() => (view === "details" ? setShowWidget(true) : null)}
					style={{ width: "100%" }}
				>
					{view === "details" ? (
						<MeetingContentDetails showWidget={showWidget} />
					) : (
						<MeetingContentPreview />
					)}
				</Flex>
				<Flex direction="column" onMouseEnter={() => setShowWidget(false)}>
					{view === "details" && <MeetingChats />}
					{/* <MeetingChatStreaming
						completionItem={completionItem}
						updateCurrentCompletionsItem={updateCurrentCompletionsItem}
					/> */}
					<div ref={endOfContentRef} id="end-of-content" />
				</Flex>
			</MeetingContentScrollerInnerContainer>
		</MeetingContentScrollerOuterContainer>
	);
};

export default MeetingContentScroller;
