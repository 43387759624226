import React from "react";
import {
	Flex,
	AvatarWrapper,
	Tooltip,
	CustomReactMarkdown,
} from "@mightybot/web-ui";
import { MeetingChatUserMessageContainer } from "./styled";
import TruncatedMarkdown from "../TruncatedMarkdown";
import { ChatMessageProps } from "./MeetingChat";

interface MeetingChatUserMessageProps
	extends Omit<ChatMessageProps, "reply" | "isStreaming"> {}

const anonymousUser = {
	name: "Anonymous",
	profile_pic: "",
	email: "",
	id: "",
};

const MeetingChatUserMessage: React.FC<MeetingChatUserMessageProps> = ({
	timestamp,
	content,
	question,
	author,
}) => {
	const formattedQuestion = question.replace(/\\n/g, "\n");

	return (
		<MeetingChatUserMessageContainer>
			<Tooltip content={author?.name}>
				<AvatarWrapper
					src={author?.profile_pic ?? anonymousUser.profile_pic}
					alt={author?.name ?? anonymousUser.name}
					radius="full"
					styles={{
						width: "26px",
						height: "26px",
					}}
				/>
			</Tooltip>
			<Flex direction="column" style={{ width: "calc(100% - 32px)" }}>
				{/* <Flex align="center" style={{ width: "100%", gap: "4px" }}>
					<Text weight="bold" size="2">
						{author?.name ?? user?.name}
					</Text>
					<Dot />
					<Text
						weight="light"
						size="1"
						style={{ color: "var(--mb-color-light-gray-9)" }}
					>
						{timestamp}
					</Text>
				</Flex> */}
				<CustomReactMarkdown
					styles={{
						content: {
							fontSize: "14px",
							fontWeight: "light",
							whiteSpace: "pre-wrap",
						},
					}}
				>
					{formattedQuestion}
				</CustomReactMarkdown>
				{content && <TruncatedMarkdown content={content} singleLine />}
			</Flex>
		</MeetingChatUserMessageContainer>
	);
};

export default MeetingChatUserMessage;
