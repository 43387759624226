import React from "react";
import Admin from "../components/Admin";
import Layout from "../components/Layout";

const AdminRoute: React.FC = () => {
	return (
		<Layout>
			<Admin />
		</Layout>
	);
};

export default AdminRoute;
