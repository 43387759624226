import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

export const PeopleContainer = styled(Flex)`
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	margin: auto;
`;
