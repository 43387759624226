import styled from "styled-components";
import { Flex, Button } from "@mightybot/web-ui";

export const GeneralContainer = styled(Flex)`
	border: 1px solid #e0e0e0;
	border-radius: 12px;
	margin-top: 20px;
	width: 100%;
`;

export const GeneralHeader = styled(Flex)`
	padding: 0px 20px;
	border-bottom: 1px solid #e0e0e0;
	height: 72px;
	> * {
		flex-grow: 1;
	}
`;

export const GeneralContent = styled(Flex)`
	width: 100%;
	padding: 0px 20px 20px 20px;
`;

export const UserProfileSection = styled(Flex)`
	width: 100%;
	padding: 30px 0px;
	border-radius: 8px;
	border-bottom: 1px solid #e0e0e0;
`;

export const LogoutSection = styled(Flex)`
	width: 100%;
	padding: 20px 0px;
	border-radius: 8px;
`;

export const LogoutButton = styled(Button)`
	background: #ffdce5;
	color: #dc3d43;
	padding: 10px 15px;
	margin: 0px;
	cursor: pointer;
`;
