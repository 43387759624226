import React, { useMemo, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
	HomeContainer,
	HomeInnerContainer,
	HomeHeaderContainer,
	FeedContainer,
	AccordionContainer,
	AccordionTrigger,
	AccordionContent,
	AccordionItem,
	AccordionHeader,
	AccordionChevron,
	HeaderItemContainer,
	FilterTabsContainer,
	FilterTab,
	DropdownChevron,
	HomeHeaderInnerContainer,
	StyledIndexingProgress,
} from "./styled";
import { FeedItem as CoreFeedItem, useFeed } from "@mightybot/core";
import FeedCard from "./FeedCard";
import { Icon, Flex, Text, DropdownMenu, Skeleton } from "@mightybot/web-ui";
import FeedTile from "./FeedTile";
import Filter from "./Filter";
import { formatMarkdown } from "../../utils/helpers";
import { breakpoints, useMediaQuery } from "@mightybot/web-ui";
import IndexingProgress from "../IndexingProgress/IndexingProgress";

export type FeedItem = Omit<
	CoreFeedItem,
	"apps" | "links" | "company_names"
> & {
	app: string;
	link: string;
	company_name: string;
};

export const formatDescription = (description: string) => {
	const formatted = formatMarkdown(description);
	// TODO: add this back when after confirmation
	// const maxLength = 350;
	// if (formatted.length > maxLength) {
	// 	return formatted.slice(0, maxLength) + "...";
	// }
	return formatted;
};

export const hasLinks = (description: string) => {
	return (
		/<a\s+(?:[^>]*?\s+)?href=/.test(description) ||
		/\[.*\]\(.*\)/.test(description)
	);
};

const renderSkeletons = (viewMode: "list" | "tile") => {
	if (viewMode === "list") {
		return (
			<AccordionContainer type="multiple" defaultValue={["Today"]}>
				<AccordionItem value="Today">
					<AccordionHeader>
						<AccordionTrigger>
							<Flex align="center" justify="between" style={{ gap: 4 }}>
								<Skeleton width="100px" height="18px" />
								<Skeleton width="18px" height="18px" />
							</Flex>
						</AccordionTrigger>
					</AccordionHeader>
					<AccordionContent>
						{[...Array(5)].map((_, index) => (
							<Flex
								key={index}
								direction="column"
								style={{
									padding: "16px 0",
									borderBottom:
										index < 4 ? "1px solid var(--mb-gray-3)" : "none",
								}}
							>
								<Skeleton
									width="200px"
									height="24px"
									style={{ marginBottom: "8px" }}
								/>
								<Skeleton
									width="100%"
									height="40px"
									style={{ marginBottom: "8px" }}
								/>
								<Skeleton width="150px" height="20px" />
							</Flex>
						))}
					</AccordionContent>
				</AccordionItem>
			</AccordionContainer>
		);
	} else {
		return (
			<Flex wrap="wrap" style={{ gap: 10 }}>
				{[...Array(8)].map((_, index) => (
					<Skeleton key={index} width="200px" height="150px" />
				))}
			</Flex>
		);
	}
};

const Home: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const { data: feed, isLoading } = useFeed({
		page: 1,
		per_page: 200,
	});

	const [feedItems, setFeedItems] = useState<FeedItem[]>([]);

	// Use useEffect to update feedItems when feed data changes
	useEffect(() => {
		if (feed?.items) {
			setFeedItems(
				feed.items.map((item) => ({
					...item,
					app: item.apps?.[0] ?? "",
					link: item.links?.[0] ?? "",
					company_name: item.company_names?.[0] ?? "",
				})) as FeedItem[],
			);
		}
	}, [feed]);

	const [selectedCategories, setSelectedCategories] = useState<string[]>(() => {
		const categories = searchParams.get("categories");
		return categories ? categories.split(",") : [];
	});

	const [selectedApps, setSelectedApps] = useState<string[]>(() => {
		const apps = searchParams.get("apps");
		return apps ? apps.split(",") : [];
	});

	const [viewMode, setViewMode] = useState<"list" | "tile">("list");
	const [activeFilter, setActiveFilter] = useState<"category" | "app" | null>(
		null,
	);
	const dropdownTriggerRef = useRef<HTMLButtonElement>(null);
	const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);

	const onFilterOpenChange = (open: boolean) => {
		setActiveFilter(null);
		setFilterDropdownOpen(open);
	};

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		let viewParam = params.get("view");
		if (isMobile) {
			viewParam = "list";
		}
		if (viewParam === "list" || viewParam === "tile") {
			setViewMode(viewParam);
		}

		const categories = params.get("categories");
		if (categories) {
			setSelectedCategories(categories.split(","));
		}

		const apps = params.get("apps");
		if (apps) {
			setSelectedApps(apps.split(","));
		}
	}, [location.search, isMobile]);

	const handleDismiss = (dismissedItem: FeedItem) => {
		setFeedItems((prevItems) =>
			prevItems.filter((item) => item.timestamp !== dismissedItem.timestamp),
		);
	};

	const filteredFeed = useMemo(() => {
		return feedItems.filter((item) => {
			const categoryMatch =
				selectedCategories.length === 0 ||
				(item.category && selectedCategories.includes(item.category));

			//TODO: send app id from backend instead of app name
			// remove _app from selected apps
			const formattedSelectedApps = selectedApps.map((app) =>
				app.replace("_app", ""),
			);
			const appMatch =
				formattedSelectedApps.length === 0 ||
				formattedSelectedApps.includes(item.app.toLowerCase());
			return categoryMatch && appMatch;
		});
	}, [feedItems, selectedCategories, selectedApps]);

	const categorizedFeed = useMemo(() => {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);

		const sortedFeed = [...filteredFeed].sort(
			(a, b) =>
				new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
		);

		const categorized = sortedFeed.reduce(
			(acc, feed) => {
				const feedDate = new Date(feed.timestamp);
				let category;

				if (feedDate >= today) {
					category = "Today";
				} else if (feedDate >= yesterday) {
					category = "Yesterday";
				} else {
					// Format the date as "MMM DD" (e.g., "Oct 01")
					category = feedDate.toLocaleDateString("en-US", {
						month: "short",
						day: "2-digit",
					});
				}

				if (!acc[category]) {
					acc[category] = [];
				}
				acc[category].push(feed);
				return acc;
			},
			{} as Record<string, FeedItem[]>,
		);

		// Sort items within each category by score
		Object.keys(categorized).forEach((category) => {
			categorized[category].sort((a, b) => (b.score || 0) - (a.score || 0));
		});

		return categorized;
	}, [filteredFeed]);

	const handleFilterChange = (
		categories: string[],
		apps: string[],
		filterType?: "category" | "app",
		applyFilter?: boolean,
	) => {
		setSelectedCategories(categories);
		setSelectedApps(apps);
		setActiveFilter(filterType ?? null);
		setFilterDropdownOpen(true);

		if (applyFilter) {
			setFilterDropdownOpen(false);
		}

		const params = new URLSearchParams(searchParams);
		if (categories.length > 0) {
			params.set("categories", categories.join(","));
		} else {
			params.delete("categories");
		}
		if (apps.length > 0) {
			params.set("apps", apps.join(","));
		} else {
			params.delete("apps");
		}
		setSearchParams(params);
	};

	const handleTabClick = (filterType: "category" | "app") => {
		setActiveFilter(filterType);
		setFilterDropdownOpen(true);
	};

	const getFilterCount = (filterType: "category" | "app") => {
		if (filterType === "category") {
			return selectedCategories.length;
		} else {
			return selectedApps.length;
		}
	};

	const handleViewModeChange = (mode: "list" | "tile") => {
		setViewMode(mode);
		const params = new URLSearchParams(location.search);
		params.set("view", mode);
		navigate(`${location.pathname}?${params.toString()}`, { replace: true });
	};

	const [openCategories, setOpenCategories] = useState<string[]>([]);

	// Use useEffect to set all categories as open when the feed data changes
	useEffect(() => {
		if (categorizedFeed) {
			setOpenCategories(Object.keys(categorizedFeed));
		}
	}, [categorizedFeed]);

	return (
		<HomeContainer>
			<HomeInnerContainer>
				<HomeHeaderContainer align="center" justify="center">
					<HomeHeaderInnerContainer align="center" justify="between">
						<Text
							size="6"
							weight="medium"
							style={{ padding: "20px 0px 10px 0px" }}
						>
							Home Feed
						</Text>
						<Flex
							align="center"
							justify="center"
							style={{ gap: 10, height: 28 }}
						>
							{/* //TODO: add this back when we have the backend ready */}
							{/* <HeaderItemContainer>
							<Icon.Checks size={20} />
							<Text>Mark all as read</Text>
						</HeaderItemContainer> */}
							{!isMobile && (
								<Flex
									align="center"
									justify="center"
									style={{ height: "100%" }}
								>
									<HeaderItemContainer
										onClick={() => handleViewModeChange("list")}
										style={{
											backgroundColor:
												viewMode === "list"
													? "var(--mb-blue-1)"
													: "transparent",
										}}
									>
										<Icon.List size={20} />
									</HeaderItemContainer>
									<HeaderItemContainer
										onClick={() => handleViewModeChange("tile")}
										style={{
											backgroundColor:
												viewMode === "tile"
													? "var(--mb-blue-1)"
													: "transparent",
										}}
									>
										<Icon.SquaresFour size={20} />
									</HeaderItemContainer>
								</Flex>
							)}
							<DropdownMenu.Root
								open={filterDropdownOpen}
								onOpenChange={onFilterOpenChange}
							>
								<DropdownMenu.Trigger ref={dropdownTriggerRef}>
									<HeaderItemContainer>
										<Icon.SlidersHorizontal size={20} />
										<Text>Filters</Text>
										<DropdownChevron size={16} />
										{(selectedCategories.length > 0 ||
											selectedApps.length > 0) && (
											<Text
												size="1"
												style={{ marginLeft: "5px", color: "var(--mb-blue-9)" }}
											>
												({selectedCategories.length + selectedApps.length})
											</Text>
										)}
									</HeaderItemContainer>
								</DropdownMenu.Trigger>
								<DropdownMenu.Content style={{ width: "180px" }}>
									<Filter
										onFilterChange={handleFilterChange}
										initialSelectedCategories={selectedCategories}
										initialSelectedApps={selectedApps}
										activeFilter={activeFilter}
									/>
								</DropdownMenu.Content>
							</DropdownMenu.Root>
						</Flex>
					</HomeHeaderInnerContainer>
				</HomeHeaderContainer>
				<Flex style={{ width: "100%", maxWidth: "870px" }}>
					<IndexingProgress />
				</Flex>
				{(selectedCategories.length > 0 || selectedApps.length > 0) && (
					<FilterTabsContainer>
						{selectedCategories.length > 0 && (
							<FilterTab
								key="category-filter"
								onClick={() => handleTabClick("category")}
							>
								<Text size="1">Notification Type</Text>
								<Text
									size="1"
									style={{ marginLeft: "5px", color: "var(--mb-blue-9)" }}
								>
									({getFilterCount("category")})
								</Text>
								<Icon.X
									size={12}
									onClick={(e) => {
										e.stopPropagation();
										handleFilterChange([], [], "category", true);
									}}
								/>
							</FilterTab>
						)}
						{selectedApps.length > 0 && (
							<FilterTab key="app-filter" onClick={() => handleTabClick("app")}>
								<Text size="1">Apps</Text>
								<Text
									size="1"
									style={{ marginLeft: "5px", color: "var(--mb-blue-9)" }}
								>
									({getFilterCount("app")})
								</Text>
								<Icon.X
									size={12}
									onClick={(e) => {
										e.stopPropagation();
										handleFilterChange([], [], "app", true);
									}}
								/>
							</FilterTab>
						)}
					</FilterTabsContainer>
				)}

				<FeedContainer>
					{isLoading ? (
						renderSkeletons(viewMode)
					) : (
						<AccordionContainer
							type="multiple"
							value={openCategories}
							onValueChange={setOpenCategories}
						>
							{Object.entries(categorizedFeed).map(([category, feeds]) => (
								<AccordionItem key={category} value={category}>
									<AccordionHeader>
										<AccordionTrigger>
											<Flex align="center" justify="between" style={{ gap: 4 }}>
												<Text
													weight="medium"
													size="2"
													style={{
														lineHeight: "18px",
														fontFamily: "fk_grotesk_neue !important",
													}}
												>
													{category}
												</Text>
												<AccordionChevron size={18} />
											</Flex>
										</AccordionTrigger>
									</AccordionHeader>
									<AccordionContent>
										{viewMode === "list" ? (
											feeds.map((feed, index) => (
												<FeedCard
													key={index}
													feed={feed}
													last={index === feeds.length - 1}
													onDismiss={handleDismiss}
												/>
											))
										) : (
											<Flex wrap="wrap" style={{ gap: 10 }}>
												{feeds.map((feed, index) => (
													<FeedTile
														key={index}
														feed={feed}
														onDismiss={handleDismiss}
													/>
												))}
											</Flex>
										)}
									</AccordionContent>
								</AccordionItem>
							))}
						</AccordionContainer>
					)}
				</FeedContainer>
			</HomeInnerContainer>
		</HomeContainer>
	);
};

export default Home;
