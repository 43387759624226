import React from "react";
import AdminSidebar from "./AdminSidebar";
import { Text } from "@mightybot/web-ui";
import { AdminContainer, AdminContent, AdminContentContainer } from "./styled";
import { useParams } from "react-router-dom";
import EnterpriseList from "../EnterpriseList";
import AutopilotList from "../AutopilotList";
import Autopilot from "../Autopilot";
import TracesList from "../TracesList";
import AutopilotTestRun from "../AutopilotTestRun";

const Admin: React.FC = () => {
	const { adminTab } = useParams<{
		adminTab: string;
	}>();
	return (
		<AdminContainer>
			<Text size="6" weight="medium" style={{ padding: "20px 0px 10px 0px" }}>
				Admin
			</Text>
			<AdminContentContainer>
				<AdminSidebar />
				<AdminContent>
					{adminTab === "enterprises" ? (
						<EnterpriseList />
					) : adminTab === "enterprise" ? (
						<AutopilotList />
					) : adminTab === "autopilot" ? (
						<Autopilot />
					) : adminTab === "autopilots" ? (
						<AutopilotList />
					) : adminTab === "traces" ? (
						<TracesList />
					) : adminTab === "test" ? (
						<AutopilotTestRun />
					) : null}
				</AdminContent>
			</AdminContentContainer>
		</AdminContainer>
	);
};

export default Admin;
