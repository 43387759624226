import React, { memo } from "react";
import { Flex, Text, Checkbox } from "@mightybot/web-ui";
import { EnterpriseResponse as Enterprise, User } from "@mightybot/core";
import { SelectedData } from "./MultiSelectionDialog";

type CheckboxWrapperProps = {
	data: User | Enterprise | { name: string; id: string };
	selectedData: SelectedData;
	handleDataSelection: (
		data: User | Enterprise | { name: string; id: string },
	) => void;
	checked?: boolean;
};
const CheckboxWrapper = ({
	data,
	selectedData,
	handleDataSelection,
	checked = false,
}: CheckboxWrapperProps) => {
	return (
		<Flex
			key={data.id}
			align="center"
			style={{
				gap: "8px",
				padding: "6px",
				borderRadius: "4px",
				cursor: "pointer",
			}}
			onClick={() => handleDataSelection(data)}
		>
			<Checkbox
				checked={
					checked ||
					selectedData?.some((u: User | Enterprise) => u.id === data.id)
				}
				onChange={() => handleDataSelection(data)}
				style={{ flexShrink: 0 }}
			/>
			<img
				src={(data as User).profile_pic || (data as Enterprise).logo}
				alt={data.name}
				style={{
					width: "25px",
					height: "25px",
					borderRadius: "50%",
					flexShrink: 0,
				}}
			/>
			<Text
				style={{
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
				}}
			>
				{data.name}
			</Text>
		</Flex>
	);
};

export default memo(CheckboxWrapper);
