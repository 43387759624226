import React, { useState } from "react";
import { MeetingPreviewContainer } from "./styled";

import MeetingsPreviewSidebar from "../MeetingsPreviewSidebar";
import MeetingPreviewContent from "../MeetingContent";
import { MeetingContentNavigationProvider } from "../../contexts/MeetingContentNavigationContext";
import { useMeetingsListBidirectional } from "@mightybot/core";

const MeetingPreview: React.FC = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const { meetings, isLoading } = useMeetingsListBidirectional({
		initialCursor: new Date().toISOString(),
	});

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<MeetingContentNavigationProvider>
			<MeetingPreviewContainer>
				<MeetingsPreviewSidebar
					isSidebarOpen={isSidebarOpen}
					toggleSidebar={toggleSidebar}
				/>
				<MeetingPreviewContent />
			</MeetingPreviewContainer>
		</MeetingContentNavigationProvider>
	);
};

export default MeetingPreview;
