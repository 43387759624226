import React, { useEffect, useState } from "react";
import { Button, Flex, Box, Icon, TextArea, Text } from "@mightybot/web-ui";
import {
	useCreateUserFeedback,
	UserFeedbackRequest,
	useUserFeedbackByEntity,
	useUpdateUserFeedback,
} from "@mightybot/core";
import Toast from "../Toast";
import { getErrorString } from "../../utils/helpers";

const REDIRECT_URL = "https://www.mightybot.ai/";

const Feedback: React.FC = () => {
	const [createUserFeedback, { isError: isCreateError, error: createError }] =
		useCreateUserFeedback();
	const [updateUserFeedback, { isError: isUpdateError, error: updateError }] =
		useUpdateUserFeedback();

	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState<{
		title: string;
		status: "success" | "error" | "info" | undefined;
		description: string;
	}>({
		title: "Feedback",
		status: "success",
		description: "",
	});

	const queryParams = new URLSearchParams(window.location.search);
	const entity_id = queryParams.get("entity_id") as any;

	const { data: userFeedback, isLoading } = useUserFeedbackByEntity({
		entityId: entity_id,
		entityType: "MAIL",
	});

	const type: "POSITIVE" | "NEGATIVE" =
		userFeedback?.type ||
		(queryParams.get("type")?.toUpperCase() === "POSITIVE"
			? "POSITIVE"
			: "NEGATIVE");

	const [feedbackData, setFeedbackData] = useState<UserFeedbackRequest>({
		entity_type: "MAIL",
		entity_id: entity_id,
		type,
		data: {
			feedback: "",
			reason: [],
		},
	});

	useEffect(() => {
		if (userFeedback?.id) {
			setFeedbackData((prev) => ({
				...prev,
				entity_type: userFeedback?.entity_type,
				entity_id: userFeedback?.entity_id,
				type: userFeedback?.type as "POSITIVE" | "NEGATIVE",
				data: {
					feedback: userFeedback?.data?.feedback,
					reason: userFeedback?.data?.reason,
				},
			}));
		}
	}, [userFeedback?.id]);

	const handleButtonClick = async () => {
		if (userFeedback?.id) {
			await updateUserFeedback({
				feedbackId: userFeedback?.id,
				feedback: feedbackData,
			}).unwrap();
			setToastData({
				title: "Feedback",
				status: "success",
				description: "Feedback updated successfully",
			});
			setOpenToast(true);
			window.open(REDIRECT_URL, "_self");
			if (isUpdateError) {
				setToastData({
					title: "Feedback",
					status: "error",
					description: getErrorString(updateError),
				});
				setOpenToast(true);
			}
		} else {
			await createUserFeedback(feedbackData).unwrap();
			setToastData({
				title: "Feedback",
				status: "success",
				description: "Feedback submitted successfully",
			});
			setOpenToast(true);
			window.open(REDIRECT_URL, "_self");
			if (isCreateError) {
				setToastData({
					title: "Feedback",
					status: "error",
					description: getErrorString(createError),
				});
				setOpenToast(true);
			}
		}
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "470px",
					border: "1px solid #e0e0e0",
					borderRadius: "16px",
					marginTop: "20%",
				}}
			>
				<Flex
					style={{
						padding: "40px",
						gap: "20px",
						height: "85%",
					}}
					direction="column"
					align="center"
					justify="center"
				>
					{feedbackData.type === "POSITIVE" ? (
						<Icon.ThumbsUp weight="fill" size={50} fill="#0554F2" />
					) : (
						<Icon.ThumbsDown weight="fill" size={50} color="#0554F2" />
					)}
					<Text
						weight="medium"
						style={{
							fontSize: "18px",
							color: "#333",
							lineHeight: "24px",
						}}
					>
						Thank you for submitting your feedback.
					</Text>
					<Flex
						direction="column"
						align="center"
						justify="center"
						style={{ gap: "4px", width: "540px" }}
					>
						<Text style={{ color: "#333" }}>
							{feedbackData.type === "POSITIVE"
								? `What did you like about the email response? (Optional)`
								: `What was the issue with the email response? How could it be improved?`}
						</Text>
						{feedbackData.type === "NEGATIVE" && (
							<Flex style={{ gap: "10px", padding: "5px" }}>
								{["Not helpful", "Not relevant", "Not accurate"].map((item) => (
									//@ts-ignore
									<Button
										key={item}
										radius="medium"
										variant="outline"
										style={{
											color: "#333",
											border: "1px solid var(--mb-color-light-gray-6)",
											boxShadow: "none",
											cursor: "pointer",
										}}
										onClick={() => {
											const reason = feedbackData?.data?.reason;
											const newReason = reason?.includes(item)
												? reason.filter((reasonItem) => reasonItem !== item)
												: [...reason, item];
											setFeedbackData((prev) => ({
												...prev,
												data: {
													...prev.data,
													reason: newReason,
												},
											}));
										}}
									>
										{feedbackData?.data?.reason?.includes(item) && (
											<Icon.Check size={16} />
										)}
										{item}
									</Button>
								))}
							</Flex>
						)}
						<TextArea
							value={feedbackData?.data?.feedback}
							placeholder="Enter your feedback here..."
							style={{ width: "100%", height: "90px" }}
							onChange={(e) =>
								setFeedbackData((prev) => ({
									...prev,
									data: {
										...prev.data,
										feedback: e.target.value,
									},
								}))
							}
						/>
					</Flex>
					{/* @ts-ignore */}
					<Button
						style={{
							borderRadius: "60px",
							height: "44px",
							cursor: "pointer",
							padding: "0px 30px",
						}}
						onClick={handleButtonClick}
					>
						Submit
					</Button>
				</Flex>
				<Flex
					align="center"
					justify="center"
					style={{
						backgroundColor: "var(--gray-2)",
						width: "100%",
						borderRadius: "0px 0px 16px 16px",
						color: "var(--gray-9)",
						padding: "20px",
						height: "15%",
					}}
				>
					<p>© MightyBot. All rights reserved.</p>
				</Flex>
			</Box>
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title || "Autopilot"}
				type={toastData.status}
				description={toastData.description || ""}
			/>
		</React.Fragment>
	);
};

export default Feedback;
