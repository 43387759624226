import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

export const MeetingChatStreamingContainer = styled(Flex)`
	width: 100%;
	gap: 8px;
	&:focus {
		outline: none;
	}
`;
