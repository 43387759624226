import {
	useAutopilot,
	User,
	Autopilot,
	useDraftAutopilots,
	useMe,
	useAutopilotAuthor,
	useEnterpriseUsers,
} from "@mightybot/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Flex,
	Button,
	Switch,
	Icon,
	Text,
	Tooltip,
	Checkbox,
	OverlappingAvatars,
} from "@mightybot/web-ui";
import MultiSelectionDialog from "../MultiSelectionDialog";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import Toast from "../Toast";
import { MoreDialog } from "./MoreDialog";
import { UUID } from "crypto";

type AutoPilotCardProps = {
	autopilot: Autopilot;
	enterpriseId: UUID | string;
	onToggleAutopilot: (autopilotId: UUID, isEnabled: boolean) => void;
	onAutopilotSelect: (autopilotId: UUID, value: boolean | string) => void;
	selectedAutopilots: UUID[];
};

export const AutopilotCard = ({
	autopilot,
	enterpriseId,
	onToggleAutopilot,
	onAutopilotSelect,
	selectedAutopilots,
}: AutoPilotCardProps) => {
	const navigate = useNavigate();
	const autopilotUsers = autopilot.users;
	const [toastData, setToastData] = useState<{
		title: string;
		description: string;
		variant: "success" | "error" | "info";
	}>({
		title: "",
		description: "",
		variant: "success",
	});
	const [openToast, setOpenToast] = useState(false);
	const { data: user } = useMe();
	const { data: autopilotAuthor } = useAutopilotAuthor(autopilot.author_id);
	const { updateAutopilot, deleteAutopilot, isDeleteError } = useAutopilot();
	const { data: enterpriseUsers } = useEnterpriseUsers(enterpriseId);
	const { removeDraft } = useDraftAutopilots();
	const [confirmationDialogData, setConfirmationDialogData] = useState<{
		title: string;
		description: string;
		handleCtaClick: () => void;
		ctaText: string;
		icon: React.ReactNode;
	}>({
		title: "",
		description: "",
		handleCtaClick: () => {},
		ctaText: "",
		icon: <></>,
	});

	const [userDialogOpen, setUserDialogOpen] = useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [moreDialogOpen, setMoreDialogOpen] = useState(false);
	const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });

	const isAutopilotsPage = useMemo(() => {
		return window.location.pathname.includes("/autopilots");
	}, [window.location.pathname]);

	const onToggleMoreDialog = () => {
		if (dotsIconRef.current) {
			const rect = dotsIconRef.current.getBoundingClientRect();
			setDialogPosition({ top: rect.bottom, left: rect.left });
		}
		setMoreDialogOpen(!moreDialogOpen);
	};

	const onDeleteAutopilot = async () => {
		if (autopilot.draft_id) {
			removeDraft(autopilot.draft_id);
		}
		if (!autopilot?.id) {
			return;
		}
		try {
			await deleteAutopilot(autopilot?.id).unwrap();
		} catch (error) {
			console.error("Error deleting autopilot:", error);
			setToastData({
				title: "Failed to delete autopilot",
				description: "Please try again later",
				variant: "error",
			});
			setOpenToast(true);
		}
	};

	const moreDialogRef = useRef<HTMLDivElement>(null);
	const dotsIconRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				moreDialogRef.current &&
				!moreDialogRef.current.contains(event.target)
			) {
				setMoreDialogOpen(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [moreDialogRef]);

	const handleUsersSubmit = (selectedUsers: User[], autopilotId: UUID) => {
		updateAutopilot({
			id: autopilotId,
			users: selectedUsers,
			enterprise_id: enterpriseId,
		});
		setUserDialogOpen(false);
	};

	const onRemoveUser = (userId: UUID) => {
		if (isAutopilotsPage) {
			setToastData({
				title: "Not allowed",
				description:
					"You are not allowed to remove users from all autopilots view. Please select a specific enterprise to remove users.",
				variant: "info",
			});
			setOpenToast(true);
			return;
		}
		updateAutopilot({
			id: autopilot.id,
			users: autopilotUsers.filter((user: User) => user.id !== userId),
			enterprise_id: enterpriseId,
		});
	};

	const onLockAutopilot = () => {
		updateAutopilot({
			id: autopilot.id,
			author_id: user?.id,
			enterprise_id: enterpriseId,
		});
	};

	const isUserAutopilotAuthor = autopilot.author_id === user?.id;

	return (
		<>
			<Flex justify="between" align="center" style={{ padding: "12px 20px" }}>
				<Flex style={{ gap: "10px" }}>
					{!autopilot.draft_id && (
						<Checkbox
							checked={selectedAutopilots.includes(autopilot.id)}
							onCheckedChange={(value) => {
								onAutopilotSelect(autopilot.id, value);
							}}
							style={{ cursor: "pointer" }}
						/>
					)}
					<Flex direction="column" style={{ gap: "4px" }}>
						<Text weight="medium" style={{ fontSize: "14px" }}>
							{autopilot.name}
						</Text>
						<Text
							style={{
								fontSize: "12px",
								color: "var(--mb-color-light-gray-9)",
							}}
						>
							{autopilot.description}
						</Text>
					</Flex>
				</Flex>
				<Flex justify="center" align="center" style={{ gap: "10px" }}>
					{!autopilot.draft_id && (
						<Flex
							style={{ gap: "5px", cursor: "pointer" }}
							align="center"
							onClick={() => setUserDialogOpen(true)}
						>
							{autopilotUsers?.length > 0 ? (
								<OverlappingAvatars
									data={autopilotUsers.slice(0, 2).map((user) => ({
										avatar: user.profile_pic ?? "",
										alt: user.name ?? "",
									}))}
								/>
							) : (
								<Flex
									align="center"
									justify="center"
									style={{ cursor: "pointer", gap: "6px" }}
									onClick={() => setUserDialogOpen(true)}
								>
									<Icon.Plus size="15px" weight="bold" fill="var(--gray-9)" />
									<Text style={{ fontSize: "12px" }}>Add</Text>
								</Flex>
							)}
							{autopilotUsers?.length > 2 && (
								<Tooltip
									content={
										<Flex direction="column">
											{autopilotUsers?.map((user: User) => (
												<Text key={user.id}>{user.name}</Text>
											))}
										</Flex>
									}
								>
									<Text style={{ cursor: "pointer", color: "var(--gray-9)" }}>
										+{autopilotUsers?.length - 2}
									</Text>
								</Tooltip>
							)}
							{autopilotUsers?.length > 0 && (
								<Flex align="center" justify="center" style={{ gap: "6px" }}>
									<Icon.PencilSimple
										size="15px"
										weight="bold"
										fill="var(--gray-9)"
									/>
								</Flex>
							)}
						</Flex>
					)}
					<Switch
						style={{
							cursor: "pointer",
							marginLeft: "5px",
						}}
						onCheckedChange={(value) => {
							onToggleAutopilot(autopilot.id, value);
						}}
						mr="2"
						checked={autopilot.is_enabled}
						radius="full"
						id={`autopilot-toggle-${autopilot.id}`}
					/>
					{/* @ts-ignore */}
					<Button
						// variant="classic"
						style={{
							cursor: "pointer",
							background: !isUserAutopilotAuthor ? "var(--gray-3)" : "#FFF",
							color: "#333",
							border: "1px solid var(--gray-6)",
						}}
						onClick={() => {
							if (!isUserAutopilotAuthor) {
								setToastData({
									title: "Failed to edit autopilot",
									description:
										"You are not allowed to edit this autopilot. Please acquire lock on this autopilot.",
									variant: "error",
								});
								setOpenToast(true);
								return;
							}
							if (autopilot.draft_id) {
								navigate(
									`/admin/enterprise/${enterpriseId}/autopilot/${autopilot.draft_id}/draft`,
								);
							} else {
								navigate(
									`/admin/enterprise/${enterpriseId || user?.enterprise_id}/autopilot/${autopilot.id}/edit`,
								);
							}
						}}
					>
						Edit
					</Button>
					{/* @ts-ignore */}
					<Button
						variant="white-bg"
						style={{
							cursor: "pointer",
						}}
						onClick={() => {
							navigate(
								`/admin/enterprise/${enterpriseId}/autopilot/${autopilot.id}/test`,
							);
						}}
					>
						Test Run
					</Button>
					<Tooltip
						content={`Current lock: ${autopilotAuthor?.email} ${autopilot.author_id !== user?.id ? "Acquire Lock?" : ""}`}
					>
						<Flex
							justify="center"
							align="center"
							style={{
								width: "34px",
								height: "34px",
								borderRadius: "50%",
								backgroundColor: "var(--mb-blue-1)",
								cursor: "pointer",
							}}
							onClick={() => {
								if (autopilot.author_id !== user?.id) {
									setConfirmationDialogData({
										title: "Acquire Lock",
										description: `Are you sure you want to acquire lock on this autopilot? Current lock holder is: ${autopilotAuthor?.name} (${autopilotAuthor?.email})`,
										handleCtaClick: onLockAutopilot,
										icon: <Icon.LockOpen />,
										ctaText: "Acquire Lock",
									});
									setConfirmationDialogOpen(true);
								}
							}}
						>
							{autopilot.author_id !== user?.id ? (
								<Icon.Lock
									size={20}
									weight="bold"
									color="var(--mb-color-dark-blue-8)"
								/>
							) : (
								<Icon.LockOpen
									size={20}
									weight="bold"
									color="var(--mb-color-dark-blue-8)"
								/>
							)}
						</Flex>
					</Tooltip>

					<div ref={dotsIconRef}>
						<Icon.DotsThreeVertical
							size="30px"
							style={{ cursor: "pointer" }}
							onClick={onToggleMoreDialog}
						/>
					</div>

					{moreDialogOpen && (
						<div
							ref={moreDialogRef}
							style={{
								position: "absolute",
								top: dialogPosition.top + window.scrollY,
								left: dialogPosition.left + window.scrollX,
								transform: "translateY(5px)",
								zIndex: 1000,
							}}
						>
							<MoreDialog
								onDelete={() => {
									setMoreDialogOpen(false);
									if (!isUserAutopilotAuthor) {
										setToastData({
											title: "Failed to delete autopilot",
											description:
												"You are not allowed to delete this autopilot. Please acquire lock on this autopilot.",
											variant: "error",
										});
										setOpenToast(true);
										return;
									}
									setConfirmationDialogData({
										title: "Delete Autopilot",
										description:
											"Are you sure you want to delete this autopilot?",
										handleCtaClick: onDeleteAutopilot,
										icon: <Icon.Trash />,
										ctaText: "Delete",
									});
									setConfirmationDialogOpen(true);
								}}
							/>
						</div>
					)}
				</Flex>
				<MultiSelectionDialog
					handleSubmit={(selectedUsers) =>
						handleUsersSubmit(selectedUsers, autopilot.id)
					}
					open={userDialogOpen}
					handleClose={() => setUserDialogOpen(false)}
					onOpenChange={setUserDialogOpen}
					initialData={autopilotUsers}
					data={enterpriseUsers as User[]}
				/>
				<ConfirmationDialog
					title={confirmationDialogData.title}
					open={confirmationDialogOpen}
					onOpenChange={setConfirmationDialogOpen}
					handleClose={() => setConfirmationDialogOpen(false)}
					text={confirmationDialogData.description}
					handleCtaClick={confirmationDialogData.handleCtaClick}
					icon={confirmationDialogData.icon}
					ctaText={confirmationDialogData.ctaText}
				/>
			</Flex>
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description}
				type={toastData.variant ?? ""}
			/>
		</>
	);
};
