import { Dialog, Button } from "@mightybot/web-ui";
import styled from "styled-components";

export const GenerateDocRoot = styled(Dialog.Root)``;
export const GenerateDocTrigger = styled(Dialog.Trigger)``;
export const GenerateDocContainer = styled(Dialog.Content)`
	background-color: white;
	max-width: 760px;
	width: 100%;
`;
export const GenerateDocButton = styled(Button)`
	gap: var(--space-1);
	padding: 0 17px;
	border-radius: 16px;
	color: var(--mb-color-dark-gray-10);
	background-color: var(--mb-color-light-blue-8);
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
`;
