import React from "react";
import { Icon } from "@mightybot/web-ui";
import Widget, { WidgetIcon } from "../Widget/Widget";
import { WidgetPosition } from "./MeetingContentScroller";
import { useMeetingContentHover } from "../../contexts/MeetingContentHoverContext";

interface MeetingContentWidgetProps {
	widgetPosition: WidgetPosition;
}

const MeetingContentWidget: React.FC<MeetingContentWidgetProps> = ({
	widgetPosition,
}) => {
	const { handleFollowUp } = useMeetingContentHover();

	const icons: WidgetIcon[] = [
		{
			content: "Ask follow up",
			icon: Icon.ShareFat,
			onClick: handleFollowUp,
			transform: "scaleX(-1)",
		},
	];

	return <Widget widgetPosition={widgetPosition} icons={icons} />;
};

export default MeetingContentWidget;
