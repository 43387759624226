import React from "react";
import * as Styled from "./styled";
import { Flex, Spinner, Text } from "@mightybot/web-ui";
import { CustomAppType, CustomGoogleAppType, AppData } from "./Connect";

type AppInfoProps = {
	app: CustomAppType | CustomGoogleAppType;
};
type AppCardProps = {
	app: CustomAppType | CustomGoogleAppType;
	lastItem: boolean;
	connect: (app: CustomAppType | CustomGoogleAppType) => void;
	disconnect: (app: CustomAppType | CustomGoogleAppType) => void;
	appConnecting: boolean;
};

const AppInfo = ({ app }: AppInfoProps) => {
	return (
		<Flex direction="column" justify="center">
			<Text weight="medium" size="2" style={{ lineHeight: "18px" }}>
				{app.app_name ?? app.name}
			</Text>
			{app.description && (
				<Text
					weight="light"
					size="1"
					style={{
						color: "var(--mb-color-dark-gray-4)",
						lineHeight: "16px",
					}}
				>
					{app.description}
				</Text>
			)}
		</Flex>
	);
};

export const AppCard = ({
	app,
	lastItem,
	connect,
	disconnect,
	appConnecting,
}: AppCardProps) => {
	return (
		<Styled.AppCardContainer
			align="center"
			justify="between"
			style={{
				borderBottom: lastItem ? "" : "1px solid #E0E0E0",
			}}
		>
			<Flex
				style={{
					columnGap: "10px",
				}}
			>
				<Styled.ImageContainer>
					<img
						src={app.static_logo ?? (app.icon_url as string)}
						alt={`${app.name} logo`}
						style={{ width: "100%", height: "100%", objectFit: "contain" }}
					/>
				</Styled.ImageContainer>

				<AppInfo app={app} />
			</Flex>
			<Flex>
				{/* @ts-ignore */}
				<Styled.ConnectButton
					variant="white-bg"
					onClick={() => {
						if (app.is_connected) {
							disconnect(app);
						} else {
							connect(app);
						}
					}}
					style={{
						backgroundColor: app.is_connected ? "white" : "var(--mb-blue-8)",
						color: app.is_connected ? "var(--color-text-primary)" : "white",
						border: app.is_connected ? "1px solid #cccccc" : "none",
					}}
				>
					{app.is_connected && !appConnecting ? "Disconnect" : "Connect"}
					{appConnecting && <Spinner size={14} />}
				</Styled.ConnectButton>
			</Flex>
		</Styled.AppCardContainer>
	);
};
