import React from "react";
import { Icon, Tooltip, DropdownMenu } from "@mightybot/web-ui";
import { MODELS, useModelSelection, useMe } from "@mightybot/core";

const ModelSelection: React.FC = () => {
	const { data: user } = useMe();
	const { changeModel, selectedModel } = useModelSelection();

	if (!user?.is_admin) {
		return null;
	}

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger>
				<Icon.DotsThree
					weight="bold"
					style={{
						cursor: "pointer",
					}}
				/>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content style={{ maxWidth: "200px" }}>
				<DropdownMenu.Sub>
					<DropdownMenu.SubTrigger>Select Model</DropdownMenu.SubTrigger>
					<DropdownMenu.SubContent>
						{MODELS.map((model) => (
							<Tooltip content={model.id} key={model.id}>
								<DropdownMenu.Item
									key={model.id}
									onSelect={() => changeModel(model)}
								>
									{model.label}
									{model.id === selectedModel?.id && (
										<Icon.Check style={{ marginLeft: "4px" }} />
									)}
								</DropdownMenu.Item>
							</Tooltip>
						))}
					</DropdownMenu.SubContent>
				</DropdownMenu.Sub>
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	);
};

export default ModelSelection;
