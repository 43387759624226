import React, { createContext, useContext, useState } from "react";
import {
	startStreamingApi,
	StreamPayload,
	useCompletionsHistory,
} from "@mightybot/core";

interface StreamingContextType {
	streamingMessage: string;
	isStreaming: boolean;
	startStreaming: (payload: StreamPayload) => Promise<void>;
	endStreaming: () => void;
	currentStreamingQuestion: string;
}

const StreamingContext = createContext<StreamingContextType | undefined>(
	undefined,
);

export const StreamingProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [streamingMessage, setStreamingMessage] = useState("");
	const [isStreaming, setIsStreaming] = useState(false);

	const [currentStreamingQuestion, setCurrentStreamingQuestion] = useState("");
	const { handleStreamingComplete } = useCompletionsHistory();

	const startStreaming = async (payload: StreamPayload) => {
		try {
			setIsStreaming(true);
			setStreamingMessage("");
			setCurrentStreamingQuestion(payload.user_question);

			let finalMessage = "";
			await startStreamingApi(payload, (chunk: string) => {
				finalMessage += chunk;
				setStreamingMessage(finalMessage);
			});

			handleStreamingComplete({
				user_question: payload.user_question,
				response: finalMessage,
			});
		} catch (error) {
			console.error(error);
		} finally {
			setIsStreaming(false);
		}
	};

	const endStreaming = () => {
		setIsStreaming(false);
		setCurrentStreamingQuestion("");
	};

	return (
		<StreamingContext.Provider
			value={{
				streamingMessage,
				isStreaming,
				startStreaming,
				endStreaming,
				currentStreamingQuestion,
			}}
		>
			{children}
		</StreamingContext.Provider>
	);
};

export const useStreaming = () => {
	const context = useContext(StreamingContext);
	if (!context) {
		throw new Error("useStreaming must be used within a StreamingProvider");
	}
	return context;
};
