import React from "react";
import Search from "../components/Search";
import Layout from "../components/Layout";

const SearchRoute: React.FC = () => {
	return (
		<Layout>
			<Search />
		</Layout>
	);
};

export default SearchRoute;
