import { Flex, Icon } from "@mightybot/web-ui";

import { styled } from "styled-components";

export const Dot = styled(Flex)`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--mb-color-light-gray-9);
`;

export const MeetingChatUserMessageContainer = styled(Flex)`
	gap: 6px;
`;

export const MeetingChatReplyContainer = styled(Flex)`
	gap: 6px;
	background-color: var(--mb-color-light-blue-1);
	padding: 10px;
	border-radius: 8px;
`;

export const CopyIcon = styled(Icon.Copy)`
	cursor: pointer;
	&:hover {
		background-color: var(--gray-1);
	}
`;

export const MeetingChatContainer = styled(Flex)`
	width: 100%;
`;
