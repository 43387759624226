import React from "react";
import { AvatarWrapper, Flex, Icon, Text } from "@mightybot/web-ui";
import { EnterpriseResponse as Enterprise, User } from "@mightybot/core";
import { UUID } from "crypto";

type CardProps = {
	data: User | Enterprise;
	onRemove: (id: UUID) => void;
};

export const Card = ({ data, onRemove }: CardProps) => {
	return (
		<Flex
			justify="center"
			align="center"
			style={{
				gap: "6px",
				borderRadius: "10px",
				height: "24px",
				backgroundColor: "var(--mb-blue-2)",
			}}
		>
			<AvatarWrapper
				src={(data as User)?.profile_pic || (data as Enterprise)?.logo || ""}
				alt={data?.name || ""}
				key={data?.id}
				radius="full"
				size="1"
				variant="solid"
			/>
			<Text>{data?.name || ""}</Text>
			<Icon.X
				size="16px"
				style={{ marginRight: "6px", cursor: "pointer" }}
				weight="bold"
				onClick={() => onRemove(data.id)}
			/>
		</Flex>
	);
};
