import React, { useState, useEffect } from "react";
import { Text, Flex, Icon } from "@mightybot/web-ui";
import { useNavigate, useParams } from "react-router-dom";
import {
	AutopilotTestRunRequest,
	AutopilotVariables,
	useAutopilot,
	useAutopilotConfig,
	useUserRecentEvents,
	useAutopilotLogs,
	useAutopilotById,
} from "@mightybot/core";
import { TabsRoot } from "./styled";
import { ManualInputView } from "./ManualInputView";
import { RecentEventsView } from "./RecentEventsView";
import { capitalize, getErrorString } from "../../utils/helpers";
import { AutopilotLogs } from "./AutopilotLogs";
import Toast from "../Toast";

export function AutopilotTestRun() {
	const { data: config } = useAutopilotConfig();
	const { enterpriseId, autopilotId } = useParams();
	const navigate = useNavigate();
	const [tab, setTab] = useState<"event" | "manual">("manual");
	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState<{
		title: string;
		description?: string;
		status: "success" | "error" | "info";
	}>({
		title: "",
		description: "",
		status: "success",
	});
	const { data: autopilot } = useAutopilotById({
		autopilotId: autopilotId ?? "",
	});
	const { testRun } = useAutopilot();
	const [eventsPage, setEventsPage] = useState(1);
	const { data: recentUserEventsData, refetch: refetchEvents } =
		useUserRecentEvents({
			eventNames: autopilot?.config?.event_name,
			page: eventsPage,
			pageSize: 10,
		});
	const {
		logs,
		total: logsTotal,
		page: logsPage,
		perPage: logsPerPage,
		refetch: refetchLogs,
	} = useAutopilotLogs({
		enterpriseId: enterpriseId ?? undefined,
		autopilotId: autopilotId ?? undefined,
		isTest: true,
		page: 1,
		perPage: 10,
	});
	const onCloseClick = () => {
		navigate(-1);
	};

	const handleLogsPageChange = (newPage: number) => {
		refetchLogs({ page: newPage });
	};

	const handleEventsPageChange = (newPage: number) => {
		setEventsPage(newPage);
		if (!autopilot?.config?.event_name) {
			refetchEvents();
		}
	};

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			refetchLogs({ page: 1, perPage: 10 });
			setIsLoading(false);
		};
		fetchData();
	}, []);

	const autopilotEventName = autopilot?.config?.event_name ?? [];
	const autopilotVariables:
		| AutopilotVariables[keyof AutopilotVariables]
		| undefined = config?.event_fields_mapping[autopilotEventName[0]];

	const handleTestRun: (
		payload: AutopilotTestRunRequest,
	) => Promise<boolean> = async (payload: AutopilotTestRunRequest) => {
		try {
			await testRun({ ...payload }).unwrap();
			setToastData({
				title: "Test Run Success",
				description: "Test run triggered successfully!",
				status: "success",
			});
			setOpenToast(true);
			return true;
		} catch (error) {
			const { status: errorStatus, data: errorData } = error;
			let errorString = errorData.error || errorData.description;
			if (!errorString) {
				errorString =
					errorData.validation_error || errorData.details
						? getErrorString(errorData.validation_error || errorData.details)
						: "Failed to trigger test run. Please try again.";
			}
			setToastData({
				title: "Test Run Error",
				description: errorString,
				status: "error",
			});
			setOpenToast(true);
			return false;
		}
	};

	return (
		<React.Fragment>
			<Flex
				direction="column"
				style={{ gap: "10px", width: "1200px", overflow: "scroll" }}
			>
				<Flex justify="between" align="center">
					<Text size="6">Test Run - {capitalize(autopilot?.name ?? "")}</Text>
					<Icon.X
						size="22"
						onClick={onCloseClick}
						style={{ cursor: "pointer" }}
					/>
				</Flex>
				{isLoading ? (
					<Text>Loading...</Text>
				) : (
					<Flex
						direction="column"
						style={{
							borderRadius: "12px",
							border: "1px solid #E0E0E0",
						}}
					>
						<AutopilotLogs
							logs={logs ?? []}
							total={logsTotal ?? 0}
							page={logsPage}
							perPage={logsPerPage}
							onPageChange={handleLogsPageChange}
						/>
					</Flex>
				)}
				<Flex
					direction="column"
					style={{
						borderRadius: "12px",
						border: "1px solid #E0E0E0",
					}}
				>
					<Flex direction="column" justify="center">
						<Text weight="bold" style={{ padding: "12px 20px" }}>
							Manual Testing
						</Text>
						<div
							style={{
								width: "100%",
								borderColor: "#E0E0E0",
								height: "1px",
								backgroundColor: "#E0E0E0",
							}}
						/>
					</Flex>
					<Flex
						direction="column"
						style={{
							background: "var(--mb-blue-1)",
							gap: "20px",
							padding: "20px",
						}}
					>
						<>
							<TabsRoot>
								<Flex className="tabs">
									{autopilot?.config?.event_name && (
										<>
											<input
												type="radio"
												id="radio-events"
												name="tabs"
												checked={tab === "event"}
												onClick={() => setTab("event")}
											/>
											<label className="tab" htmlFor="radio-events">
												Recent Events
											</label>
										</>
									)}
									<input
										type="radio"
										id="radio-manual"
										name="tabs"
										checked={tab === "manual"}
										onClick={() => setTab("manual")}
									/>
									<label className="tab" htmlFor="radio-manual">
										Manual Input
									</label>
									<span className="glider"></span>
								</Flex>
							</TabsRoot>
						</>
						{tab === "event" ? (
							<RecentEventsView
								recentUserEvents={recentUserEventsData?.events ?? []}
								total={recentUserEventsData?.total_count ?? 0}
								page={recentUserEventsData?.page ?? 1}
								perPage={recentUserEventsData?.page_size ?? 10}
								onTestRun={handleTestRun}
								onPageChange={handleEventsPageChange}
							/>
						) : (
							<ManualInputView
								autopilotVariables={autopilotVariables}
								onTestRun={handleTestRun}
							/>
						)}
					</Flex>
				</Flex>
			</Flex>
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description ?? ""}
				type={toastData.status}
			/>
		</React.Fragment>
	);
}
