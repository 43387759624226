import styled from "styled-components";
import { Flex } from "@mightybot/web-ui";

export const TabsRoot = styled(Flex)`
	display: flex;
	align-items: center;
	flex-shrink: 0;
	.tabs {
		display: flex;
		background-color: #fff;
		padding: 4px;
		border-radius: 99px;
	}
	.tabs * {
		z-index: 2;
	}
	input[type="radio"] {
		display: none;
	}
	.tab {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		width: 134px;
		font-weight: 500;
		border-radius: 99px;
		cursor: pointer;
		transition: color 0.15s ease-in;
	}
	input[type="radio"]:checked + label {
		color: #fff;
		background: #333;
	}
	input[type="radio"]:not(:checked) + label {
		color: var(--mb-gray-9);
	}
	input[id="radio-build"]:checked ~ .glider {
		transform: translateX(0);
	}
	input[id="radio-test"]:checked ~ .glider {
		transform: translateX(100%);
	}
	.glider {
		position: absolute;
		display: flex;
		height: 30px;
		width: 134px;
		z-index: 1;
		border-radius: 99px;
		transition: 0.25s ease-out;
	}
`;
