import React from "react";
import {
	Text,
	Icon,
	Flex,
	Skeleton,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import {
	MeetingContentTitleInnerContainer,
	MeetingContentTitleOuterContainer,
} from "./styled";
import { useMeeting } from "@mightybot/core";
import { useParams } from "react-router-dom";
import { formatDateTime, formatDuration } from "../../utils/helpers";
import GenerateDoc from "../GenerateDoc";
import ModelSelection from "../ModelSelection";

interface MeetingContentTitleProps {}

const MeetingContentTitle: React.FC<MeetingContentTitleProps> = ({}) => {
	const meetingId = useParams().meetingId;
	const { meeting, isLoading } = useMeeting(meetingId ?? "");
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const duration = formatDuration(
		meeting?.start_time ?? "",
		meeting?.end_time ?? "",
	);
	const formattedDuration = duration.startsWith("0h ")
		? duration.slice(3)
		: duration;

	if (!meetingId) return null;

	return (
		<MeetingContentTitleOuterContainer
			justify="between"
			align="center"
			gap="3"
			data-title={`${meetingId}-title`}
		>
			<MeetingContentTitleInnerContainer
				style={{ maxWidth: "870px", width: "100%" }}
			>
				<Flex align="center" gap="3" style={{ width: "100%" }}>
					{isLoading ? (
						<>
							<Skeleton width="300px" height="24px" />
							<Skeleton width="150px" height="16px" />
							<Skeleton width="100px" height="16px" />
						</>
					) : (
						<Flex
							align={isMobile ? "flex-start" : "center"}
							gap="15px"
							style={{ width: "100%", flexWrap: "wrap" }}
							direction={isMobile ? "column" : "row"}
						>
							<Text
								weight="medium"
								size="4"
								style={{ flexShrink: 0, paddingRight: "10px" }}
							>
								{meeting?.title}
							</Text>
							<Flex align="center" gap="3" style={{ width: "100%" }}>
								<Flex align="center" gap="1">
									<Icon.CalendarCheck />
									<Text weight="regular" size="1">
										{formatDateTime(meeting?.start_time ?? "")}
									</Text>
								</Flex>
								<Flex align="center" gap="1" style={{ marginLeft: "1vw" }}>
									<Icon.Clock />
									<Text weight="regular" size="1">
										{formattedDuration}
									</Text>
								</Flex>
								{isMobile && (
									<Flex
										align="center"
										style={{
											marginLeft: "auto",
											gap: "5px",
										}}
									>
										<GenerateDoc />
										<ModelSelection />
									</Flex>
								)}
							</Flex>
						</Flex>
					)}
				</Flex>
				{!isMobile && (
					<Flex align="start" justify="end" gap="2" style={{ width: "100%" }}>
						<Flex align="center" gap="2">
							{isLoading ? (
								<Skeleton width="120px" height="32px" />
							) : (
								<Flex align="center" gap="5px">
									<GenerateDoc />
									<ModelSelection />
								</Flex>
							)}
						</Flex>
					</Flex>
				)}
			</MeetingContentTitleInnerContainer>
		</MeetingContentTitleOuterContainer>
	);
};

export default MeetingContentTitle;
