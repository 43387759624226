import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import React from "react";
import {
	useLocation,
	useNavigationType,
	matchRoutes,
	createRoutesFromChildren,
} from "react-router-dom";

const SENTRY_DSN =
	"https://649b28909dd5ab8bb3f5599ee313ca75@o4505835461410816.ingest.us.sentry.io/4507708203728896";

export const initializeAnalytics = () => {
	if (process.env.NODE_ENV !== "production") return;

	process.env.REACT_APP_POSTHOG_APP_ID &&
		posthog.init(process.env.REACT_APP_POSTHOG_APP_ID, {
			persistence: "localStorage",
			autocapture: true,
			capture_pageview: true,
			capture_pageleave: true,
		});

	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				matchRoutes,
				createRoutesFromChildren,
			}),
			Sentry.replayIntegration(),
			Sentry.sessionTimingIntegration(),
			Sentry.captureConsoleIntegration(),
			Sentry.browserApiErrorsIntegration({
				setTimeout: true,
				setInterval: true,
				requestAnimationFrame: true,
				XMLHttpRequest: true,
				eventTarget: true,
			}),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: ["localhost", /^https:\/\/app.mightybot.ai\/api/],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		release: process.env.REACT_APP_PORTER_IMAGE_TAG,
		environment: process.env.REACT_APP_ENVIRONMENT,
	});
};
