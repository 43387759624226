import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Home from "../components/Home";
import localforage from "localforage";
import { EXAMPLE_FEED_KEY, ExampleFeedData } from "@mightybot/core";
import { useContentful } from "react-contentful";

const EXAMPLE_FEED_ENTRY_ID = "4DNjy7Hui8csUFpt5y9eSa";

export default function HomeRoute() {
	let { data: exampleFeedData } = useContentful({
		contentType: "exampleFeedData",
		id: EXAMPLE_FEED_ENTRY_ID,
	});

	useEffect(() => {
		if (exampleFeedData) {
			const feed = (exampleFeedData as ExampleFeedData)?.fields?.feed ?? [];
			localforage.setItem(EXAMPLE_FEED_KEY, {
				feed: feed.map((item) => ({
					...item,
					timestamp: new Date().toISOString(),
				})),
			});
		}
	}, [exampleFeedData]);
	return (
		<Layout>
			<Home />
		</Layout>
	);
}
