import React, { useState, useEffect } from "react";
import { MightyBotLogo } from "@mightybot/core-ui";
import { LoadingContainer, LogoContainer, RippleRing } from "./styled";

const Loading: React.FC = () => {
	const [showRings, setShowRings] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowRings(true);
		}, 500);

		return () => clearTimeout(timer);
	}, []);

	return (
		<LoadingContainer>
			<LogoContainer>
				<img src={MightyBotLogo} alt="MightyBot Logo" />
				<RippleRing
					delay="0s"
					size={50}
					visible={showRings ? "true" : "false"}
				/>
			</LogoContainer>
		</LoadingContainer>
	);
};

export default Loading;
