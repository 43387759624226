import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	Flex,
	Text,
	Button,
	Icon,
	Skeleton,
	Checkbox,
} from "@mightybot/web-ui";
import {
	CheckboxContainer,
	ResultsSummaryContainer,
	ResultsSummaryInnerContainer,
	StyledButton,
} from "./styled";
import { useSearchSelection } from "@mightybot/core";

interface SearchResultsSummaryProps {
	isLoading: boolean;
	searchResults: any[];
	resultsScrolled: boolean;
	isDialog: boolean;
}

const SearchResultsSummary = ({
	isDialog,
	isLoading,
	searchResults,
	resultsScrolled,
}: SearchResultsSummaryProps) => {
	const navigate = useNavigate();
	const {
		isSelectionMode,
		selectedResults,
		toggleSelectionMode,
		clearSelection,
		selectAll,
	} = useSearchSelection();

	const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);
	const [searchParams] = useSearchParams();
	const searchId = searchParams.get("searchId");
	const isChecked =
		searchResults.length > 0 && selectedResults.length === searchResults.length;

	const handleCheckboxClick = () => {
		if (isChecked) {
			clearSelection();
		} else {
			selectAll(searchResults);
		}
	};

	useEffect(() => {
		if (isSelectionMode) {
			setTimeout(() => setIsCheckboxVisible(true), 50);
		} else {
			setIsCheckboxVisible(false);
		}
	}, [isSelectionMode]);

	const handleStartChat = () => {
		if (selectedResults.length > 0) {
			navigate(`/chats?searchId=${searchId}`);
			toggleSelectionMode();
		}
	};

	const onClose = () => {
		toggleSelectionMode();
		clearSelection();
	};

	return (
		<ResultsSummaryContainer
			resultsscrolled={resultsScrolled.toString()}
			isdialog={isDialog.toString()}
		>
			<ResultsSummaryInnerContainer isdialog={isDialog.toString()}>
				<Flex align="center">
					{isSelectionMode && (
						<CheckboxContainer $isVisible={isCheckboxVisible}>
							<Checkbox
								checked={isChecked}
								onCheckedChange={handleCheckboxClick}
							/>
						</CheckboxContainer>
					)}
					<Text size="4" weight="medium" style={{ paddingLeft: "6px" }}>
						{isLoading ? (
							<Skeleton width="100px" height="24px" />
						) : isSelectionMode ? (
							`${selectedResults.length} selected`
						) : (
							`${searchResults.length} result${searchResults.length !== 1 ? "s" : ""}`
						)}
					</Text>
				</Flex>

				<Flex gap="8px" align="center">
					{!isDialog && !isSelectionMode && (
						<StyledButton
							size="2"
							style={{
								backgroundColor: "var(--mb-blue-8)",
							}}
							onClick={toggleSelectionMode}
						>
							Start Chat
						</StyledButton>
					)}
					{isSelectionMode && (
						<>
							{!isDialog && (
								<>
									<StyledButton
										size="2"
										style={{
											backgroundColor:
												selectedResults.length > 0
													? "var(--mb-blue-8)"
													: "var(--mb-gray-4)",
										}}
										onClick={handleStartChat}
										disabled={
											selectedResults.length === 0 ||
											isLoading ||
											searchResults.length === 0
										}
									>
										<Icon.ArrowBendDownRight />
										Chat with Selected
									</StyledButton>
									<StyledButton size="2" variant="white-bg" onClick={onClose}>
										Cancel
									</StyledButton>
								</>
							)}
						</>
					)}
				</Flex>
			</ResultsSummaryInnerContainer>
		</ResultsSummaryContainer>
	);
};

export default SearchResultsSummary;
