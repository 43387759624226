import React from "react";
import { DealsContainer } from "./styled";
import Placeholder from "../../utils/Placeholder";
import { PlaceholderDealsPage } from "@mightybot/core-ui";

const Deals: React.FC = () => {
	return (
		<DealsContainer>
			<Placeholder
				placeholder={PlaceholderDealsPage}
				style={{ alignItems: "flex-start" }}
			/>
		</DealsContainer>
	);
};

export default Deals;
