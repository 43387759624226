import React, { useEffect, useState, useCallback } from "react";
import { Dialog, Flex, Button } from "@mightybot/web-ui";
import { EnterpriseResponse as Enterprise, User } from "@mightybot/core";
import debounce from "lodash/debounce";
import CheckboxWrapper from "./CheckboxWrapper";

export type SelectedData = User[] | Enterprise[];

type MultiSelectionDialogProps = {
	title?: string;
	open?: boolean;
	onOpenChange: (open: boolean) => void;
	handleClose: () => void;
	handleSubmit: (selectedUsers: User[]) => void;
	initialData: SelectedData;
	data: SelectedData;
};

function MultiSelectionDialog({
	open = false,
	onOpenChange,
	handleClose,
	handleSubmit,
	initialData,
	data,
	title = "Select Users",
}: MultiSelectionDialogProps) {
	const [selectedData, setSelectedData] = useState<SelectedData>(initialData);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedAll, setSelectedAll] = useState(false);

	const handleSearch = debounce(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(event.target.value);
		},
		300,
	);

	const handleDataSelection = useCallback(
		(data: User | Enterprise | { name: string; id: string }) => {
			if (selectedData.some((u: User | Enterprise) => u.id === data.id)) {
				const updatedData = (selectedData as (User | Enterprise)[]).filter(
					(u) => u.id !== data.id,
				);
				setSelectedData(updatedData as SelectedData);
			} else {
				const updatedData = [...selectedData, data];
				setSelectedData(updatedData as SelectedData);
			}
		},
		[selectedData],
	);

	const filteredData = data?.filter((d: User | Enterprise) =>
		d.name?.toLowerCase().includes(searchTerm.toLowerCase()),
	);

	const handleSelectAll = () => {
		const newSelectedAll = !selectedAll;
		setSelectedAll(newSelectedAll);
		setSelectedData(newSelectedAll ? data : []);
	};

	useEffect(() => {
		setSelectedData(initialData);
	}, [initialData]);
	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Content style={{ maxWidth: 350, padding: 20 }}>
				<Dialog.Title>{title}</Dialog.Title>
				<Flex direction="column" style={{ gap: "16px" }}>
					<input
						type="text"
						placeholder="Search..."
						value={searchTerm}
						onChange={handleSearch}
						style={{
							height: "32px",
							width: "100%",
							borderRadius: "4px",
							padding: "0px 10px",
							backgroundColor: "var(--gray-3)",
							border: "1px solid var(--gray-5)",
						}}
					/>
					<Flex
						direction="column"
						style={{
							maxHeight: "200px",
							overflowY: "auto",
							paddingRight: "10px",
						}}
					>
						<CheckboxWrapper
							data={{ name: "Select All", id: "select-all" }}
							selectedData={selectedData}
							handleDataSelection={handleSelectAll}
							checked={selectedAll}
						/>
						{filteredData?.map((data) => (
							<CheckboxWrapper
								key={data.id}
								data={data}
								selectedData={selectedData}
								handleDataSelection={handleDataSelection}
							/>
						))}
					</Flex>
					<Flex gap="3" mt="4" justify="end">
						{/* @ts-ignore*/}
						<Button variant="soft" color="gray" onClick={handleClose}>
							Cancel
						</Button>
						{/* @ts-ignore*/}
						<Button onClick={() => handleSubmit(selectedData)}>Done</Button>
					</Flex>
				</Flex>
			</Dialog.Content>
		</Dialog.Root>
	);
}

export default MultiSelectionDialog;
