import React, { useState } from "react";
import { NLSearchResult } from "@mightybot/core";
import SearchResult from "./SearchResult";
import { ResultGroupContainer } from "./styled";
import { useSearchSelection } from "@mightybot/core";

interface SearchResultGroupProps {
	results: NLSearchResult[];
	index: number;
	isLast: boolean;
	isDialog: boolean;
	showSelectedOnly: boolean;
}

const SearchResultGroup: React.FC<SearchResultGroupProps> = ({
	results,
	index,
	isLast,
	isDialog,
	showSelectedOnly,
}) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [slideDirection, setSlideDirection] = useState<"left" | "right" | null>(
		null,
	);
	const hasMultipleResults = results.length > 1;
	const { isSelectionMode } = useSearchSelection();

	const handlePrevious = (e: React.MouseEvent) => {
		if (isSelectionMode) {
			e.stopPropagation(); // Prevent selection when navigating in selection mode
		}
		setSlideDirection("right");
		setCurrentIndex((current) =>
			current === 0 ? results.length - 1 : current - 1,
		);
		setTimeout(() => setSlideDirection(null), 300);
	};

	const handleNext = (e: React.MouseEvent) => {
		if (isSelectionMode) {
			e.stopPropagation(); // Prevent selection when navigating in selection mode
		}
		setSlideDirection("left");
		setCurrentIndex((current) =>
			current === results.length - 1 ? 0 : current + 1,
		);
		setTimeout(() => setSlideDirection(null), 300);
	};

	return (
		<ResultGroupContainer>
			<SearchResult
				result={results[currentIndex]}
				index={index}
				isLast={isLast}
				isDialog={isDialog}
				handlePrevious={handlePrevious}
				handleNext={handleNext}
				hasMultipleResults={hasMultipleResults}
				count={results.length}
				currentIndex={currentIndex}
				slideDirection={slideDirection}
				groupResults={results} // Pass all results to allow selection of any result in the group
				setCurrentIndex={setCurrentIndex}
				showSelectedOnly={showSelectedOnly}
			/>
		</ResultGroupContainer>
	);
};

export default SearchResultGroup;
