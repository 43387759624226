import styled from "styled-components";
import { Flex, Text, Button, mediaQueries } from "@mightybot/web-ui";

export const SidebarContainer = styled(Flex)`
	width: 100%;
	flex-direction: column;
	background-color: var(--mb-color-light-blue-1);
	border-radius: 20px;
	padding-bottom: 16px;
	gap: 3px;
	overflow: scroll;
	transition: all 0.2s ease;
`;

export const SidebarHeaderContainer = styled(Flex)`
	padding: 20px 10px 10px 26px;
	position: sticky;
	top: 0;
	gap: 8px;
	background-color: var(--mb-color-light-blue-1);
	align-items: center;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const SidebarContentContainer = styled(Flex)`
	overflow-y: hidden;
	flex-direction: column;
`;

export const SidebarToggleButton = styled(Flex)`
	background-color: var(--mb-color-light-blue-2);
	border-radius: 50%;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 14px;
`;

export const MobileSidebarBar = styled(Flex)`
	align-items: center;
	justify-content: space-between;
	padding: 12px;
	background-color: var(--mb-color-light-blue-1);
	cursor: pointer;
	height: 50px;
`;

export const MobileSidebarContent = styled.div<{ isOpen: boolean }>`
	max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
	overflow: hidden;
	transition: max-height 0.3s ease;
	background-color: var(--mb-color-light-blue-1);
`;

export const SidebarItemsContainer = styled(Flex)`
	gap: 0px;
	padding: 0px 10px;
	overflow: scroll;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 5px;
	}

	scrollbar-width: thin;
	scrollbar-color: #888 transparent;

	${mediaQueries.tablet} {
		height: 100%;
		align-items: center;
	}
`;

export const SidebarItem = styled(Flex)<{ selected: boolean }>`
	align-items: center;
	cursor: pointer;
	border-radius: var(--radius-pill);
	gap: 10px;
	padding: 8px 16px 8px 18px;
	height: 36px;
	flex-shrink: 0;
	transition: background-color 0.2s ease;
	background-color: ${({ selected }) =>
		selected ? "var(--mb-color-light-blue-2)" : "transparent"};

	white-space: nowrap;
	overflow: hidden;

	&:hover {
		background-color: var(--mb-color-light-blue-2);
		color: var(--mb-color-light-blue-8);
	}
`;

export const SidebarItemText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 80%;

	${mediaQueries.tablet} {
		&:hover {
			white-space: unset;
		}
	}
`;

export const SidebarDayHeader = styled(Text)`
	font-size: 12px;
	font-weight: medium;
	color: var(--mb-gray-9);
	padding: 12px 18px 8px;

	${mediaQueries.tablet} {
		padding: 8px 12px 4px;
	}
`;

export const LoadMoreButton = styled(Button)`
	background: none;
	border: none;
	color: var(--mb-primary-9);
	cursor: pointer;
	font-size: 14px;
	margin-top: 16px;
	padding: 8px;
	width: 100%;
	text-align: center;

	&:hover {
		background-color: var(--mb-gray-3);
	}
`;

export const MobileChatsBar = styled(Flex)`
	align-items: center;
	justify-content: space-between;
	padding: 12px;
	background-color: var(--mb-gray-1);
	cursor: pointer;
	height: 56px;
	border-bottom: 1px solid var(--mb-gray-5);
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
	position: sticky;
	top: 0;
	z-index: 10;
`;

export const MobileChatsContent = styled(Flex)<{ isOpen: boolean }>`
	max-height: ${({ isOpen }) => (isOpen ? "calc(100vh - 56px)" : "0")};
	overflow: auto;
	transition: max-height 0.3s ease;
	background-color: var(--mb-gray-1);
	flex-direction: column;
	box-shadow: ${({ isOpen }) =>
		isOpen ? "inset 0px 2px 4px rgba(0, 0, 0, 0.05)" : "none"};
	position: absolute;
	top: 56px;
	left: 0;
	right: 0;
	z-index: 9;

	${mediaQueries.tablet} {
		scrollbar-width: none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}
		padding: 0px 12px;
	}
`;

export const MobileChatHistoryItem = styled(Flex)<{ selected: string }>`
	padding: 12px;
	border-radius: 8px;
	cursor: pointer;
	background-color: ${({ selected }) =>
		selected === "true" ? "var(--mb-blue-1)" : "transparent"};
	align-items: center;
	gap: 12px;
	margin: 0 8px;

	&:hover {
		background-color: var(--mb-gray-2);
	}

	${mediaQueries.tablet} {
		padding: 8px 12px;
		margin: 2px 4px;
	}
`;
