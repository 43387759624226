import React, { useState } from "react";
import { Button, Text, Flex, Input } from "@mightybot/web-ui";
import { AutopilotTestRunRequest } from "@mightybot/core";
import { useNavigate, useParams } from "react-router-dom";
import { AutopilotVariables } from "@mightybot/core";
import TestRunKeysSchema from "./TestRunKeysSchema.json";
import { capitalize } from "../../utils/helpers";

type ManualInputViewProps = {
	autopilotVariables: AutopilotVariables[keyof AutopilotVariables] | undefined;
	onTestRun: (payload: AutopilotTestRunRequest) => void;
};

export const ManualInputView = ({
	autopilotVariables,
	onTestRun,
}: ManualInputViewProps) => {
	const navigate = useNavigate();
	const { autopilotId } = useParams();
	const [variableValues, setVariableValues] = useState<Record<string, string>>(
		{},
	);

	const handleTestRun = () => {
		onTestRun({
			autopilot_id: autopilotId ?? "",
			variable_values: variableValues,
		});
	};

	const handleVariableChange = (key: string, value: string) => {
		setVariableValues((prev) => ({ ...prev, [key]: value }));
	};

	const requiredFields = autopilotVariables?.fields_required ?? [];
	const optionalFields = autopilotVariables?.fields_optional ?? [];

	return (
		<Flex direction="column" style={{ gap: "20px" }}>
			{requiredFields.length > 0 && (
				<Flex direction="column" style={{ gap: "8px" }}>
					<Flex justify="between" style={{ flexBasis: "100%", gap: "20px" }}>
						<Text weight="bold" style={{ flex: 1 }}>
							Required Fields
						</Text>
						<Text weight="bold" style={{ flex: 1 }}>
							Value
						</Text>
					</Flex>
					<Flex
						direction="column"
						style={{ overflow: "scroll", width: "100%" }}
					>
						{requiredFields.map((field) => (
							<Flex key={field} justify="between">
								<Flex style={{ gap: "20px", flexBasis: "100%" }}>
									<Flex direction="column" style={{ gap: "0px", flex: 1 }}>
										<Flex
											style={{
												border: "1px solid var(--border-primary, #E0E0E0)",
												borderRadius: "4px",
												background: "#FFF",
												padding: "7px 12px",
												alignItems: "center",
												justifyContent: "start",
											}}
										>
											<Text>{capitalize(field.replace(/_/g, " "))}</Text>
										</Flex>
									</Flex>
									<Flex style={{ flex: 1 }}>
										<Input
											style={{ width: "100%" }}
											placeholder="Value"
											value={variableValues[field] || ""}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												handleVariableChange(field, e.target.value)
											}
										/>
									</Flex>
								</Flex>
								{TestRunKeysSchema[field] && (
									<Text
										style={{
											color: "var(--gray-8)",
											fontSize: "9px",
											paddingLeft: "2px",
										}}
									>
										eg. {JSON.stringify(TestRunKeysSchema[field])}
									</Text>
								)}
							</Flex>
						))}
					</Flex>
				</Flex>
			)}

			{optionalFields.length > 0 && (
				<Flex direction="column" style={{ gap: "8px" }}>
					<Flex justify="between" style={{ flexBasis: "100%", gap: "20px" }}>
						<Text weight="bold" style={{ flex: 1 }}>
							Optional Fields
						</Text>
						<Text weight="bold" style={{ flex: 1 }}>
							Value
						</Text>
					</Flex>
					{optionalFields.map((field) => (
						<Flex key={field} justify="between" direction="column">
							<Flex style={{ gap: "20px", flexBasis: "100%" }}>
								<Flex direction="column" style={{ gap: "0px", flex: 1 }}>
									<Flex
										style={{
											border: "1px solid var(--border-primary, #E0E0E0)",
											borderRadius: "4px",
											background: "#FFF",
											padding: "7px 12px",
											alignItems: "center",
											justifyContent: "start",
										}}
									>
										<Text>{capitalize(field.replace(/_/g, " "))}</Text>
									</Flex>
								</Flex>
								<Flex style={{ flex: 1, width: "100%" }}>
									<Input
										style={{ width: "100%" }}
										placeholder="Value"
										value={variableValues[field] || ""}
										onChange={(e) =>
											handleVariableChange(field, e.target.value)
										}
									/>
								</Flex>
							</Flex>
							{TestRunKeysSchema[field] && (
								<Text
									style={{
										color: "var(--gray-8)",
										fontSize: "9px",
										paddingLeft: "2px",
									}}
								>
									eg. {JSON.stringify(TestRunKeysSchema[field])}
								</Text>
							)}
						</Flex>
					))}
				</Flex>
			)}

			<Flex style={{ gap: "10px" }}>
				{/* @ts-ignore */}
				<Button
					size="1"
					style={{ cursor: "pointer" }}
					onClick={handleTestRun}
					disabled={
						!requiredFields.every(
							(field) =>
								variableValues[field] && variableValues[field].trim() !== "",
						)
					}
				>
					Test Run
				</Button>
				{/* @ts-ignore */}
				<Button
					size="1"
					variant="white-bg"
					style={{ cursor: "pointer" }}
					onClick={() => navigate(-1)}
				>
					Cancel
				</Button>
			</Flex>
		</Flex>
	);
};
