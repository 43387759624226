import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Text } from "@mightybot/web-ui";
import {
	EnterpriseAutopilotsResponse,
	useEnterpriseAutopilots,
} from "@mightybot/core";

export default function EnterpriseList() {
	const [data, setData] = useState<EnterpriseAutopilotsResponse>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const navigate = useNavigate();
	const {
		data: enterpriseUsersData,
		isLoading: isEnterpriseUsersLoading,
		isFetching: isEnterpriseUsersFetching,
		isError: isEnterpriseUsersError,
	} = useEnterpriseAutopilots();

	useEffect(() => {
		if (!isEnterpriseUsersLoading && !isEnterpriseUsersError) {
			setData(enterpriseUsersData ?? []);
		}
	}, [isEnterpriseUsersFetching]);

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const filteredData = data.filter((org) =>
		org.name.toLowerCase().includes(searchTerm.toLowerCase()),
	);

	return (
		<Flex
			direction="column"
			style={{
				width: "1200px",
				borderRadius: "12px",
				border: "1px solid #E0E0E0",
				overflow: "hidden",
			}}
		>
			<Flex
				direction="column"
				style={{
					top: 0,
					zIndex: 1,
					backgroundColor: "white",
					borderRadius: "12px 12px 0px 0px",
					position: "sticky",
				}}
			>
				<Flex direction="row" justify="between" style={{ padding: "20px" }}>
					<Text weight="medium" style={{ fontSize: 14 }}>
						Select Organisation
					</Text>
					<input
						type="text"
						placeholder="Search..."
						value={searchTerm}
						onChange={handleSearch}
						style={{
							height: "30px",
							width: "200px",
							borderRadius: "4px",
							border: "1px solid #ccc",
							padding: "0px 10px",
						}}
					/>
				</Flex>
				<div
					style={{
						width: "100%",
						borderColor: "#E0E0E0",
						height: "1px",
						backgroundColor: "#E0E0E0",
					}}
				/>
			</Flex>
			<Flex direction="column" style={{ overflow: "scroll" }}>
				{filteredData.map((enterprise, index) => (
					<Flex direction="column" key={enterprise.id}>
						<Flex
							align="center"
							style={{ flexBasis: "100%", padding: "12px 26px", gap: "10px" }}
						>
							<Text style={{ flex: 1 }}>{enterprise.name}</Text>
							<Text style={{ flex: 1 }}>{enterprise.users.length} users</Text>
							<Text style={{ flex: 1 }}>
								{enterprise.autopilots.count} autopilots
							</Text>
							{/* @ts-ignore */}
							<Button
								variant="white-bg"
								style={{
									cursor: "pointer",
									flex: 0.5,
								}}
								onClick={() => navigate(`/admin/enterprise/${enterprise.id}`)}
							>
								Select
							</Button>
						</Flex>
						{index < filteredData.length - 1 && (
							<div
								style={{
									width: "100%",
									borderColor: "#E0E0E0",
									height: "1px",
									backgroundColor: "#E0E0E0",
								}}
							/>
						)}
					</Flex>
				))}
			</Flex>
		</Flex>
	);
}
