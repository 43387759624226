import React, { useMemo, useRef } from "react";
import { QueryClient } from "react-query";

import {
	MeetingsListContentHeaderContainer,
	MeetingsListContentContainer,
	MeetingsListContentItem,
	MeetingsListContentItemText,
	MeetingsListContainer,
	MeetingGroup,
	MeetingsListSidebarToggleButton,
} from "./styled";
import { Flex, Icon, Text, Tooltip } from "@mightybot/web-ui";
import { Meeting, useMeetingsListBidirectional } from "@mightybot/core";
import { useIntegrationLogos } from "@mightybot/core-ui";
import { formatMeetingDateTime } from "../../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { InfiniteScroll } from "../InfiniteScroll";
import { uniqBy } from "lodash";

type MeetingsListProps = {
	toggleSidebar: () => void;
	isSidebarOpen: boolean;
};

type MeetingListItemProps = {
	meeting: Meeting;
	index: number;
};

const queryClient = new QueryClient();

const MeetingListItem = ({ meeting, index }: MeetingListItemProps) => {
	const navigate = useNavigate();
	const meetingId = useParams<{ meetingId: string }>().meetingId;
	const { getIntegrationLogo } = useIntegrationLogos();

	const renderAttendees = (attendees: Meeting["attendees"]) => {
		if (!attendees || attendees.length === 0) return "No attendees";
		if (typeof attendees === "string") {
			try {
				return JSON.parse(attendees).join(", ");
			} catch {
				return attendees; // Return the string as-is if parsing fails
			}
		}
		return Array.isArray(attendees) ? attendees.join(", ") : "No attendees";
	};

	const handleMeetingClick = (meetingId: string) => {
		navigate(`/meetings/${meetingId}`);
	};

	const meetingPlatformLogo = getIntegrationLogo(
		`${meeting.meeting_platform}_plain`,
	);
	return (
		<Tooltip
			content={renderAttendees(meeting.attendees)}
			side="right"
			width="fit"
			maxWidth="300px"
		>
			<MeetingsListContentItem
				data-cursor={meeting.start_time}
				onClick={() => handleMeetingClick(meeting.id)}
				selected={meeting.id === meetingId ? "true" : "false"}
			>
				<Text size="1" style={{ flexShrink: 0, width: "52px" }}>
					{formatMeetingDateTime(meeting.start_time).time}
				</Text>
				{meetingPlatformLogo ? (
					<img
						src={meetingPlatformLogo}
						alt={meeting.meeting_platform}
						style={{ width: "16px", height: "13px" }}
					/>
				) : (
					<Icon.VideoCamera weight="bold" size={16} />
				)}
				<MeetingsListContentItemText size="1" weight="medium">
					{meeting.title}
				</MeetingsListContentItemText>
			</MeetingsListContentItem>
		</Tooltip>
	);
};

const MeetingsGroup = ({ meetings }: { meetings: Meeting[] }) => {
	const uniqueMeetings = useMemo(
		() => uniqBy(meetings || [], "id"),
		[meetings],
	);
	const todayGroupRef = useRef<HTMLDivElement>(null);

	const groupedAndSortedMeetings = useMemo(() => {
		const groups: { [key: string]: Meeting[] } = {};
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);
		const tomorrow = new Date(today);
		tomorrow.setDate(tomorrow.getDate() + 1);

		uniqueMeetings.forEach((meeting: Meeting) => {
			const meetingDate = new Date(meeting.start_time);
			let key: string;

			if (meetingDate.toDateString() === yesterday.toDateString()) {
				key = "Yesterday";
			} else if (meetingDate.toDateString() === today.toDateString()) {
				key = "Today";
			} else if (meetingDate.toDateString() === tomorrow.toDateString()) {
				key = "Tomorrow";
			} else {
				key = meetingDate.toDateString();
			}

			if (!groups[key]) {
				groups[key] = [];
			}
			groups[key].push(meeting);
		});

		const specialOrder = ["Tomorrow", "Today", "Yesterday"];
		return Object.entries(groups).sort(([dateA], [dateB]) => {
			const indexA = specialOrder.indexOf(dateA);
			const indexB = specialOrder.indexOf(dateB);
			const dateObjA = new Date(dateA);
			const dateObjB = new Date(dateB);

			// If both are special dates, use the special order
			if (indexA !== -1 && indexB !== -1) {
				return indexA - indexB;
			}

			// If one is a special date and the other isn't
			if (indexA !== -1) {
				return dateObjB > tomorrow ? 1 : -1;
			}
			if (indexB !== -1) {
				return dateObjA > tomorrow ? -1 : 1;
			}

			// For non-special dates
			if (dateObjA > tomorrow && dateObjB > tomorrow) {
				// Both are future dates, sort in descending order
				return dateObjB.getTime() - dateObjA.getTime();
			}
			if (dateObjA < yesterday && dateObjB < yesterday) {
				// Both are past dates, sort in descending order
				return dateObjB.getTime() - dateObjA.getTime();
			}

			// If one is future and one is past
			if (dateObjA > tomorrow) return -1;
			if (dateObjB > tomorrow) return 1;

			// This case should not happen, but just in case
			return dateObjB.getTime() - dateObjA.getTime();
		});
	}, [uniqueMeetings]);

	return groupedAndSortedMeetings.map(([date, meetings], index, array) => (
		<MeetingGroup
			direction="column"
			id={`group-${date}`}
			key={`group-${date}`}
			ref={date === "Today" ? todayGroupRef : null}
		>
			<Text
				size="1"
				id={formatMeetingDateTime(date).dayDate}
				style={{
					padding: "6px 6px 4px 16px",
					color: date === "Today" ? "var(--mb-blue-8)" : "var(--mb-gray-11)",
					fontWeight: "bold",
					fontSize: date === "Today" ? "13px" : "12px",
				}}
			>
				{date}
			</Text>
			{meetings.map((meeting) => (
				<MeetingListItem meeting={meeting} index={index} />
			))}
			{index !== array.length - 1 && (
				<Flex
					style={{
						alignSelf: "center",
						height: "1px",
						width: "calc(100% - 30px)",
						borderBottom: "1px solid var(--mb-gray-5)",
					}}
				/>
			)}
		</MeetingGroup>
	));
};

const MeetingsList: React.FC<MeetingsListProps> = ({
	toggleSidebar,
	isSidebarOpen,
}) => {
	return (
		<MeetingsListContainer align={isSidebarOpen ? "start" : "center"}>
			{isSidebarOpen ? (
				<Flex direction="column" style={{ width: "100%", overflow: "hidden" }}>
					<MeetingsListContentHeaderContainer align="center" justify="between">
						<Icon.ListBullets weight="bold" />
						<Text size="3" weight="medium" style={{ flex: 1 }}>
							Meetings
						</Text>
						<Icon.ArrowLineLeft
							style={{ cursor: "pointer" }}
							onClick={toggleSidebar}
						/>
					</MeetingsListContentHeaderContainer>
					<MeetingsListContentContainer>
						<InfiniteScroll
							initialCursor={new Date().toISOString()}
							queryFn={useMeetingsListBidirectional}
							renderItems={(meetings: Meeting[] = []) => (
								<MeetingsGroup meetings={meetings} />
							)}
							enablePastScroll={true}
							initialPosition="group-Today"
						/>
					</MeetingsListContentContainer>
				</Flex>
			) : (
				<MeetingsListSidebarToggleButton onClick={toggleSidebar} align="center">
					<Icon.ListBullets weight="bold" />
				</MeetingsListSidebarToggleButton>
			)}
		</MeetingsListContainer>
	);
};

export default MeetingsList;
