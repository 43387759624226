import React from "react";
import { Flex, Text, DropdownMenu } from "@mightybot/web-ui";

interface FeedExtrasProps {
	onDismiss: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const FeedExtras: React.FC<FeedExtrasProps> = ({ onDismiss }) => {
	return (
		<DropdownMenu.Item onClick={onDismiss}>
			<Flex align="center" style={{ gap: 8 }}>
				<Text>Dismiss</Text>
			</Flex>
		</DropdownMenu.Item>
	);
};

export default FeedExtras;
