import React, { useState } from "react";
import { Dialog, Flex, Text, Button, Icon, Spinner } from "@mightybot/web-ui";
import { AutopilotTestRunRequest, Event } from "@mightybot/core";
import { capitalize, cleanAgenda } from "../../utils/helpers";

type RecentEventDialogProps = {
	showModal: boolean;
	handleModalClose: () => void;
	selectedEvent: Event;
	onTestRun: (payload: AutopilotTestRunRequest) => Promise<boolean>;
	autopilotId: string;
};

const RecentEventsDialog = ({
	showModal,
	handleModalClose,
	selectedEvent,
	onTestRun,
	autopilotId,
}: RecentEventDialogProps) => {
	const [loading, setLoading] = useState(false);
	const handleTestRun = async () => {
		if (selectedEvent) {
			setLoading(true);
			const success = await onTestRun({
				autopilot_id: autopilotId ?? "",
				variable_values: selectedEvent.event_data,
			});
			setLoading(false);
			if (success) {
				handleModalClose();
			}
		}
	};

	const renderValue = (value: any, key: string): string => {
		if (value === null || value === undefined) return "N/A";
		if (key === "meeting_description") return cleanAgenda(value);
		if (["event_start_time", "event_end_time"].includes(key))
			return new Date(value).toLocaleString();
		if (key === "meeting_agenda") return cleanAgenda(value);
		if (typeof value === "object") return JSON.stringify(value);
		return String(value);
	};

	return (
		<Dialog.Root open={showModal} onOpenChange={handleModalClose}>
			<Dialog.Content>
				<Dialog.Title
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Text weight="bold">Event Variables</Text>
					<Icon.X onClick={handleModalClose} style={{ cursor: "pointer" }} />
				</Dialog.Title>
				<Flex direction="column" style={{ gap: "16px" }} align="center">
					<Flex direction="column" style={{ gap: "8px", width: "100%" }}>
						{Object.entries(selectedEvent?.event_data || {}).map(
							([key, value]) => (
								<Flex key={key} style={{ gap: "10px" }}>
									<Text style={{ width: "20%" }}>
										{capitalize(key.replace(/_/g, " "))}
									</Text>
									<Text style={{ width: "80%", textWrap: "wrap" }}>
										{renderValue(value, key)}
									</Text>
								</Flex>
							),
						)}
					</Flex>
					{/* @ts-ignore */}
					<Button
						style={{ cursor: "pointer", width: "60%" }}
						onClick={handleTestRun}
					>
						Run Test with Selected Event
						{loading && <Spinner size={16} />}
					</Button>
				</Flex>
			</Dialog.Content>
		</Dialog.Root>
	);
};

export default RecentEventsDialog;
