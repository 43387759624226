import React from "react";
import { PeopleContainer } from "./styled";
import Placeholder from "../../utils/Placeholder";
import { PlaceholderPeoplePage } from "@mightybot/core-ui";

const People: React.FC = () => {
	return (
		<PeopleContainer>
			<Placeholder
				placeholder={PlaceholderPeoplePage}
				style={{ alignItems: "flex-start" }}
				imageStyle={{ height: "auto" }}
			/>
		</PeopleContainer>
	);
};

export default People;
