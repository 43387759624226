import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
	AdminRoute as Admin,
	AuthCallbackRoute as AuthCallback,
	AuthenticatedRoute as Authenticated,
	AuthRedirectRoute as AuthRedirect,
	AuthRoute as Auth,
	ChatsRoute as Chats,
	CompaniesRoute as Companies,
	DealsRoute as Deals,
	FeedbackPage as Feedback,
	HomeRoute as Home,
	MeetingsRoute as Meetings,
	OnboardingRoute as Onboarding,
	RootRoute as Root,
	SettingsRoute as Settings,
	UnsubscribeRoute as Unsubscribe,
	WelcomeRoute as Welcome,
	PeopleRoute as People,
	SearchRoute as Search,
} from "./routes";

import ErrorFallback from "./components/ErrorFallback";

// Define route configuration using objects for better type safety and maintainability
const routes = {
	auth: {
		signin: "/signin",
		login: "/login",
		signup: "/signup",
		callback: "/auth/callback",
		oauthCallback: "/oauth/callback",
	},
	onboarding: {
		root: "/onboarding",
		welcome: "/welcome",
	},
	app: {
		home: "/home",
		chats: "/chats",
		companies: "/companies",
		deals: "/deals",
		people: "/people",
		search: "/search",
		meetings: "/meetings",
		settings: "/settings",
	},
	admin: {
		root: "/admin",
		enterprise: "/admin/enterprise",
	},
} as const;

export const createAppRouter = () => {
	const sentryCreateBrowserRouter =
		Sentry.wrapCreateBrowserRouter(createBrowserRouter);

	const routeConfig = [
		{
			path: "/",
			element: <Root />,
			errorElement: <ErrorFallback />,
			children: [
				{
					errorElement: <ErrorFallback />,
					children: [
						// Auth Routes
						{
							path: routes.auth.signin,
							element: <Auth />,
						},
						{
							path: routes.auth.login,
							element: <Auth />,
						},
						{
							path: routes.auth.signup,
							element: <Auth />,
						},
						{
							path: routes.auth.callback,
							element: <AuthCallback />,
						},
						{
							path: routes.auth.oauthCallback,
							element: <AuthCallback />,
						},

						// Onboarding Routes
						{
							path: routes.onboarding.root,
							element: <Onboarding />,
						},
						{
							path: routes.onboarding.welcome,
							element: <Welcome />,
						},

						// Protected Routes
						{
							element: <Authenticated />,
							children: [
								// Main App Routes
								{
									path: routes.app.home,
									element: <Home />,
								},
								{
									path: routes.app.chats,
									element: <Chats />,
								},
								{
									path: `${routes.app.chats}/:chatId`,
									element: <Chats />,
								},
								{
									path: routes.app.companies,
									element: <Companies />,
								},
								{
									path: routes.app.deals,
									element: <Deals />,
								},
								{
									path: routes.app.people,
									element: <People />,
								},
								{
									path: routes.app.search,
									element: <Search />,
								},
								{
									path: routes.app.meetings,
									element: <Meetings />,
								},
								{
									path: `${routes.app.meetings}/:meetingId`,
									element: <Meetings />,
								},
								{
									path: `${routes.app.meetings}/:meetingId/:view`,
									element: <Meetings />,
								},
								{
									path: routes.app.settings,
									element: <Settings />,
								},
								{
									path: `${routes.app.settings}/:settingsTab`,
									element: <Settings />,
								},

								// Admin Routes
								{
									path: routes.admin.root,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/:adminTab`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise`,
									element: <Navigate to="/admin/enterprises" replace />,
								},
								{
									path: `${routes.admin.root}/:adminTab/:enterpriseId`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/:adminTab/:autopilotId`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/:adminTab/:autopilotId/edit`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/:adminTab/:autopilotId/new`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/autopilot/:autopilotId/:adminTab`,
									element: <Admin />,
								},

								// Utility Routes
								{
									path: "/email/unsubscribe",
									element: <Unsubscribe />,
								},
								{
									path: "/email/feedback",
									element: <Feedback />,
								},
								{
									path: "/integrations/*",
									element: <AuthRedirect />,
								},

								// Redirects
								{
									path: "/connect",
									element: <Navigate to="/settings/connect" replace />,
								},
							],
						},

						// 404 Route
						{
							path: "*",
							element: <ErrorFallback errorType="routeNotFound" />,
						},
					],
				},
			],
		},
	];

	return sentryCreateBrowserRouter(routeConfig, { basename: "/app" });
};
