import React from "react";
import { MightyBotLogo } from "@mightybot/core-ui";
import { Flex, Button } from "@mightybot/web-ui";
import { ErrorFallbackContainer } from "./styled";

type ErrorFallbackProps = {
	errorType?: "notAdmin" | "routeNotFound" | "default";
};

export default function ErrorFallback({
	errorType = "default",
}: ErrorFallbackProps) {
	const getErrorMessage = () => {
		switch (errorType) {
			case "notAdmin":
				return "You don't have permission to access this page.";
			case "routeNotFound":
				return "Oops! Page not found.";
			default:
				return "An unexpected error occurred.";
		}
	};

	const getSubMessage = () => {
		switch (errorType) {
			case "notAdmin":
				return "Please contact your administrator for access.";
			case "routeNotFound":
				return "This is not what you are looking for. Let's get back to the Connect apps page.";
			default:
				return "We're working on fixing this issue. Please try again later.";
		}
	};

	const getButtonText = () => {
		switch (errorType) {
			case "notAdmin":
				return "Go to Homepage";
			case "routeNotFound":
				return "Go to Connect Apps";
			default:
				return "Go to Homepage";
		}
	};

	const handleButtonClick = () => {
		window.location.href = `${window.location.origin}/app/home`;
	};

	return (
		<ErrorFallbackContainer>
			<Flex
				justify="center"
				align="center"
				style={{ height: "100vh", width: "60%" }}
			>
				<Flex
					justify="center"
					align="center"
					direction="column"
					style={{
						height: "400px",
						border: "1px solid #e0e0e0",
						borderRadius: "16px",
						width: "100%",
					}}
				>
					<Flex
						justify="between"
						align="center"
						direction="column"
						style={{ height: "100%" }}
					>
						<Flex
							style={{ padding: "40px", gap: "20px" }}
							direction="column"
							align="center"
							justify="center"
						>
							<img
								src={MightyBotLogo as string}
								alt="Mightybot Logo"
								style={{ width: "40px", height: "40px" }}
							/>
							<p style={{ fontSize: "22px", fontWeight: "500" }}>
								{getErrorMessage()}
							</p>
							<p style={{ fontSize: "14px", fontWeight: "400" }}>
								{getSubMessage()}
							</p>
							{/* @ts-ignore */}
							<Button
								onClick={handleButtonClick}
								style={{
									borderRadius: "22px",
									width: "300px",
									height: "44px",
									cursor: "pointer",
								}}
							>
								{getButtonText()}
							</Button>
							<p style={{ fontSize: "14px", fontWeight: "400" }}>
								If you face any issue, feel free to reach out to us via email at{" "}
								<a
									href="mailto:support@mightybot.ai"
									style={{ color: "var(--mb-blue-8)" }}
								>
									support@mightybot.ai
								</a>
								.
							</p>
						</Flex>
					</Flex>
					<Flex
						align="center"
						justify="center"
						style={{
							backgroundColor: "var(--gray-2)",
							width: "100%",
							borderRadius: "0px 0px 16px 16px",
							color: "var(--gray-9)",
							padding: "20px",
						}}
					>
						<p>© MightyBot. All rights reserved.</p>
					</Flex>
				</Flex>
			</Flex>
		</ErrorFallbackContainer>
	);
}
