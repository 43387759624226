import React, { useEffect, useRef } from "react";
import {
	useAuthState,
	useModelSelection,
	Model,
	CompletionCurrentItem,
	useStreamingCompletion,
} from "@mightybot/core";
import MeetingChat from "../MeetingChat";
import { useMeetingChat } from "../../contexts/MeetingChatContext";
import { useParams } from "react-router-dom";
import { MeetingChatStreamingContainer } from "./styled";
import { useMeetingContentNavigation } from "../../contexts/MeetingContentNavigationContext";

type MeetingChatStreamingProps = {
	completionItem: CompletionCurrentItem;
	updateCurrentCompletionsItem: (item: CompletionCurrentItem) => void;
};

const MeetingChatStreaming: React.FC<MeetingChatStreamingProps> = ({
	completionItem,
	updateCurrentCompletionsItem,
}) => {
	const meetingId = useParams().meetingId;
	const { getSelectedModel } = useModelSelection();
	const { accessToken, initAccessToken } = useAuthState();
	const { shouldStreamReply, resetStreamReply } = useMeetingChat();

	const { endOfContentRef, scrollerRef, scrollToBottom } =
		useMeetingContentNavigation();

	const streamingRef = useRef<HTMLDivElement>(null);

	const streamCount = useRef(0);
	const { messages, isStreaming, error, startStreaming } =
		useStreamingCompletion();

	useEffect(() => {
		if (!accessToken) {
			initAccessToken();
		}
	}, [accessToken, initAccessToken]);

	useEffect(() => {
		if (
			isStreaming &&
			scrollerRef.current &&
			endOfContentRef.current &&
			streamingRef.current
		) {
			endOfContentRef.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
			});
			streamingRef.current?.focus();

			const resizeObserver = new ResizeObserver(() => {
				scrollToBottom();
			});

			resizeObserver.observe(scrollerRef.current);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, [isStreaming]);

	const streamReply = () => {
		if (shouldStreamReply && streamCount.current < 1 && accessToken) {
			const model: Model = getSelectedModel();
			const payload = {
				collection_id: meetingId ?? "",
				context: completionItem?.context ?? {},
				user_question: completionItem?.user_question ?? "",
				llm_model: model?.id,
			};
			startStreaming(payload, accessToken);
			streamCount.current++;
			setTimeout(() => {
				streamCount.current = 0;
			}, 1000);
		}
	};

	useEffect(() => {
		streamReply();
	}, [shouldStreamReply, accessToken]);

	useEffect(() => {
		if (!isStreaming && messages) {
			resetStreamReply();
			updateCurrentCompletionsItem({
				...completionItem,
				response: messages,
			});
		}
	}, [isStreaming, messages]);

	if (error) {
		console.error("Streaming error:", error);
	}

	if (!isStreaming && !messages) return null;

	return (
		<MeetingChatStreamingContainer
			direction="column"
			ref={streamingRef}
			id={`meeting-chat-streaming-${completionItem.id}`}
			tabIndex={-1}
		>
			<MeetingChat
				timestamp={new Date().toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
					hour12: true,
				})}
				content={completionItem?.context?.prompt ?? ""}
				question={completionItem?.user_question ?? ""}
				reply={completionItem.response ?? messages ?? ""}
				isStreaming={isStreaming}
			/>
		</MeetingChatStreamingContainer>
	);
};

export default MeetingChatStreaming;
