import styled from "styled-components";
import { Flex, mediaQueries, Text, Popover } from "@mightybot/web-ui";

export const PopoverRoot = styled(Popover.Root)`
	width: 100%;
	height: 100%;
`;
export const PopoverContent = styled(Popover.Content)`
	width: 100%;
	max-width: 360px;
	height: 100%;
	border-radius: 12px;
	box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);
	border: 1px solid var(--mb-gray-4);
	background: linear-gradient(
		360deg,
		var(--mb-gray-1) 0%,
		var(--mb-blue-2) 100%
	);
	padding: 50px 30px;
`;
export const Container = styled(Flex)`
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 6px;
`;

export const AvatarContainer = styled(Flex)`
	justify-content: flex-start;
	align-items: flex-start;
	gap: -10px;
`;

export const Avatar = styled.img`
	width: 80px;
	height: 48px;
`;
export const FounderButton = styled(Flex)`
	align-self: stretch;
	height: 44px;
	padding: 0 10px;
	background: var(--mb-gray-1);
	border-radius: 6px;
	border: 1px solid var(--mb-blue-2);
	align-items: center;
	cursor: pointer;
	transition: background-color 0.2s;
	margin-top: 4px;

	&:hover {
		background: var(--mb-blue-1);
	}
`;

export const GiftCardButtonContainer = styled(Flex)`
	width: 100%;
	padding: 8px;
	border-radius: 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3px;
	cursor: pointer;
	transition: all 0.15s ease;

	&:hover {
		background: rgba(255, 255, 255, 0.2);
	}

	&:active {
		background: rgba(255, 255, 255, 0.15);
	}

	${mediaQueries.tablet} {
		padding: 6px;
	}
`;
export const GiftCardIconContainer = styled(Flex)`
	width: 25px;
	height: 25px;
	position: relative;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const GiftCardText = styled(Text)`
	align-self: stretch;
	text-align: center;
	color: white;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	word-wrap: break-word;
	white-space: normal;
	margin: 0px -14px;

	${mediaQueries.tablet} {
		font-size: 10px;
		line-height: 12px;
	}
`;
