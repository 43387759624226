import React, { createContext, useContext, useState } from "react";

interface MeetingChatContextType {
	triggerStreamReply: () => void;
	shouldStreamReply: boolean;
	resetStreamReply: () => void;
}

const MeetingChatContext = createContext<MeetingChatContextType | undefined>(
	undefined,
);

export const MeetingChatProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [shouldStreamReply, setShouldStreamReply] = useState(false);

	const triggerStreamReply = () => {
		setShouldStreamReply(true);
	};

	const resetStreamReply = () => {
		setShouldStreamReply(false);
	};

	return (
		<MeetingChatContext.Provider
			value={{
				triggerStreamReply,
				shouldStreamReply,
				resetStreamReply,
			}}
		>
			{children}
		</MeetingChatContext.Provider>
	);
};

export const useMeetingChat = () => {
	const context = useContext(MeetingChatContext);
	if (context === undefined) {
		throw new Error("useMeetingChat must be used within a MeetingChatProvider");
	}
	return context;
};
