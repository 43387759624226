import React, { useEffect, useState, useRef } from "react";
import {
	StyledBox,
	StyledTextField,
	PastSearchesContainer,
	PastSearchItem,
	SearchQueryText,
	ResultCount,
} from "./styled";
import { Icon, TextField, IconButton, Separator } from "@mightybot/web-ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	Search,
	useSearch,
	useSearchPastSearches,
	useSearchSelection,
} from "@mightybot/core";
import debounce from "lodash/debounce";
import { UUID } from "crypto";

interface SearchBarProps {
	clearSearch: () => void;
	isDialog: boolean;
	handleSearch: (query: string) => Promise<void>;
	isFirstRender: React.MutableRefObject<boolean>;
}

const SearchBar = ({
	clearSearch,
	isDialog,
	handleSearch,
	isFirstRender,
}: SearchBarProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const activeSearchId = searchParams.get("searchId") as UUID;
	const { activeSearch, userSearches } = useSearch(activeSearchId);
	const { selectedResults, clearSelection } = useSearchSelection();
	const [searchQuery, setSearchQuery] = useState("");
	const { pastSearches, searchPastSearches } = useSearchPastSearches();
	const [isResultsVisible, setIsResultsVisible] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const [isFocused, setIsFocused] = useState(false);

	const debouncedSearch = useRef(
		debounce((query: string) => {
			searchPastSearches(query);
		}, 300),
	).current;

	const searchContainerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	// Add effect to clean up states when activeSearchId changes
	useEffect(() => {
		if (!activeSearchId) {
			setSearchQuery("");
			setIsResultsVisible(false);
			setSelectedIndex(-1);
			setIsFocused(false);
		}
	}, [activeSearchId]);

	// Modify the searchQuery effect
	useEffect(() => {
		if (searchQuery) {
			debouncedSearch(searchQuery);
		}
	}, [searchQuery, setSearchParams, debouncedSearch]);

	useEffect(() => {
		if (activeSearch) {
			setSearchQuery(activeSearch.query || "");
		} else {
			setSearchQuery("");
		}
	}, [activeSearch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchContainerRef.current &&
				!searchContainerRef.current.contains(event.target as Node)
			) {
				setIsResultsVisible(false);
				setIsFocused(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		setSelectedIndex(-1);
	}, [searchQuery]);

	useEffect(() => {
		if (isDialog && !activeSearchId && selectedResults.length === 0) {
			inputRef.current?.focus();
		}
	}, [isDialog, activeSearchId, selectedResults]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsResultsVisible(true);
		setSearchQuery(e.target.value);
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (selectedIndex >= 0 && pastSearches && pastSearches[selectedIndex]) {
				await onSearch(pastSearches[selectedIndex].query);
			} else {
				await onSearch(searchQuery);
			}
		} else if (e.key === "ArrowDown") {
			e.preventDefault();
			if (!isResultsVisible) {
				setIsResultsVisible(true);
				setIsFocused(true);
				if (!searchQuery) {
					searchPastSearches("");
				}
				setSelectedIndex(-1);
				return;
			}

			if (visiblePastSearches?.length) {
				setSelectedIndex((prev) =>
					prev < visiblePastSearches.length - 1 ? prev + 1 : prev,
				);
			}
		} else if (e.key === "ArrowUp") {
			e.preventDefault();
			if (isResultsVisible && visiblePastSearches?.length) {
				setSelectedIndex((prev) => (prev > -1 ? prev - 1 : -1));
				if (selectedIndex === 0) {
					setIsResultsVisible(false);
					setIsFocused(false);
				}
			}
		} else if (e.key === "Escape") {
			e.preventDefault();
			setIsResultsVisible(false);
			setIsFocused(false);
			setSelectedIndex(-1);
		}
	};

	const onSearch = async (query: string) => {
		setIsResultsVisible(false);
		setIsFocused(false);
		setSelectedIndex(-1);

		await handleSearch(query);
	};

	const handleClearSearch = () => {
		setSearchQuery("");
		clearSearch();
		clearSelection();

		const params = new URLSearchParams(searchParams);
		const filteredParams = Array.from(params.entries()).filter(
			([key]) => key !== "q" && key !== "searchId",
		);

		setSearchParams(Object.fromEntries(filteredParams));
		setIsResultsVisible(false);
		setSelectedIndex(-1);
	};

	const handlePastSearchClick = (pastSearch: Search) => {
		isFirstRender.current = true;
		setIsResultsVisible(false);
		setIsFocused(false);
		setSelectedIndex(-1);

		if (!isDialog) {
			navigate(`/search?searchId=${pastSearch.id}&q=${pastSearch.query}`);
		} else {
			const params = new URLSearchParams(searchParams);
			const existingParams = Object.fromEntries(params.entries());

			setSearchParams({
				...existingParams,
				q: pastSearch.query,
				searchId: pastSearch.id,
			});
		}
	};

	const handleFocus = () => {
		setIsFocused(true);
		if (!searchQuery) {
			searchPastSearches("");
		}
	};

	const handleBlur = () => {
		// Don't set isFocused to false here as we want to handle this in the clickOutside handler
	};

	const visiblePastSearches = searchQuery
		? pastSearches
		: userSearches?.slice(0, 5) || [];

	const isPastSearchesVisible =
		(isFocused || isResultsVisible) &&
		visiblePastSearches &&
		visiblePastSearches.length > 0;

	return (
		<StyledBox width="100%" position="relative" ref={searchContainerRef}>
			<StyledTextField
				ref={inputRef}
				placeholder="Search Files, Docs, Emails, Slack, Meetings, Linear"
				size="3"
				value={searchQuery}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				onFocus={handleFocus}
				onBlur={handleBlur}
				isexpanded={isPastSearchesVisible ? "true" : "false"}
			>
				<TextField.Slot>
					<Icon.MagnifyingGlass width="16" height="16" />
				</TextField.Slot>
				<TextField.Slot pr="3">
					{searchQuery && (
						<IconButton
							size="2"
							variant="ghost"
							onClick={handleClearSearch}
							style={{ cursor: "pointer" }}
						>
							<Icon.X width="16" height="16" />
						</IconButton>
					)}
				</TextField.Slot>
			</StyledTextField>
			<PastSearchesContainer
				isvisible={isPastSearchesVisible ? "true" : "false"}
			>
				{isPastSearchesVisible && pastSearches && pastSearches.length > 0 && (
					<Separator
						style={{
							width: "calc(100% - 24px)",
							marginBottom: "10px",
							flexShrink: 0,
							alignSelf: "center",
						}}
					/>
				)}
				{isPastSearchesVisible &&
					visiblePastSearches &&
					visiblePastSearches.map((search, index) => (
						<PastSearchItem
							key={index}
							onClick={() => handlePastSearchClick(search)}
							isselected={index === selectedIndex ? "true" : "false"}
						>
							<SearchQueryText>
								<Icon.MagnifyingGlass width="16" height="16" />
								{search.query}
							</SearchQueryText>
							{search.results_count && (
								<ResultCount>
									{search.results_count ?? 0}{" "}
									{search.results_count === 1 ? "result" : "results"}
								</ResultCount>
							)}
						</PastSearchItem>
					))}
			</PastSearchesContainer>
		</StyledBox>
	);
};

export default SearchBar;
