import React from "react";
import {
	Flex,
	AvatarWrapper,
	Tooltip,
	CustomReactMarkdown,
	Text,
} from "@mightybot/web-ui";
import {
	ChatUserMessageContainer,
	customMarkdownStyles,
	MessageBackground,
} from "./styled";
import TruncatedMarkdown from "../TruncatedMarkdown";
import { ChatMessageProps } from "./Chat";
import { formatTimestamp } from "../../utils/helpers";

interface ChatUserMessageProps
	extends Omit<ChatMessageProps, "reply" | "isStreaming"> {}

const anonymousUser = {
	name: "Anonymous",
	profile_pic: "",
	email: "",
	id: "",
};

const ChatUserMessage: React.FC<ChatUserMessageProps> = ({
	timestamp,
	content,
	question,
	author,
}) => {
	const formattedQuestion = question.replace(/\\n/g, "\n");
	return (
		<ChatUserMessageContainer>
			<Tooltip content={author?.name}>
				<AvatarWrapper
					src={author?.profile_pic ?? anonymousUser.profile_pic}
					alt={author?.name ?? anonymousUser.name}
					radius="full"
					styles={{
						width: "32px",
						height: "32px",
					}}
				/>
			</Tooltip>
			<Flex direction="column" gap="8" style={{ flex: 1, minWidth: 0 }}>
				<MessageBackground>
					<CustomReactMarkdown styles={customMarkdownStyles}>
						{formattedQuestion}
					</CustomReactMarkdown>
				</MessageBackground>
				{content && <TruncatedMarkdown content={content} singleLine />}
			</Flex>
			<Flex justify="end" style={{ alignSelf: "center" }}>
				<Text
					weight="light"
					size="1"
					style={{
						color: "var(--mb-color-light-gray-9)",
						flexShrink: 0,
					}}
				>
					{formatTimestamp(timestamp)}
				</Text>
			</Flex>
		</ChatUserMessageContainer>
	);
};

export default ChatUserMessage;
