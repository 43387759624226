import React from "react";
import Welcome from "../components/Welcome/Welcome";

export default function WelcomeRoute() {
	return (
		<React.Fragment>
			<Welcome />
		</React.Fragment>
	);
}
