import styled from "styled-components";
import { Button, Dialog, Flex, Text, mediaQueries } from "@mightybot/web-ui";

export const ChatsContainer = styled(Flex)`
	height: 100%;
	width: 100%;
	justify-content: center;
	background-color: var(--mb-gray-1);
	overflow-y: hidden;

	${mediaQueries.tablet} {
		flex-direction: column;
	}
`;

export const ChatsInnerContainer = styled(Flex)`
	flex-direction: column;
	width: 100%;
	max-width: 870px;
	flex: 1;
	position: relative;

	${mediaQueries.tablet} {
		padding: 0px 12px;
	}
`;

export const ChatsContentContainer = styled(Flex)<{ newchat: string }>`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	position: relative;
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
	flex-direction: column;
	align-items: center;
	top: ${({ newchat }) => (newchat === "true" ? "150px" : "none")};

	${mediaQueries.desktop} {
		padding: 0px 12px;
	}

	${mediaQueries.tablet} {
		top: ${({ newchat }) => (newchat === "true" ? "60px" : "none")};
		padding: 0px;
	}

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 4px;
	}
`;

export const ChatsDefaultTitle = styled(Text)`
	font-size: 22px;
	margin-bottom: 30px;
	font-weight: 500;
`;

export const StickyContainer = styled(Flex)`
	position: sticky;
	top: 0;
	background-color: var(--mb-gray-1);
	z-index: 10;
	padding-top: 10px;
	width: 100%;
	max-width: 870px;
	flex-direction: column;
`;

export const MessagesContainer = styled.div<{ newchat: string }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px 0px;
	gap: 16px;
	flex: ${({ newchat }) => (newchat === "true" ? "0" : "1")};
`;

export const ChatInputArea = styled.div<{ newchat: string }>`
	background-color: var(--mb-gray-1);
	width: 100%;
	position: sticky;
	bottom: ${({ newchat }) => (newchat === "true" ? "auto" : "0")};
	transition: bottom 0.3s ease-in-out;
	padding: 10px 0px;
`;

export const SidebarContainer = styled(Flex)`
	width: 280px;
	background-color: white;
	border-right: 1px solid var(--mb-gray-6);
	padding: 24px 16px;
	flex-direction: column;
`;

export const ChatContentArea = styled.div`
	width: 100%;
	gap: 10px;
`;

export const ChatsHeaderOuterContainer = styled(Flex)`
	width: 100%;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: 0;
	background: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(8px);
	webkit-backdrop-filter: blur(8px);
	z-index: 10;
	height: 64px;
`;

export const ChatsHeaderInnerContainer = styled(Flex)`
	max-width: 870px;
	width: 100%;
	border-bottom: 1px solid #ccc;
	padding: 16px 0px;
	gap: 10px;
	${mediaQueries.tablet} {
		padding: 12px;
	}
`;

export const GoToEndButtonContainer = styled(Flex)`
	position: absolute;
	height: 45px;
	width: 100%;
	max-width: 870px;
	justify-content: center;
	align-items: center;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
	pointer-events: none;
	z-index: 100;
	overflow: hidden;
	padding: 10px 0px;
	&.visible {
		opacity: 1;
		pointer-events: all;
	}
`;

export const GoToEndButton = styled.button`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: white;
	border: 1px solid #e0e0e0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s ease-in-out;

	&:hover {
		transform: translateY(-2px);
	}
`;

export const SearchDialogContainer = styled(Dialog.Content)`
	background-color: white;
	box-shadow:
		hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	position: fixed;
	top: 50%;
	left: 55%;
	transform: translate(-50%, -50%);
	width: 100%;
	max-width: 1000px;
	max-height: 90vh;
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
	z-index: 1001;
	border-radius: 25px;
	padding: 30px 0px 0px;
	${mediaQueries.desktopL} {
		left: 50%;
		width: 96%;
	}
`;

export const SearchDialogActions = styled(Flex)`
	height: 70px;
	padding: 0px 30px;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	align-self: stretch;
	border-top: 1px solid #e0e0e0;
	background: linear-gradient(90deg, #f3f6e9 0%, #e4edfc 100%), #edf3ff;
`;

export const AddButton = styled(Button)<{ hasselection: string }>`
	border-radius: var(--radius-pill);
	background: ${(props) =>
		props.hasselection === "true" ? "#C4E75A" : "var(--mb-gray-4)"};
	min-width: 80px;
	cursor: pointer;
	color: var(--mb-gray-10);
`;

export const CancelButton = styled(Button)`
	border-radius: var(--radius-pill);
	min-width: 80px;
	cursor: pointer;
`;

export const MobileSidebarContainer = styled.div`
	position: sticky;
	top: 0;
	z-index: 11;
	width: 100%;
	background-color: var(--mb-gray-1);
	max-height: 100px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;
