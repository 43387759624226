import React, { useState } from "react";

import {
	Skeleton,
	Flex,
	Button,
	Icon,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";

import { useMeeting } from "@mightybot/core";
import MeetingOutline from "./MeetingOutline";
import { useNavigate, useParams } from "react-router-dom";
import {
	MeetingDetailsSidebarContainer,
	MobileSidebarContainer,
} from "./styled";

interface MeetingDetailsSidebarProps {
	isSidebarOpen: boolean;
	toggleSidebar: () => void;
}

const MeetingDetailsSidebar: React.FC<MeetingDetailsSidebarProps> = ({
	isSidebarOpen,
	toggleSidebar,
}) => {
	const meetingId = useParams().meetingId;
	const { isLoading } = useMeeting();
	const navigate = useNavigate();
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const [isMobileOutlineOpen, setIsMobileOutlineOpen] = useState(false);

	const handleBackToMeetings = () => {
		navigate(`/meetings/${meetingId}/preview`);
	};

	const toggleMobileOutline = () => {
		setIsMobileOutlineOpen(!isMobileOutlineOpen);
	};

	if (isMobile) {
		return (
			<MobileSidebarContainer>
				<MeetingOutline
					toggleSidebar={toggleMobileOutline}
					isSidebarOpen={isMobileOutlineOpen}
					isMobile={true}
				/>
			</MobileSidebarContainer>
		);
	}

	return (
		<MeetingDetailsSidebarContainer
			style={{ maxWidth: isSidebarOpen ? "270px" : "80px" }}
		>
			{!isMobile && (
				<Button
					onClick={handleBackToMeetings}
					radius="full"
					style={{
						fontWeight: 500,
						padding: "16px",
						height: 38,
						cursor: "pointer",
					}}
				>
					<Icon.ArrowLeft width={16} height={16} weight="bold" />
					{isSidebarOpen && "All Meetings"}
				</Button>
			)}
			{isLoading ? (
				<Flex direction="column" gap="2" style={{ padding: "16px" }}>
					<Skeleton width="100%" height="24px" />
					<Skeleton width="80%" height="16px" />
					<Skeleton width="90%" height="16px" />
					<Skeleton width="70%" height="16px" />
				</Flex>
			) : (
				<MeetingOutline
					toggleSidebar={toggleSidebar}
					isSidebarOpen={isSidebarOpen}
					isMobile={false}
				/>
			)}
		</MeetingDetailsSidebarContainer>
	);
};

export default MeetingDetailsSidebar;
