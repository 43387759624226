import React, { useState } from "react";
import {
	SidebarContainer,
	NavigationMenu,
	NavigationMenuList,
	NavigationMenuItem,
	NavigationMenuLink,
	IconCaretRight,
} from "./styled";
import { Flex, Icon, Text } from "@mightybot/web-ui";
import { useLocation } from "react-router-dom";
import {
	useFeatureFlag,
	NOTIFICATION_TAB_FEATURE,
	useMe,
	MAPLE_BILLING_FEATURE,
} from "@mightybot/core";

interface DropdownItem {
	to: string;
	label: string;
}

interface NavItem {
	to: string;
	label: string;
	icon: React.ReactNode;
	order: number;
	isDropdown?: boolean;
	dropdownItems?: DropdownItem[];
	visibilityCheck: (flags: FeatureFlags) => boolean;
}

interface FeatureFlags {
	showNotificationTab: boolean;
	isAdmin: boolean;
	showMapleBilling: boolean;
}

const navItems: NavItem[] = [
	{
		to: "/settings/general",
		label: "General",
		icon: <Icon.Gear size={20} />,
		order: 1,
		visibilityCheck: () => true,
	},
	{
		to: "/settings/personalization",
		label: "Personalization",
		icon: <Icon.Sparkle size={20} />,
		order: 2,
		visibilityCheck: () => false,
	},
	{
		to: "/settings/user-notifications",
		label: "User Notifications",
		icon: <Icon.BellRinging size={20} />,
		order: 3,
		visibilityCheck: ({ showNotificationTab }) => showNotificationTab,
	},
	{
		to: "/settings/connect",
		label: "Connect with Apps",
		icon: <Icon.SquaresFour />,
		order: 4,
		visibilityCheck: () => true,
	},
	{
		to: "/settings/outbox",
		label: "Outbox",
		icon: <Icon.Tray />,
		order: 5,
		visibilityCheck: () => false,
	},
	{
		to: "/admin/enterprises",
		label: "Admin",
		icon: <Icon.Shield />,
		order: 6,
		visibilityCheck: ({ isAdmin }) => isAdmin,
	},
	{
		to: "/settings/privacy",
		label: "Privacy",
		icon: <Icon.LockKey size={20} />,
		order: 7,
		visibilityCheck: () => false,
		isDropdown: true,
		dropdownItems: [
			{ to: "/settings/contacts", label: "Contacts" },
			{ to: "/settings/slack-channels", label: "Slack Channels" },
			{ to: "/settings/google-drive", label: "Google Drive" },
			{ to: "/settings/data-collection", label: "Data Collection" },
		],
	},
	{
		to: "/settings/billing",
		label: "Billing",
		icon: <Icon.Receipt size={20} />,
		order: 8,
		visibilityCheck: ({ showMapleBilling }) => showMapleBilling,
	},
];

const Sidebar: React.FC = () => {
	const { data: user } = useMe();
	const location = useLocation();
	const { pathname } = location;

	const featureFlags: FeatureFlags = {
		showNotificationTab: useFeatureFlag(NOTIFICATION_TAB_FEATURE) ?? false,
		isAdmin: user?.is_admin ?? false,
		showMapleBilling: useFeatureFlag(MAPLE_BILLING_FEATURE) ?? false,
	};

	const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
		{},
	);

	const toggleDropdown = (path: string) => {
		setOpenDropdowns((prev) => ({
			...prev,
			[path]: !prev[path],
		}));
	};

	const isActive = (path: string) => pathname.startsWith(path);

	const enabledNavItems = navItems
		.filter((item) => item.visibilityCheck(featureFlags))
		.sort((a, b) => a.order - b.order);

	return (
		<SidebarContainer>
			<NavigationMenu>
				<NavigationMenuList>
					{enabledNavItems.map((item) => (
						<NavigationMenuItem key={item.to}>
							<NavigationMenuLink
								to={item.to}
								active={`${isActive(item.to)}`}
								onClick={(e) => {
									if (item.isDropdown) {
										e.preventDefault();
										toggleDropdown(item.to);
									}
								}}
							>
								{item.icon}
								<Flex
									justify="between"
									align="center"
									style={{ width: "100%" }}
								>
									<Text weight="medium">{item.label}</Text>
									{item.isDropdown && (
										<IconCaretRight
											size={14}
											style={{
												transform: openDropdowns[item.to]
													? "rotate(90deg)"
													: "rotate(0deg)",
												transition: "transform 0.3s ease",
											}}
										/>
									)}
								</Flex>
							</NavigationMenuLink>

							{item.isDropdown && openDropdowns[item.to] && (
								<NavigationMenuList>
									{item.dropdownItems?.map((dropdownItem) => (
										<NavigationMenuItem
											key={dropdownItem.to}
											style={{
												borderLeft: "1px solid var(--mb-gray-4)",
												marginLeft: "24px",
											}}
										>
											<NavigationMenuLink
												to={dropdownItem.to}
												active={`${isActive(dropdownItem.to)}`}
												style={{ paddingLeft: "15px" }}
											>
												<Text>{dropdownItem.label}</Text>
											</NavigationMenuLink>
										</NavigationMenuItem>
									))}
								</NavigationMenuList>
							)}
						</NavigationMenuItem>
					))}
				</NavigationMenuList>
			</NavigationMenu>
		</SidebarContainer>
	);
};

export default Sidebar;
