import {
	Flex,
	Button,
	mediaQueries,
	breakpointNumbers,
	mediaMaxWidth,
	mediaMinWidth,
} from "@mightybot/web-ui";
import styled from "styled-components";

export const Container = styled(Flex)`
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;

	> * {
		flex-grow: 1;
	}
`;

export const OnboardingWrapper = styled(Flex)`
	height: 100%;
	background: linear-gradient(
			112deg,
			rgba(190, 211, 255, 0.4) 3.75%,
			rgba(255, 255, 255, 0.4) 60.68%
		),
		var(--surface-primary, #fff);
	flex-basis: 60%;
	padding: 60px 140px;
	height: 100%;

	${mediaMaxWidth(breakpointNumbers.tablet)`
		padding: 40px;
		flex-basis: 100%;
	`}

	${mediaMaxWidth(breakpointNumbers.tablet)`
		padding: 20px;
	`}

	${mediaMinWidth(breakpointNumbers.desktopL)`
		flex-basis: 55%;
	`}
`;

export const OverlappingIcons = styled(Flex)`
	justify-content: center;
	margin-bottom: 1rem;

	> * {
		img {
			border: none;
			width: 70%;
			height: 70%;
			object-fit: cover;
		}
	}
`;

export const ContentBox = styled(Flex)`
	border-radius: 10px;
	background-color: white;
	width: 100%;
	border: 1px solid #e0e0e0;
	margin-top: 40px;

	${mediaMaxWidth(breakpointNumbers.laptop)`
		margin-top: 20px;
	`}
`;

export const ContentHeader = styled(Flex)`
	padding: 20px 10px;
	border-bottom: 1px solid #e0e0e0;
	width: 100%;
	flex-direction: column;
	align-items: center;
	gap: 5px;

	${mediaQueries.tablet} {
		text-align: center;
	}
`;

export const ContentBody = styled(Flex)`
	flex-direction: column;
	align-items: center;
	padding: 30px;
	gap: 10px;
`;

export const Content = styled(Flex)`
	flex-direction: column;
	align-items: start;
	gap: 5px;
	width: 100%;
	margin-bottom: 20px;
`;

export const CheckItem = styled(Flex)`
	gap: 0.5rem;
`;

export const ConnectButton = styled(Button)`
	max-width: 306px;
	border-radius: 60px;
	height: 44px;
	width: 100%;
	cursor: pointer;
`;

export const Footer = styled(Flex)`
	margin-top: 75px;
	width: 100%;
`;

export const ResponsiveImage = styled.img`
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
`;

export const ImageContainer = styled(Flex)`
	height: 100vh;
	overflow: hidden;
	flex-basis: 40%;

	${mediaQueries.desktop} {
		display: none;
	}
	${mediaQueries.desktopL} {
		flex-basis: 45%;
	}
`;

export const LogoWrapper = styled(Flex)`
	width: 100%;
	max-width: 200px;
	margin-bottom: 20px;
	height: 42px;

	${mediaMaxWidth(breakpointNumbers.laptop)`
		margin-bottom: 10px;
	`}
`;
