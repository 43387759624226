import styled, { keyframes } from "styled-components";
import { Flex } from "@mightybot/web-ui";

export const ContentWrapper = styled(Flex)`
	width: 100%;
	padding: 20px;
	background: var(--mb-gray-1);
	border-radius: 12px;
	border: 1px solid var(--mb-gray-4);
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ProgressSpinner = styled.div<{ progress: number }>`
	width: 16px;
	height: 16px;
	position: relative;
	background: conic-gradient(
		from -9deg,
		var(--mb-blue-8) ${(props) => props.progress * 3.6}deg,
		rgba(5, 84, 242, 0) 0deg
	);
	border-radius: 9999px;
	animation: ${spin} 1s linear infinite;
`;
