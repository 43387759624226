import { logger } from "@mightybot/core";

export function initializeLogger() {
	if (process.env.NODE_ENV !== "production") return;

	try {
		// Store the original console.error function
		const originalConsoleError = console.error;

		console.error = (...args: any[]) => {
			// Preserve original console.error functionality
			originalConsoleError.apply(console, args);

			const message = args
				.map((arg) => {
					if (typeof arg === "string") {
						return arg;
					} else if (arg instanceof Error) {
						return `${arg.name}: ${arg.message}\n${arg.stack}`;
					} else if (typeof arg === "object") {
						return JSON.stringify(arg, null, 2);
					}
					return String(arg);
				})
				.join(" ");

			// Log using logger
			logger.error(new Error(message));
		};
	} catch (error) {
		console.warn("Failed to initialize custom error logger:", error);
		logger.error(error);
	}
}
