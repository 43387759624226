import React from "react";
import {
	SidebarContainer,
	NavigationMenu,
	NavigationMenuList,
	NavigationMenuItem,
	NavigationMenuLink,
} from "./styled";
import { Icon, Text } from "@mightybot/web-ui";
import { useLocation } from "react-router-dom";

const adminNavItems = [
	{
		to: "/admin/enterprises",
		label: "Autopilots",
		icon: <Icon.Lightning size={20} />,
	},
	{
		to: "/admin/traces",
		label: "Traces",
		icon: <Icon.ListMagnifyingGlass size={20} />,
	},
];

const AdminSidebar: React.FC = () => {
	const location = useLocation();
	const { pathname } = location;

	const isActive = (path: string) => pathname.startsWith(path);

	return (
		<SidebarContainer>
			<NavigationMenu>
				<NavigationMenuList>
					{adminNavItems.map((item) => (
						<NavigationMenuItem key={item.to}>
							<NavigationMenuLink
								to={item.to}
								active={isActive(item.to).toString()}
							>
								{item.icon}
								<Text weight="medium">{item.label}</Text>
							</NavigationMenuLink>
						</NavigationMenuItem>
					))}
				</NavigationMenuList>
			</NavigationMenu>
		</SidebarContainer>
	);
};

export default AdminSidebar;
