import React, { useState, useEffect, useRef } from "react";
import { Icon, Tooltip } from "@mightybot/web-ui";
import { WidgetContainer } from "./styled";

export interface WidgetIcon {
	content: string;
	icon: Icon;
	onClick: () => void;
	transform?: string;
}

interface WidgetPosition {
	top: number;
	left: number;
}

interface WidgetProps {
	widgetPosition: WidgetPosition;
	icons: WidgetIcon[];
}

const Widget: React.FC<WidgetProps> = ({ widgetPosition, icons }) => {
	const [isIconHovered, setIsIconHovered] = useState<Record<string, boolean>>(
		{},
	);
	const [isVisible, setIsVisible] = useState(false);
	const widgetRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsVisible(true);
		}, 50);

		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		if (widgetRef.current && widgetPosition) {
			widgetRef.current.style.transition =
				"top 0.3s ease-out, left 0.3s ease-out, opacity 0.2s ease-out, transform 0.2s ease-out";
			widgetRef.current.style.top = `${widgetPosition.top}px`;
			widgetRef.current.style.left = `${widgetPosition.left}px`;
		}
	}, [widgetPosition]);

	return (
		<WidgetContainer ref={widgetRef} $isVisible={isVisible}>
			{icons.map(({ content, icon: IconComponent, onClick, transform }) => (
				<Tooltip key={content} content={content} side="left">
					<IconComponent
						style={{
							cursor: "pointer",
							color: "white",
							padding: "4px",
							transform,
						}}
						onClick={onClick}
						size={35}
						weight={isIconHovered[content] ? "fill" : "regular"}
						onMouseEnter={() =>
							setIsIconHovered({ ...isIconHovered, [content]: true })
						}
						onMouseLeave={() =>
							setIsIconHovered({ ...isIconHovered, [content]: false })
						}
					/>
				</Tooltip>
			))}
		</WidgetContainer>
	);
};

export default Widget;
