import React from "react";
import { Switch, Text, Flex } from "@mightybot/web-ui";

interface TraceToggleProps {
	showAllTraces: boolean;
	onToggle: (showAll: boolean) => void;
}

const TraceToggle: React.FC<TraceToggleProps> = ({
	showAllTraces,
	onToggle,
}) => {
	return (
		<Flex align="center" justify="center">
			<Text style={{ marginRight: "10px" }}>
				{showAllTraces ? "Unhide Indexing" : "Hide Indexing"}
			</Text>
			<Switch
				style={{
					cursor: "pointer",
				}}
				checked={showAllTraces}
				onCheckedChange={(value) => onToggle(value)}
				radius="full"
				id="trace-toggle"
			/>
		</Flex>
	);
};

export default TraceToggle;
