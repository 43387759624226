import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

export const DealsContainer = styled(Flex)`
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: start;
	overflow: hidden;
`;
