import React, { useRef } from "react";
import { Dialog, Flex, Text, Button, Icon } from "@mightybot/web-ui";
import { ConfirmationDialogRoot } from "./styled";

function ConfirmationDialog({
	open = false,
	onOpenChange,
	handleClose,
	handleCtaClick = () => {},
	ctaText = "Delete",
	secondaryCtaText = "Cancel",
	icon,
	handleSecondaryCtaClick = () => {},
	text = "",
	title = "",
}: {
	open?: boolean;
	handleCtaClick?: () => void;
	onOpenChange: (open: boolean) => void;
	handleClose: () => void;
	handleSecondaryCtaClick?: () => void;
	text: string;
	title: string;
	ctaText?: string;
	secondaryCtaText?: string;
	icon?: React.ReactNode;
}) {
	return (
		<ConfirmationDialogRoot open={open} onOpenChange={onOpenChange}>
			<Dialog.Content style={{ maxWidth: 450 }}>
				<Flex justify="between">
					<Dialog.Title>{title}</Dialog.Title>
					<Dialog.Close>
						<Icon.X onClick={handleClose} style={{ cursor: "pointer" }} />
					</Dialog.Close>
				</Flex>

				<Flex direction="column" gap="3">
					<Text size="2" mb="1">
						{text}
					</Text>
				</Flex>

				<Flex gap="3" mt="4" justify="end">
					<Dialog.Close>
						{/* @ts-ignore */}
						<Button
							variant="soft"
							color="gray"
							style={{ cursor: "pointer" }}
							onClick={() => {
								handleSecondaryCtaClick();
								handleClose();
							}}
						>
							{secondaryCtaText}
						</Button>
					</Dialog.Close>
					<Dialog.Close>
						{/* @ts-ignore */}
						<Button
							onClick={() => {
								handleCtaClick();
								handleClose();
							}}
							style={{
								cursor: "pointer",
								backgroundColor: "#DC3D43",
							}}
						>
							{icon}
							{ctaText}
						</Button>
					</Dialog.Close>
				</Flex>
			</Dialog.Content>
		</ConfirmationDialogRoot>
	);
}

export default ConfirmationDialog;
