import React, { ReactNode, useState, Fragment } from "react";
import { UUID } from "crypto";
import {
	SidebarContainer,
	SidebarHeaderContainer,
	SidebarContentContainer,
	SidebarToggleButton,
	MobileSidebarBar,
	MobileSidebarContent,
	SidebarItemsContainer,
	SidebarItem,
	SidebarItemText,
	SidebarDayHeader,
	LoadMoreButton,
	MobileChatsBar,
	MobileChatsContent,
	MobileChatHistoryItem,
} from "./styled";
import {
	Flex,
	Text,
	Icon,
	useMediaQuery,
	breakpoints,
	Skeleton,
	Button,
} from "@mightybot/web-ui";

type SidebarItem = {
	id: UUID;
	text: string | React.ReactNode;
	date: Date;
};

type SidebarProps = {
	title: string;
	toggleSidebar: () => void;
	isSidebarOpen: boolean;
	items: Array<SidebarItem>;
	activeItemId: UUID;
	onItemClick: (id: UUID, text: string) => void;
	icon?: ReactNode;
	itemIcon?: ReactNode;
	containerStyle?: React.CSSProperties;
	isLoading?: boolean;
	isMobileHistoryOpen?: boolean;
};

type GroupedItems = {
	[key: string]: Array<SidebarItem>;
};

const groupItemsByDay = (items: Array<SidebarItem>): GroupedItems => {
	const grouped: GroupedItems = {
		Today: [],
		Yesterday: [],
		Earlier: [],
	};

	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	items.forEach((item) => {
		const itemDate = new Date(item.date);
		itemDate.setHours(0, 0, 0, 0);

		if (itemDate.getTime() >= today.getTime()) {
			grouped.Today.push(item);
		} else if (itemDate.getTime() === yesterday.getTime()) {
			grouped.Yesterday.push(item);
		} else {
			grouped.Earlier.push(item);
		}
	});
	return grouped;
};

const Sidebar = ({
	title,
	toggleSidebar,
	isSidebarOpen,
	items,
	activeItemId,
	onItemClick,
	icon = <Icon.ClockCounterClockwise weight="thin" size={20} />,
	itemIcon = <Icon.MagnifyingGlass weight="thin" size={18} />,
	containerStyle,
	isLoading,
	isMobileHistoryOpen,
}: SidebarProps) => {
	const [visibleItems, setVisibleItems] = useState(20);

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const groupedItems = groupItemsByDay(
		(items as Array<SidebarItem>).slice(0, visibleItems),
	);
	const renderSidebarItems = (groupedItems: GroupedItems) => {
		if (isLoading) {
			const skeletonItems = Array(5)
				.fill(null)
				.map((_, index) => ({
					id: `skeleton-${index}` as UUID,
					text: <Skeleton width="80%" height="20px" />,
					date: new Date(),
				}));

			return (
				<SidebarItemsContainer
					direction="column"
					gap="2px"
					style={{ padding: "0px 10px", overflow: "scroll" }}
				>
					<SidebarDayHeader>
						<Skeleton width="60px" height="16px" />
					</SidebarDayHeader>
					{skeletonItems.map((item, index) => (
						<SidebarItem gap="4px" key={`skeleton-${index}`} selected={false}>
							{itemIcon}
							{item.text}
						</SidebarItem>
					))}
				</SidebarItemsContainer>
			);
		}

		return (
			<SidebarItemsContainer
				direction="column"
				gap="2px"
				style={{ padding: "0px 10px", overflow: "scroll" }}
			>
				{Object.entries(groupedItems).map(
					([day, dayItems]) =>
						dayItems.length > 0 && (
							<Fragment>
								<SidebarDayHeader>{day}</SidebarDayHeader>
								{dayItems.map((item, index) => (
									<SidebarItem
										gap="4px"
										selected={activeItemId === item.id}
										key={`${item.id}-${index}`}
										onClick={() => onItemClick(item.id, item.text as string)}
									>
										{itemIcon}
										<SidebarItemText size="2">{item.text}</SidebarItemText>
									</SidebarItem>
								))}
							</Fragment>
						),
				)}
				{visibleItems < items.length && (
					<LoadMoreButton onClick={() => setVisibleItems((prev) => prev + 10)}>
						Load More...
					</LoadMoreButton>
				)}
			</SidebarItemsContainer>
		);
	};

	if (isMobile) {
		return (
			<>
				<MobileChatsBar onClick={toggleSidebar}>
					<Flex align="center" gap="2">
						{/* <Button
							onClick={(e) => {
								e.stopPropagation();
								handleNewChat();
							}}
							style={{
								cursor: "pointer",
								height: "38px",
								borderRadius: "var(--radius-pill)",
								padding: "0 12px",
							}}
						>
							<Icon.Plus />
							New Chat
						</Button> */}
						<Text size="2" weight="medium">
							{title}
						</Text>
					</Flex>
					<Icon.CaretDown
						style={{
							transform: isMobileHistoryOpen
								? "rotate(180deg)"
								: "rotate(0deg)",
							transition: "transform 0.3s ease",
							margin: "0px 4px",
							flexShrink: 0,
						}}
					/>
				</MobileChatsBar>
				<MobileChatsContent isOpen={isMobileHistoryOpen || false}>
					{Object.entries(groupedItems).map(
						([day, dayItems]) =>
							dayItems.length > 0 && (
								<Fragment key={day}>
									<SidebarDayHeader>{day}</SidebarDayHeader>
									{dayItems.map((item) => (
										<MobileChatHistoryItem
											key={item.id}
											selected={(activeItemId === item.id).toString()}
											onClick={() => onItemClick(item.id, item.text as string)}
										>
											<Icon.ChatTeardropText size={16} />
											<Text size="2" style={{ flex: 1 }}>
												{item.text}
											</Text>
										</MobileChatHistoryItem>
									))}
								</Fragment>
							),
					)}
				</MobileChatsContent>
			</>
		);
	}

	return (
		<SidebarContainer
			align={isSidebarOpen ? "start" : "center"}
			style={{ maxWidth: isSidebarOpen ? "270px" : "80px", ...containerStyle }}
		>
			{isSidebarOpen ? (
				<Flex
					direction="column"
					gap="10px"
					style={{ width: "100%", overflow: "hidden" }}
				>
					<SidebarHeaderContainer align="center" justify="between">
						<Text size="3" weight="medium" style={{ flex: 1 }}>
							{title}
						</Text>
						<Icon.ArrowLineLeft
							style={{ cursor: "pointer" }}
							onClick={toggleSidebar}
						/>
					</SidebarHeaderContainer>
					<SidebarContentContainer direction="column">
						{renderSidebarItems(groupedItems)}
					</SidebarContentContainer>
				</Flex>
			) : (
				<SidebarToggleButton onClick={toggleSidebar} align="center">
					{React.cloneElement(icon as React.ReactElement, {
						size: 20,
						weight: "thin",
					})}
				</SidebarToggleButton>
			)}
		</SidebarContainer>
	);
};

export default Sidebar;
