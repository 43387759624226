import { Dialog } from "@mightybot/web-ui";
import styled from "styled-components";

export const ConfirmationDialogRoot = styled(Dialog.Root)`
	padding: 0px;
	&.rt-BaseDialogScrollPadding {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
