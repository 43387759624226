import React, { useState } from "react";
import { Flex, Text } from "@mightybot/web-ui";
import { FilterOption } from "../styled";
import { subDays, subMonths } from "date-fns";
import DateRangePicker from "../../DateRangePicker";
import { TimestampFilterValue } from "@mightybot/core";

interface TimestampFilterContentProps {
	value: TimestampFilterValue;
	onChange: (value: TimestampFilterValue) => void;
}

const FILTER_OPTIONS = [
	{ label: "Any time", value: "any_time" },
	{ label: "Last 24 hours", value: "last_24_hours" },
	{ label: "Last 7 days", value: "last_7_days" },
	{ label: "Last 30 days", value: "last_30_days" },
	{ label: "Last 3 months", value: "last_3_months" },
	{ label: "From custom date until now", value: "custom_until_now" },
	{ label: "Custom date range", value: "custom_range" },
];

const TimestampFilterContent: React.FC<TimestampFilterContentProps> = ({
	value: timestampFilter,
	onChange: setTimestampFilter,
}) => {
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [tempStartDate, setTempStartDate] = useState<Date | null>(null);
	const [tempEndDate, setTempEndDate] = useState<Date | null>(null);

	const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
		const [start, end] = dates;
		setTempStartDate(start);
		setTempEndDate(end);
		const newFilter: TimestampFilterValue = {
			type: timestampFilter.type,
			startDate: start || undefined,
			endDate:
				timestampFilter.type === "custom_until_now"
					? new Date()
					: end || undefined,
		};
		setTimestampFilter(newFilter);
		setIsDatePickerOpen(false);
	};

	const handleOptionClick = (optionValue: string) => {
		const now = new Date();
		let newValue: TimestampFilterValue = { type: "any_time" };

		switch (optionValue) {
			case "last_24_hours":
				newValue = {
					type: "preset",
					startDate: subDays(now, 1),
					endDate: now,
				};
				break;
			case "last_7_days":
				newValue = {
					type: "preset",
					startDate: subDays(now, 7),
					endDate: now,
				};
				break;
			case "last_30_days":
				newValue = {
					type: "preset",
					startDate: subDays(now, 30),
					endDate: now,
				};
				break;
			case "last_3_months":
				newValue = {
					type: "preset",
					startDate: subMonths(now, 3),
					endDate: now,
				};
				break;
			case "custom_until_now":
			case "custom_range":
				setIsDatePickerOpen(true);
				newValue = {
					type: optionValue,
				};
				break;
			default:
				newValue = { type: "any_time" };
		}
		setTimestampFilter(newValue);
	};

	const handleDatePickerApply = () => {};

	if (isDatePickerOpen) {
		return (
			<DateRangePicker
				startDate={tempStartDate}
				endDate={tempEndDate}
				onChange={handleDateRangeChange}
				singleDate={timestampFilter.type === "custom_until_now"}
				onClose={() => setIsDatePickerOpen(false)}
				maxDate={new Date()}
				placeholderText={
					timestampFilter.type === "custom_until_now"
						? "Select start date"
						: "Select date range"
				}
			/>
		);
	}

	return (
		<Flex
			direction="column"
			gap="10px"
			style={{ width: "100%", padding: 10, cursor: "pointer" }}
		>
			{FILTER_OPTIONS.map((option) => (
				<FilterOption
					key={option.value}
					selected={(timestampFilter.type === option.value).toString()}
					onClick={() => handleOptionClick(option.value)}
				>
					<Text style={{ fontSize: 13 }}>{option.label}</Text>
				</FilterOption>
			))}
		</Flex>
	);
};

export default TimestampFilterContent;
