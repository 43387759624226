import React, { useState } from "react";
import { Text, Flex, Skeleton } from "@mightybot/web-ui";
import { useBilling } from "@mightybot/core";
import { BillingContent, TileGridLayout, TileGridCard } from "./BillingContent";
import { TextSection, TileSection } from "./styled";
import {
	PricingToggle,
	PricingToggleButton,
	PurchasePlanButton,
	StatusBadge,
	PlanPrice,
	TrialBadge,
	PlanFeatureList,
	ContactSalesButton,
} from "./styled";

const professionalFeatures = [
	"Integrations with Slack, Hubspot, Salesforce, Google Workspace (Gmail, Calendar, Docs, Sheets, Others)",
	"MightyBot Meeting Presence records, transcribes, and answers any questions about your Meetings",
	"Individual Autopilot workflow automations",
	"Advanced internal knowledge retrievals",
	"Precise human-like research tool (Web/LinkedIn)",
	"Comprehensive writer tool crafts 20+ page docs",
];

const enterpriseFeatures = [
	"Advanced security options",
	"Dedicated account support",
	"Team Autopilot workflow automations",
	"Forecasting & personalized coaching reports",
	"Win/Loss analysis reports",
	"Generates research reports & PRDs",
	"Builds detailed client reports (Consulting, Project, Marketing, Business Performance, others)",
];

const LoadingCard = () => (
	<TileGridCard>
		<Flex direction="column" gap="16px">
			<Skeleton width="160px" height="18px" />
			<Flex direction="column" gap="12px" mt="5px">
				<Skeleton height="16px" width="100%" />
				<Skeleton height="16px" width="100%" />
				<Skeleton height="16px" width="100%" />
				<Skeleton height="16px" width="100%" />
				<Skeleton height="16px" width="100%" />
			</Flex>
		</Flex>
	</TileGridCard>
);

const Billing: React.FC = () => {
	const [billingCycle, setBillingCycle] = useState<"monthly" | "yearly">(
		"monthly",
	);
	const { plan, subscription, isLoading, isOnTrial, trialDaysRemaining } =
		useBilling();

	const price =
		billingCycle === "yearly" ? plan?.prices.yearly : plan?.prices.monthly;
	const currency = plan?.prices.currency || "USD";

	if (isLoading) {
		return (
			<BillingContent
				title="Billing"
				subHeader={
					<Flex justify="between" align="center" style={{ width: "100%" }}>
						<Flex style={{ flexDirection: "column", gap: "8px" }}>
							<Skeleton height="24px" width="200px" />
							<Skeleton height="18px" width="300px" />
						</Flex>
					</Flex>
				}
			>
				<TileSection padding="20px 0" align="center" />
				<TileGridLayout>
					<LoadingCard />
					<LoadingCard />
				</TileGridLayout>
			</BillingContent>
		);
	}

	return (
		<BillingContent
			title="Billing"
			subHeader={
				<Flex justify="between" align="center" style={{ width: "100%" }}>
					<div>
						<Flex direction="column" gap="2">
							<Text size="3" weight="medium">
								Subscription Details
							</Text>
							{!subscription ? (
								<Text size="2" color="gray">
									Subscribe here to get started
								</Text>
							) : isOnTrial ? (
								<Text size="2" color="gray">
									You're on a 14-day free trial with {trialDaysRemaining} days
									remaining.
								</Text>
							) : (
								<Text size="2" color="gray">
									Your {billingCycle} subscription is active
								</Text>
							)}
							{/* <Text size="1" color="gray">
									Seats in use: {subscription?.current_seats}
								</Text> */}
						</Flex>
					</div>
					<PurchasePlanButton
						hidden={subscription?.status === "ACTIVE" && !isOnTrial}
					>
						Purchase Plan
					</PurchasePlanButton>
				</Flex>
			}
		>
			<TileSection padding="20px 0" align="center">
				{/* <PricingToggle>
						<PricingToggleButton
							active={billingCycle === 'yearly'}
							onClick={() => setBillingCycle( 'yearly' )}
							disabled={true}
						>
							Yearly (10% discount)
						</PricingToggleButton>
						<PricingToggleButton
							active={billingCycle === 'monthly'}
							onClick={() => setBillingCycle( 'monthly' )}
						>
							Monthly
						</PricingToggleButton>
					</PricingToggle> */}
			</TileSection>

			<TileGridLayout>
				<TileGridCard>
					<Text
						size="2"
						style={{
							marginBottom: "10px",
							color: "#333333",
							fontWeight: "400",
							textTransform: "uppercase",
							lineHeight: "18px",
							letterSpacing: "1.40px",
						}}
					>
						{plan?.name}
					</Text>
					<PlanPrice>
						<Text size="8" weight="medium">
							${price}
						</Text>
						<Text size="2" color="gray">
							/{billingCycle === "yearly" ? "Year" : "Month"}/User
						</Text>
					</PlanPrice>
					{!subscription ? (
						<ContactSalesButton>Subscribe</ContactSalesButton>
					) : isOnTrial ? (
						<TrialBadge>
							<Text size="3" weight="medium">
								On Trial
							</Text>
						</TrialBadge>
					) : (
						subscription?.status === "ACTIVE" && (
							<TrialBadge>
								<Text size="3" weight="medium">
									Active
								</Text>
							</TrialBadge>
						)
					)}
					<Text style={{ marginBottom: "10px" }} size="2" color="gray">
						Maximum {plan?.max_seats ? plan?.max_seats : "10"} seats
					</Text>
					<TextSection>
						<Text style={{ textAlign: "center" }} weight="medium">
							Plans feature
						</Text>
						<PlanFeatureList>
							{professionalFeatures.map((feature, index) => (
								<li
									style={{
										listStyleType: "none",
										display: "flex",
										alignItems: "baseline",
									}}
									key={index}
								>
									<Text size="2" weight="medium" style={{ marginRight: "8px" }}>
										•
									</Text>
									<span>{feature}</span>
								</li>
							))}
						</PlanFeatureList>
					</TextSection>
				</TileGridCard>
				<TileGridCard>
					<Text
						size="2"
						style={{
							marginBottom: "10px",
							color: "#333333",
							fontWeight: "400",
							textTransform: "uppercase",
							lineHeight: "18px",
							letterSpacing: "1.40px",
						}}
					>
						Enterprise
					</Text>
					<PlanPrice>
						<Text size="8" weight="medium">
							Custom
						</Text>
					</PlanPrice>
					<ContactSalesButton
						onClick={() =>
							window.open(
								"https://www.mightybot.ai/get-a-demo",
								"_blank",
								"noopener,noreferrer",
							)
						}
					>
						Contact Sales
					</ContactSalesButton>
					<Text style={{ marginBottom: "10px" }} size="2" color="gray">
						Minimum 10 seats
					</Text>
					<TextSection>
						<Text style={{ textAlign: "center" }} weight="medium">
							Everything in Professional, plus:
						</Text>
						<PlanFeatureList>
							{enterpriseFeatures.map((feature, index) => (
								<li
									style={{
										listStyleType: "none",
										display: "flex",
										alignItems: "baseline",
									}}
									key={index}
								>
									<Text size="2" weight="medium" style={{ marginRight: "8px" }}>
										•
									</Text>
									<span>{feature}</span>
								</li>
							))}
						</PlanFeatureList>
					</TextSection>
				</TileGridCard>
			</TileGridLayout>

			{/* <TileSection background="var(--mb-color-light-gray-2)">
					<Text weight="medium">Invoice history</Text>
					<TileTableLayout headers={[ 'Date', 'Amount', 'Plan', 'Status' ]}>
						<TileTableRow>
							<div style={{ color: 'var(--mb-color-blue-9)' }}>Jan 01, 2024</div>
							<div>$0.00</div>
							<div>14-day free trial</div>
							<div>
								<StatusBadge>Paid</StatusBadge>
							</div>
						</TileTableRow>
					</TileTableLayout>
				</TileSection> */}
		</BillingContent>
	);
};

export default Billing;
