import React, { useState, useEffect } from "react";
import { Flex, Text, Icon, Select, Button } from "@mightybot/web-ui";
import {
	EnterpriseAutopilots,
	Log,
	useAutopilotLogs,
	useEnterpriseAutopilots,
} from "@mightybot/core";
import LogsOutputDialog from "../AutopilotTestRun/LogsOutputDialog";
import Pagination from "../Pagination";
import TraceToggle from "./TraceToggle";

export const TracesList = () => {
	const [selectedEnterprise, setSelectedEnterprise] = useState<string>("all");
	const [page, setPage] = useState(1);
	const [perPage] = useState(100);
	const [open, setOpen] = useState(false);
	const [selectedLog, setSelectedLog] = useState<Log | null>(null);
	const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
	const [showAllTraces, setShowAllTraces] = useState(true);

	const { data: enterpriseList } = useEnterpriseAutopilots();

	const {
		logs,
		total,
		refetch: refetchLogs,
	} = useAutopilotLogs({
		enterpriseId: selectedEnterprise === "all" ? undefined : selectedEnterprise,
		isTest: false,
		page,
		perPage,
		showIndexLogs: !showAllTraces,
	});

	useEffect(() => {
		refetchLogs({
			page,
			perPage,
		});
	}, [selectedEnterprise, page]);

	const toggleLogDetails = (log: Log) => {
		setSelectedLog(log);
		setOpen(true);
	};

	const handleSort = () => {
		setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
	};

	const sortedLogs = [...(logs || [])].sort((a, b) => {
		const dateA = new Date(a.execution_time ?? 0).getTime();
		const dateB = new Date(b.execution_time ?? 0).getTime();
		return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
	});

	const onPageChange = (newPage: number) => {
		setPage(newPage);
	};
	return (
		<Flex
			direction="column"
			style={{
				borderRadius: "12px",
				border: "1px solid #E0E0E0",
				overflow: "scroll",
				width: "1200px",
			}}
		>
			<Flex direction="column">
				<Flex justify="between" align="center" style={{ padding: "20px" }}>
					<Text weight="medium" style={{ fontSize: "14px" }}>
						Autopilot Execution Traces
					</Text>
					<Flex align="center" style={{ gap: "10px" }}>
						<TraceToggle
							showAllTraces={showAllTraces}
							onToggle={setShowAllTraces}
						/>
						<Select.Root
							value={selectedEnterprise}
							onValueChange={(value) => setSelectedEnterprise(value)}
						>
							<Select.Trigger placeholder="Select an enterprise" />
							<Select.Content>
								<Select.Item value="all">All Enterprises</Select.Item>
								{enterpriseList?.map((enterprise: EnterpriseAutopilots) => (
									<Select.Item key={enterprise.id} value={enterprise.id}>
										{enterprise.name}
									</Select.Item>
								))}
							</Select.Content>
						</Select.Root>
						{/* @ts-ignore */}
						<Button
							variant="soft"
							onClick={() =>
								refetchLogs({
									page,
									perPage,
								})
							}
						>
							Refresh
						</Button>
					</Flex>
				</Flex>
				<div
					style={{
						width: "100%",
						borderColor: "#E0E0E0",
						height: "1px",
						backgroundColor: "#E0E0E0",
					}}
				/>

				<Flex
					align="center"
					style={{
						background: "var(--gray-3)",
						padding: "12px 20px",
						borderBottom: "1px solid #E0E0E0",
					}}
				>
					<Flex
						style={{ width: "180px", gap: "6px", cursor: "pointer" }}
						align="center"
						onClick={handleSort}
					>
						<Text weight="bold">Time Stamp</Text>
						<Icon.CaretDown
							size={16}
							style={{
								transform: sortOrder === "asc" ? "rotate(180deg)" : "none",
							}}
						/>
					</Flex>
					<Text weight="medium" style={{ width: "200px" }}>
						User Email
					</Text>
					<Text weight="medium" style={{ width: "200px" }}>
						Autopilot Name
					</Text>
					<Text weight="medium" style={{ width: "150px" }}>
						Enterprise
					</Text>
					<Text weight="medium" style={{ width: "150px" }}>
						Status
					</Text>
					<Text weight="medium" style={{ width: "180px" }}>
						Apps Used
					</Text>
					<Text weight="medium" style={{ width: "200px" }}>
						Trace URLs
					</Text>
					<Text weight="medium" style={{ width: "80px", textAlign: "right" }}>
						Details
					</Text>
				</Flex>

				<Flex direction="column" style={{ overflow: "scroll", width: "100%" }}>
					{sortedLogs.length > 0 ? (
						sortedLogs.map((log, index) => (
							<Flex key={log.id} direction="column">
								<Flex
									align="center"
									style={{
										padding: "12px 20px",
										borderBottom:
											index !== sortedLogs.length - 1
												? "1px solid #E0E0E0"
												: "none",
									}}
								>
									<Text
										style={{
											width: "180px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
										}}
									>
										{new Date(log.execution_time ?? 0).toLocaleString()}
									</Text>
									<Text
										style={{
											width: "200px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
										}}
									>
										{log.user_email}
									</Text>
									<Text
										style={{
											width: "200px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
										}}
									>
										{log.autopilot_name}
									</Text>
									<Text
										style={{
											width: "150px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
										}}
									>
										{log.enterprise_name}
									</Text>

									<Text
										style={{
											width: "150px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
										}}
									>
										{log.status}
									</Text>
									<Text
										style={{
											width: "180px",
											overflow: "hidden",
											whiteSpace: "normal",
											wordBreak: "break-word",
										}}
									>
										{log?.data?.apps?.join(", ") ||
											(log?.data?.apps && log.data.apps.length > 0
												? log.data.apps.join(", ")
												: "")}
									</Text>
									<Flex style={{ width: "200px", flexDirection: "row" }}>
										{log.trace_url && Array.isArray(log.trace_url) ? (
											log.trace_url.map((url, urlIndex) => (
												<a
													key={urlIndex}
													href={url}
													target="_blank"
													rel="noopener noreferrer"
													style={{
														color: "var(--mb-blue-8)",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
														maxWidth: "100%",
														display: "block",
														padding: "0 5px",
													}}
													title={url}
												>
													{`Link ${urlIndex + 1}`}
												</a>
											))
										) : (
											<Text>No trace URL available</Text>
										)}
									</Flex>
									<Flex style={{ width: "80px", justifyContent: "flex-end" }}>
										<Text
											onClick={() => toggleLogDetails(log)}
											style={{
												color: "var(--mb-blue-8)",
												textDecoration: "underline",
												cursor: "pointer",
											}}
										>
											View
										</Text>
									</Flex>
								</Flex>
							</Flex>
						))
					) : (
						<Text style={{ textAlign: "center", padding: "20px" }}>
							No traces available
						</Text>
					)}
				</Flex>
			</Flex>
			<Pagination
				total={total}
				page={page}
				perPage={perPage}
				onPageChange={onPageChange}
			/>
			<LogsOutputDialog
				open={open}
				onClose={() => setOpen(false)}
				log={selectedLog}
			/>
		</Flex>
	);
};

export default TracesList;
