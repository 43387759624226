import styled from "styled-components";
import { Button, Flex, Input } from "@mightybot/web-ui";

export const ConnectContainer = styled(Flex)`
	border: 1px solid #e0e0e0;
	border-radius: 12px;
	margin-top: 20px;
	width: 100%;
`;

export const AppCardContainer = styled(Flex)`
	height: 56px;
	margin-top: 15px;
	padding-bottom: 16px;
	column-gap: 1.75rem;
`;

export const ConnectButton = styled(Button)`
	align-items: center;
	gap: 10px;
	cursor: pointer;
	padding: 6px 12px;
	font-weight: 500;
	border-radius: 4px;
`;

export const ImageContainer = styled.div`
	width: 32px;
	height: 32px;
	flex-shrink: 0;
`;

export const AppHeader = styled(Flex)`
	padding: 0px 20px;
	border-bottom: 1px solid #e0e0e0;
	height: 72px;
	> * {
		flex-grow: 1;
	}
`;
export const StyledInput = styled(Input)`
	flex-basis: 0%;
	padding: 8px;
	border-radius: 4px;
	height: 32px;
`;

export const AppContainer = styled(Flex)`
	border-color: rgb(243 244 246);
	padding: 10px 16px 20px 20px;
	margin: 0;
`;
