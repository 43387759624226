import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import MeetingPreview from "../components/MeetingPreview";
import MeetingDetails from "../components/MeetingDetails";
import {
	Meeting,
	MeetingContentItem,
	EXAMPLE_MEETINGS_KEY,
	ExampleMeetingData,
} from "@mightybot/core";
import { useContentful } from "react-contentful";
import localforage from "localforage";

export default function MeetingsDetailRoute() {
	let { view, meetingId } = useParams();
	const navigate = useNavigate();
	let {
		data: exampleMeetingData,
		error,
		fetched,
		loading,
	} = useContentful({
		contentType: "exampleMeetingData",
		id: "1HgyfwQm1jFZ7Lcb0nK727",
	});

	useEffect(() => {
		if (exampleMeetingData) {
			localforage.setItem(EXAMPLE_MEETINGS_KEY, {
				meetings:
					(exampleMeetingData as ExampleMeetingData)?.fields?.meetings ?? [],
				meetingContent:
					(exampleMeetingData as ExampleMeetingData)?.fields?.meetingContent ??
					[],
			});
		}
	}, [exampleMeetingData]);

	if (!view && meetingId) {
		navigate(`/meetings/${meetingId}/preview`);
	}

	return (
		<Layout>
			{view === "details" ? <MeetingDetails /> : <MeetingPreview />}
		</Layout>
	);
}
