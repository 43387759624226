import React from "react";
import Companies from "../components/Companies";
import Layout from "../components/Layout";

const CompaniesRoute: React.FC = () => {
	return (
		<Layout>
			<Companies />
		</Layout>
	);
};

export default CompaniesRoute;
