import styled from "styled-components";
import { Flex } from "@mightybot/web-ui";

export const MeetingPreviewContainer = styled(Flex)`
	height: 100%;
	padding-left: 10px;
	width: 100%;
`;

export const MeetingItem = styled.div`
	display: flex;
	align-items: center;
	padding: 12px;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.2s ease;

	&:hover {
		background-color: var(--mb-color-light-blue-1);
	}
`;

export const MeetingItemContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const DateSeparator = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: var(--mb-color-light-gray-11);
	margin: 16px 0 8px;
`;

export const MeetingPreviewContentContainer = styled(Flex)`
	flex-direction: column;
	height: 100%;
	width: 100%;
	max-width: 95%;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;
