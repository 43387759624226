import React from "react";
import { Flex, Icon, Text } from "@mightybot/web-ui";
import { capitalize } from "lodash";
import { AppFilterPill } from "../styled";
import { useIntegrationLogos } from "@mightybot/core-ui";

interface AppFilterContentProps {
	appResultCounts: { app: string; count: number }[];
	selectedApps: string[];
	handleAppSelect: (app: string) => void;
	results: any[];
}
const AppFilterContent = ({
	appResultCounts,
	selectedApps,
	handleAppSelect,
	results,
}: AppFilterContentProps) => {
	const { getIntegrationLogoRegex } = useIntegrationLogos();
	return (
		<Flex direction="column" gap="8px" p="16px">
			<Flex gap="6px" style={{ maxHeight: 530, flexWrap: "wrap" }}>
				<AppFilterPill
					key="all"
					onClick={() => handleAppSelect("all")}
					selected={(selectedApps.length === appResultCounts.length).toString()}
				>
					<Icon.Check size={16} />
					<Text style={{ fontSize: 13 }}>All Apps</Text>
					<Text style={{ color: "#889096", fontSize: 13 }}>
						{results.length}
					</Text>
				</AppFilterPill>
				{appResultCounts.map(({ app, count }) => (
					<AppFilterPill
						key={app}
						onClick={() => handleAppSelect(app)}
						selected={selectedApps.includes(app).toString()}
					>
						<img
							src={getIntegrationLogoRegex(app.toLowerCase())}
							alt={app}
							width={16}
							height={16}
						/>
						<Text style={{ fontSize: 13 }}>{capitalize(app)}</Text>
						<Text style={{ color: "#889096", fontSize: 13 }}>{count}</Text>
					</AppFilterPill>
				))}
			</Flex>
		</Flex>
	);
};

export default AppFilterContent;
