import styled from "styled-components";
import { Flex } from "@mightybot/web-ui";
import * as NavigationMenuRadix from "@radix-ui/react-navigation-menu";
import { NavLink } from "react-router-dom";

export const AdminContainer = styled(Flex)`
	flex-direction: column;
	width: 100%;
	padding: 0px 20px;
	height: 100%;
`;

export const AdminContentContainer = styled(Flex)`
	gap: 16px;
	overflow: scroll;
`;

export const AdminContent = styled(Flex)`
	flex-direction: column;
	width: 100%;
	max-width: 1200px;
	overflow: hidden;
`;

export const SidebarContainer = styled(Flex)`
	width: 200px;
	padding: 20px 0px;
	flex-direction: column;
	flex-shrink: 0;
`;

export const NavigationMenu = styled(NavigationMenuRadix.Root)`
	width: 100%;
`;

export const NavigationMenuList = styled(NavigationMenuRadix.List)`
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
`;

export const NavigationMenuItem = styled(NavigationMenuRadix.Item)`
	margin-bottom: 5px;
`;

export const NavigationMenuLink = styled(NavLink)<{
	active?: string;
}>`
	display: flex;
	align-items: center;
	padding: 10px 15px;
	color: ${(props) =>
		props.active === "true"
			? "var(--mb-color-light-gray-1)"
			: "var(--mb-color-light-gray-11)"};
	font-size: 14px;
	cursor: pointer;
	border-radius: 4px;
	text-decoration: none;
	background-color: ${(props) =>
		props.active === "true" ? "var(--mb-color-light-blue-8)" : "transparent"};

	transition: background-color 0.3s ease;

	&:hover {
		background-color: ${(props) =>
			props.active === "true"
				? "var(--mb-color-light-blue-8)"
				: "var(--mb-color-dark-blue-12)"};
		color: ${(props) =>
			props.active === "true"
				? "var(--mb-color-light-gray-1)"
				: "var(--mb-color-dark-blue-8)"};
	}

	svg {
		margin-right: 10px;
	}
`;
