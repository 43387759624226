import React from "react";
import {
	GenerateDocRoot,
	GenerateDocTrigger,
	GenerateDocButton,
	GenerateDocContainer,
} from "./styled";
import { useMediaQuery, Icon, breakpoints } from "@mightybot/web-ui";
import Placeholder from "../../utils/Placeholder";
import { PlaceholderGenerateDoc } from "@mightybot/core-ui";

const GenerateDoc = () => {
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	return (
		<GenerateDocRoot>
			<GenerateDocTrigger>
				<GenerateDocButton>
					<Icon.Lightning />
					{isMobile ? null : "Generate Doc"}
				</GenerateDocButton>
			</GenerateDocTrigger>
			<GenerateDocContainer>
				<Placeholder
					placeholder={PlaceholderGenerateDoc}
					imageStyle={{ width: "100%", objectFit: "contain" }}
				/>
			</GenerateDocContainer>
		</GenerateDocRoot>
	);
};
export default GenerateDoc;
