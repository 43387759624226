import React, { useEffect, useState } from "react";
import { Button, Flex, Box, Icon } from "@mightybot/web-ui";
import { useUnsubscribeUser } from "@mightybot/core";
import { UnsubscribeContainer } from "./styled";

const Unsubscribe: React.FC = () => {
	const [unsubscribeUser, { isSuccess }] = useUnsubscribeUser();

	const [status, setStatus] = useState<"success" | "error" | "loading">(
		"loading",
	);

	useEffect(() => {
		async function unsubscribe() {
			await unsubscribeUser().unwrap();
			if (isSuccess) {
				setStatus("success");
			} else {
				setStatus("error");
			}
		}
		unsubscribe();
	}, []);

	const handleButtonClick = () => {
		// TODO: Open the MightyBot extension
	};
	if (status === "loading") {
		return <div>Loading...</div>;
	}

	return (
		<UnsubscribeContainer>
			<Box
				style={{
					height: "400px",
					border: "1px solid #e0e0e0",
					borderRadius: "16px",
					marginTop: "20%",
				}}
			>
				<Flex align="center" direction="column" style={{ height: "100%" }}>
					<Flex
						style={{ padding: "40px", gap: "20px", height: "85%" }}
						direction="column"
						align="center"
						justify="center"
					>
						{status === "success" ? (
							<Icon.CheckCircle size={50} fill="#7DAB20" />
						) : (
							<Icon.XCircle size={50} color="#DC3D43" />
						)}
						<p style={{ fontSize: "18px", fontWeight: "500" }}>
							{status === "success"
								? "You have been unsubscribed from these notifications."
								: "We couldn't unsubscribe you from these notifications."}
						</p>
						{/* @ts-ignore */}
						<Button
							onClick={handleButtonClick}
							style={{
								borderRadius: "60px",
								width: "300px",
								height: "44px",
								cursor: "pointer",
							}}
						>
							Open MightyBot Extension
						</Button>
					</Flex>
					<Flex
						align="center"
						justify="center"
						style={{
							backgroundColor: "var(--gray-2)",
							width: "100%",
							borderRadius: "0px 0px 16px 16px",
							color: "var(--gray-9)",
							padding: "20px",
							height: "15%",
						}}
					>
						<p>© MightyBot. All rights reserved.</p>
					</Flex>
				</Flex>
			</Box>
		</UnsubscribeContainer>
	);
};

export default Unsubscribe;
