import React, { useState } from "react";
import { MeetingDetailsContainer } from "./styled";
import MeetingContent from "../MeetingContent";
import MeetingDetailsSidebar from "../MeetingDetailsSidebar";
import { MeetingContentNavigationProvider } from "../../contexts/MeetingContentNavigationContext";
import { isMobile } from "../../utils/helpers";

const MeetingDetails: React.FC = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile());

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<MeetingContentNavigationProvider>
			<MeetingDetailsContainer>
				<MeetingDetailsSidebar
					isSidebarOpen={isSidebarOpen}
					toggleSidebar={toggleSidebar}
				/>
				<MeetingContent />
			</MeetingDetailsContainer>
		</MeetingContentNavigationProvider>
	);
};

export default MeetingDetails;
