import { Flex } from "@mightybot/web-ui";
import styled from "styled-components";

export const ChatBoxOuterContainer = styled(Flex)`
	width: 100%;
	justify-content: center;
	align-items: center;
	background-color: white;
	z-index: 10;
`;

export const ChatBoxInnerContainer = styled(Flex)`
	border-radius: 12px;
	border: 2px solid #ccc;
	padding: 8px;
	border: 1px solid #ccc;
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
	max-width: 870px;
	width: 100%;
	flex-direction: column;
`;

export const SelectedFilesPill = styled(Flex)<{ showborder?: string }>`
	justify-content: center;
	align-items: center;
	background: var(--mb-blue-1);
	border-radius: 12px;
	height: 34px;
	gap: 6px;
	padding: 6px 16px 6px 10px;
	cursor: pointer;
	border-radius: ${(props) => (props.showborder === "true" ? "12px" : "none")};
	border: ${(props) =>
		props.showborder === "true" ? "2px solid var(--mb-gray-12)" : "none"};
`;
