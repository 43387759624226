import React from "react";
import { Text } from "@mightybot/web-ui";
import {
	TileContainer,
	TileHeader,
	TileSubHeader,
	TileContent,
	TileGrid,
	TileGridItem,
	TileTable,
	TileTableRow,
} from "./styled";

interface BillingContentProps {
	title?: React.ReactNode;
	subHeader?: React.ReactNode;
	children: React.ReactNode;
}

export const BillingContent: React.FC<BillingContentProps> = ({
	title,
	subHeader,
	children,
}) => {
	return (
		<TileContainer>
			{title && (
				<TileHeader>
					<Text size="6" weight="medium">
						{title}
					</Text>
				</TileHeader>
			)}
			{subHeader && <TileSubHeader>{subHeader}</TileSubHeader>}
			<TileContent>{children}</TileContent>
		</TileContainer>
	);
};

export const TileGridLayout: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	return <TileGrid>{children}</TileGrid>;
};

export const TileGridCard: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	return <TileGridItem>{children}</TileGridItem>;
};

export const TileTableLayout: React.FC<{
	headers?: string[];
	children: React.ReactNode;
}> = ({ headers, children }) => {
	return (
		<TileTable>
			{headers && (
				<TileTableRow header>
					{headers.map((header, index) => (
						<div key={index}>
							<Text color="gray" size="2">
								{header}
							</Text>
						</div>
					))}
				</TileTableRow>
			)}
			{children}
		</TileTable>
	);
};

export default BillingContent;
