import React, { useState } from "react";
import { Dialog, Flex, Icon, Text } from "@mightybot/web-ui";
import debounce from "lodash/debounce";

export type SelectionOption = {
	id: string;
	name: string;
};

type SingleSelectionDialogProps<T extends SelectionOption> = {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	handleClose: () => void;
	handleSubmit: (selectedData: T) => void;
	data: T[];
	title?: string;
	noneOption?: T;
};

const SingleSelectionDialog = <T extends SelectionOption>({
	open = false,
	onOpenChange,
	handleClose,
	handleSubmit,
	data = [],
	title = "Select an Option",
	noneOption,
}: SingleSelectionDialogProps<T>) => {
	const updatedData = noneOption ? [noneOption, ...data] : data;
	const [searchTerm, setSearchTerm] = useState("");

	const handleSearch = debounce(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(event.target.value);
		},
		300,
	);

	const filteredData = updatedData?.filter((d) =>
		d.name?.toLowerCase().includes(searchTerm.toLowerCase()),
	);

	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Content style={{ maxWidth: 350, padding: 20 }}>
				<Flex justify="between">
					<Dialog.Title>{title}</Dialog.Title>
					<Icon.X style={{ cursor: "pointer" }} onClick={handleClose} />
				</Flex>
				<Flex direction="column" style={{ gap: "16px" }}>
					<input
						type="text"
						placeholder="Search..."
						value={searchTerm}
						onChange={handleSearch}
						style={{
							height: "32px",
							width: "100%",
							borderRadius: "4px",
							padding: "0px 10px",
							backgroundColor: "var(--gray-3)",
							border: "1px solid var(--gray-5)",
						}}
					/>
					<Flex
						direction="column"
						style={{
							maxHeight: "200px",
							overflowY: "auto",
							paddingRight: "10px",
						}}
					>
						{filteredData?.map((data) => (
							<Flex
								key={data.id}
								style={{
									padding: "8px 4px",
									cursor: "pointer",
									borderRadius: "4px",
								}}
								onMouseEnter={(e) => {
									e.currentTarget.style.backgroundColor = "var(--gray-2)";
								}}
								onMouseLeave={(e) => {
									e.currentTarget.style.backgroundColor = "transparent";
								}}
								onClick={() => handleSubmit(data as T)}
							>
								<Text>{data.name}</Text>
							</Flex>
						))}
					</Flex>
				</Flex>
			</Dialog.Content>
		</Dialog.Root>
	);
};

export default SingleSelectionDialog;
