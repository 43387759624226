import React from "react";
import { Flex, Icon, Text } from "@mightybot/web-ui";

export const MoreDialog = ({ onDelete }) => {
	return (
		<Flex
			direction="column"
			align="start"
			style={{
				gap: "2px",
				border: "1px solid var(--mb-color-light-gray-5)",
				boxShadow:
					"0px 89px 25px 0px rgba(0, 0, 0, 0.00), 0px 57px 23px 0px rgba(0, 0, 0, 0.01), 0px 32px 19px 0px rgba(0, 0, 0, 0.05), 0px 14px 14px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.10)",
				borderRadius: "6px",
				padding: "6px 0px",
				width: "130px",
				backgroundColor: "#FFF",
			}}
		>
			<Flex
				onClick={onDelete}
				style={{ cursor: "pointer", paddingLeft: "10px", gap: "4px" }}
			>
				<Icon.Trash size="17px" weight="bold" />
				<Text>Delete</Text>
			</Flex>
			{/* <Flex>Traces</Flex> */}
		</Flex>
	);
};
