import React from "react";
import { Text } from "@mightybot/web-ui";

export const Disclaimer = () => {
	return (
		<Text
			size="1"
			style={{
				bottom: 0,
				textAlign: "center",
				margin: 20,
				color: "var(--gray-9)",
			}}
		>
			By clicking continue, you agree to the mightybot.ai{" "}
			<a
				href="https://www.mightybot.ai/terms"
				target="_blank"
				rel="noopener noreferrer"
				style={{ color: "#333", cursor: "pointer", textDecoration: "none" }}
			>
				terms & conditions
			</a>{" "}
			and confirm that you have read mightybot.ai{" "}
			<a
				href="https://www.mightybot.ai/privacy"
				target="_blank"
				rel="noopener noreferrer"
				style={{ color: "#333", cursor: "pointer", textDecoration: "none" }}
			>
				privacy policy.
			</a>
		</Text>
	);
};
