import styled from "styled-components";
import { Flex } from "@mightybot/web-ui";

export const ErrorFallbackContainer = styled(Flex)`
	width: 100%;
	direction: column;
	justify-content: center;
	align-items: center;
`;

export const Box = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 800px;
	height: 400px;
	margin: auto;
	text-align: center;
	border: 1px solid #e0e0e0;
	border-radius: 16px;
	background: #fff;
	font-family: "fk_grotesk_neue", sans-serif;
`;

export const Button = styled.button`
	background-color: #0554f2;
	border: none;
	border-radius: 60px;
	width: 300px;
	height: 44px;
	cursor: pointer;
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	padding: 10px 20px;
`;
