import {
	useAutopilot,
	useEnterpriseById,
	User,
	useAutopilots,
	Autopilot,
	useDraftAutopilots,
	useEnterpriseUsers,
	EnterpriseResponse as Enterprise,
	useEnterprises,
	useMe,
} from "@mightybot/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Flex, Button, Icon, Text } from "@mightybot/web-ui";
import MultiSelectionDialog from "../MultiSelectionDialog";
import { FilterDialog } from "./FilterDialog";
import { FilterCard } from "./FilterCard";
import { AutopilotCard } from "./AutopilotCard";
import { UUID } from "crypto";
import Pagination from "../Pagination";
import SingleSelectionDialog from "../SingleSelectionDialog";

type Filter = {
	key: string;
	label: string;
};

type EnterpriseSelectionOption =
	| Enterprise
	| { id: "none"; name: "No Enterprise" };

const EnterpriseSelectionDialog = ({
	open,
	onOpenChange,
	handleClose,
	handleSubmit,
	data,
}: {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	handleClose: () => void;
	handleSubmit: (selectedData: EnterpriseSelectionOption) => void;
	data: Enterprise[];
}) => (
	<SingleSelectionDialog
		open={open}
		onOpenChange={onOpenChange}
		handleClose={handleClose}
		handleSubmit={handleSubmit}
		data={data}
		title="Select an Enterprise"
		noneOption={{ id: "none", name: "No Enterprise" }}
	/>
);
export default function AutopilotList() {
	const navigate = useNavigate();
	const { data: user } = useMe();
	const [data, setData] = useState<Autopilot[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [userDialogOpen, setUserDialogOpen] = useState(false);
	const [initialUsers, setInitialUsers] = useState<User[]>([]);
	const [selectedAutopilots, setSelectedAutopilots] = useState<UUID[]>([]);
	const [filterDialogOpen, setFilterDialogOpen] = useState(false);
	const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });
	const [showDrafts, setShowDrafts] = useState(false);
	const [enterpriseDialogOpen, setEnterpriseDialogOpen] = useState(false);

	const { enterpriseId } = useParams<{ enterpriseId: UUID }>();
	const { updateAutopilot } = useAutopilot();
	const {
		autopilots: enterpriseAutopilots,
		isLoading,
		refetch,
		total,
		perPage,
		page,
	} = useAutopilots({ enterpriseId: enterpriseId, search: searchTerm });
	const { data: enterpriseUsers } = useEnterpriseUsers(enterpriseId ?? "");
	const { autopilots: allAutopilots } = useAutopilots({
		search: searchTerm,
	});
	const { enterprisesData: enterprises } = useEnterprises();

	const [queryParams] = useSearchParams();
	const queryFilters = queryParams.get("filters");
	const { data: enterprise } = useEnterpriseById(enterpriseId ?? "");

	const [filters, setFilters] = useState<Filter[]>([]);

	const { drafts: draftAutopilots } = useDraftAutopilots();

	const filterDialogRef = useRef<HTMLDivElement>(null);
	const slidersHorizontalRef = useRef<HTMLDivElement>(null);
	const isAutopilotsPage = useMemo(() => {
		return window.location.pathname.includes("/autopilots");
	}, [window.location.pathname]);

	const onPageChange = (newPage: number) => {
		refetch({ page: newPage });
	};

	const onToggleFilterDialog = () => {
		if (slidersHorizontalRef.current) {
			const rect = slidersHorizontalRef.current.getBoundingClientRect();
			setDialogPosition({ top: rect.bottom, left: rect.left });
		}
		setFilterDialogOpen(!filterDialogOpen);
	};

	const onFilterRemove = (key: string) => {
		setFilters(filters.filter((filter) => filter.key !== key));
		switch (key) {
			case "drafts":
				navigate(`/admin/enterprise/${enterpriseId}`);
				setShowDrafts(false);
				setData([...enterpriseAutopilots]);
				break;
			case "enterprise":
				navigate("/admin/autopilots");
				setData([...allAutopilots]);
				// navigate(`/admin/enterprise`);
				// setData([...enterpriseAutopilots]);
				break;
		}
	};

	const setEnterpriseFilter = (enterprise: EnterpriseSelectionOption) => {
		const updatedFilters = filters?.filter(
			(filter) => filter.key !== "enterprise",
		);
		setFilters([
			...updatedFilters,
			{
				key: "enterprise",
				label: enterprise.name,
			},
		]);
	};

	useEffect(() => {
		if (enterprise) {
			setEnterpriseFilter(enterprise);
		}
	}, [enterprise]);

	useEffect(() => {
		const isDraftFilter = filters?.find((filter) => filter.key === "drafts");
		const enterpriseFilter = filters?.find(
			(filter) => filter.key === "enterprise",
		);
		if (queryFilters?.includes("drafts")) {
			setShowDrafts(true);
			!isDraftFilter &&
				setFilters([
					...filters,
					{
						key: "drafts",
						label: "Drafts",
					},
				]);
			setFilterDialogOpen(false);
			setData([...draftAutopilots]);
		} else {
			enterpriseFilter && setData([...enterpriseAutopilots]);
			!enterpriseFilter && setData([...allAutopilots]);
		}
	}, [queryFilters, draftAutopilots]);

	const onDraftsClick = () => {
		setFilterDialogOpen(false);
		if (!showDrafts) {
			navigate(`/admin/enterprise/${enterpriseId}?filters=drafts`);
		}
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				filterDialogRef.current &&
				!filterDialogRef.current.contains(event.target)
			) {
				setFilterDialogOpen(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [filterDialogRef]);

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const onToggleAutopilot = (autopilotId: UUID, value: boolean) => {
		updateAutopilot({
			id: autopilotId,
			is_enabled: value,
			enterprise_id: enterpriseId,
		});
	};

	const onAutopilotSelect = (autopilotId: UUID, value: boolean | string) => {
		if (value) {
			setSelectedAutopilots([...selectedAutopilots, autopilotId]);
			const selectedAutopilotUsers = selectedAutopilots.map(
				(id) => data.find((autopilot) => autopilot.id === id)?.users ?? [],
			);

			const commonUsers = selectedAutopilotUsers.reduce((common, users) =>
				common.filter((user) => users.includes(user)),
			);

			setInitialUsers(commonUsers);
		} else {
			setSelectedAutopilots(
				selectedAutopilots.filter((id) => id !== autopilotId),
			);
			const autopilotUsers =
				data.find((autopilot) => autopilot.id === autopilotId)?.users ?? [];
			const updatedInitialUsers = initialUsers.filter(
				(user) => !autopilotUsers.includes(user),
			);
			setInitialUsers(updatedInitialUsers);
		}
	};

	const handleUsersSubmit = (selectedUsers: User[]) => {
		selectedAutopilots.forEach((autopilotId) => {
			updateAutopilot({
				id: autopilotId,
				users: selectedUsers,
				enterprise_id: enterpriseId,
			});
		});
		setUserDialogOpen(false);
	};

	useEffect(() => {
		if (enterpriseId) {
			refetch({ page: page, perPage: perPage });
		}
	}, [enterpriseId]);

	useEffect(() => {
		if (!showDrafts) {
			if (enterpriseAutopilots && !isAutopilotsPage) {
				setData([...enterpriseAutopilots]);
			}
			const isNoEnterpriseFilter = filters?.find(
				(filter) => filter.key === "enterprise",
			);
			if (allAutopilots && isAutopilotsPage && !isNoEnterpriseFilter) {
				setData([...allAutopilots]);
			}
		}
	}, [enterpriseAutopilots, showDrafts, allAutopilots]);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	const handleEnterpriseSubmit = (
		selectedEnterprise: EnterpriseSelectionOption,
	) => {
		setEnterpriseFilter(selectedEnterprise);
		if (selectedEnterprise.id === "none") {
			navigate(`/admin/autopilots`);
			const autopilotsWithoutEnterprise = allAutopilots.filter(
				(autopilot) => autopilot.shared_enterprise_ids.length === 0,
			);
			setData([...autopilotsWithoutEnterprise]);
		} else {
			navigate(`/admin/enterprise/${selectedEnterprise.id}`);
			setData([...enterpriseAutopilots]);
		}
		setEnterpriseDialogOpen(false);
	};

	const handleNewAutopilot = () => {
		if (!enterpriseId && user?.enterprise_id)
			navigate(`/admin/enterprise/${user?.enterprise_id}/autopilot/new`);
		else if (enterpriseId)
			navigate(`/admin/enterprise/${enterpriseId}/autopilot/new`);
	};

	return (
		<Flex
			direction="column"
			style={{
				borderRadius: "12px",
				border: "1px solid #E0E0E0",
				marginBottom: "30px",
				width: "1200px",
				overflow: "hidden",
			}}
		>
			<Flex
				direction="column"
				style={{
					top: 0,
					zIndex: 1,
					backgroundColor: "white",
					borderRadius: "12px 12px 0px 0px",
					position: "sticky",
				}}
			>
				<Flex direction="row" justify="between" style={{ padding: "20px" }}>
					<Flex
						direction="row"
						justify="center"
						align="center"
						style={{ gap: "4px" }}
					>
						<div ref={slidersHorizontalRef}>
							<Icon.SlidersHorizontal
								style={{ cursor: "pointer" }}
								onClick={onToggleFilterDialog}
							/>
						</div>
						{filters.map(
							(filter) =>
								filter.label && (
									<FilterCard
										key={filter.key}
										label={filter.label}
										filterKey={filter.key}
										onRemove={onFilterRemove}
									/>
								),
						)}
					</Flex>
					<Flex direction="row" align="center" style={{ gap: "10px" }}>
						<input
							type="text"
							placeholder="Search..."
							value={searchTerm}
							onChange={handleSearch}
							style={{
								height: "30px",
								width: "200px",
								borderRadius: "4px",
								border: "1px solid #ccc",
								padding: "0px 10px",
							}}
						/>
						{/* @ts-ignore */}
						<Button
							style={{ cursor: "pointer" }}
							onClick={handleNewAutopilot}
							disabled={!enterpriseId && !user?.enterprise_id}
						>
							<Icon.Plus />
							New Autopilot
						</Button>
					</Flex>
				</Flex>
				<div
					style={{
						width: "100%",
						borderColor: "#E0E0E0",
						height: "1px",
						backgroundColor: "#E0E0E0",
					}}
				/>
			</Flex>
			{selectedAutopilots.length > 0 && (
				<Flex
					align="center"
					style={{
						padding: "6px 16px",
						gap: "10px",
						borderBottom: "1px solid var(--mb-color-dark-gray-8)",
						background: "var(--mb-color-dark-gray-10)",
					}}
				>
					<Text> {selectedAutopilots.length} autopilot selected</Text>
					{/* @ts-ignore */}
					<Button
						variant="ghost"
						style={{
							border: "1px solid var(--border-default, #CCC)",
							cursor: "pointer",
							boxShadow: "none !important",
							color: "none !important",
							margin: "0px",
							backgroundColor: "#FFF",
						}}
						onClick={() => {
							setUserDialogOpen(true);
						}}
					>
						<Icon.Plus fill="#333" />
						<Text style={{ color: "#333" }}>Assign User</Text>
					</Button>
				</Flex>
			)}
			<Flex direction="column" style={{ overflow: "scroll" }}>
				{data
					?.sort((a, b) => a.name.localeCompare(b.name))
					.map((autopilot, index) => (
						<Flex key={autopilot.id} direction="column">
							<AutopilotCard
								autopilot={autopilot}
								enterpriseId={enterpriseId ?? ""}
								onToggleAutopilot={onToggleAutopilot}
								onAutopilotSelect={onAutopilotSelect}
								selectedAutopilots={selectedAutopilots}
							/>
							{index < data.length - 1 && (
								<div
									style={{
										width: "100%",
										borderColor: "#E0E0E0",
										height: "1px",
										backgroundColor: "#E0E0E0",
									}}
								/>
							)}
						</Flex>
					))}
			</Flex>
			<Pagination
				page={page}
				total={total}
				perPage={perPage}
				onPageChange={onPageChange}
			/>
			<MultiSelectionDialog
				handleSubmit={(selectedUsers) => handleUsersSubmit(selectedUsers)}
				open={userDialogOpen}
				handleClose={() => setUserDialogOpen(false)}
				onOpenChange={setUserDialogOpen}
				initialData={initialUsers}
				data={enterpriseUsers as User[]}
			/>
			<EnterpriseSelectionDialog
				open={enterpriseDialogOpen}
				handleClose={() => setEnterpriseDialogOpen(false)}
				onOpenChange={setEnterpriseDialogOpen}
				handleSubmit={handleEnterpriseSubmit}
				data={enterprises as Enterprise[]}
			/>
			{filterDialogOpen && (
				<div
					ref={filterDialogRef}
					style={{
						position: "absolute",
						top: dialogPosition.top,
						left: dialogPosition.left,
						transform: "translateY(5px)",
						zIndex: 1000,
					}}
				>
					<FilterDialog
						onDraftsClick={onDraftsClick}
						onEnterpriseClick={() => {
							setFilterDialogOpen(false);
							setEnterpriseDialogOpen(true);
						}}
					/>
				</div>
			)}
		</Flex>
	);
}
