import { Icon } from "@mightybot/web-ui";
import { NavLinkItem } from "./NavBar";

export const navLinks: NavLinkItem[] = [
	{ to: "/home", Icon: Icon.House, label: "Home", type: "internal" },
	{
		to: "/chats",
		Icon: Icon.ChatTeardropDots,
		label: "Chats",
		type: "internal",
	},
	{
		to: "/search",
		Icon: Icon.ListMagnifyingGlass,
		label: "Search",
		type: "internal",
	},
	{ to: "/meetings", Icon: Icon.Video, label: "Meetings", type: "internal" },
	{ to: "/people", Icon: Icon.AddressBook, label: "People", type: "internal" },
	{
		to: "/companies",
		Icon: Icon.Buildings,
		label: "Companies",
		type: "internal",
	},
	{ to: "/deals", Icon: Icon.Briefcase, label: "Deals", type: "internal" },
];

export const internalNavLinks: NavLinkItem[] = [
	{
		to: `https://chromewebstore.google.com/detail/mightybot-beta/${process.env.REACT_APP_PUBLIC_EXTENSION_ID}`,
		Icon: Icon.PuzzlePiece,
		label: "Install",
		type: "external",
	},
	{
		to: "/settings/general",
		Icon: Icon.Gear,
		label: "Settings",
		type: "internal",
	},
	{
		to: "https://www.mightybot.ai/help/installation",
		Icon: Icon.Question,
		label: "Help",
		type: "external",
	},
];
