import React, { useRef } from "react";
import styled from "styled-components";
import { Flex } from "@mightybot/web-ui";

interface PlaceholderProps {
	placeholder: string;
	style?: React.CSSProperties;
	imageStyle?: React.CSSProperties;
}

const PlaceholderContainer = styled(Flex)`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: start;
	align-items: start;
	overflow: hidden;
`;

const StyledImage = styled.img`
	width: 1360px;
	height: auto;
`;

const Placeholder: React.FC<PlaceholderProps> = ({
	placeholder,
	style,
	imageStyle,
}) => {
	const imgRef = useRef<HTMLImageElement>(null);

	return (
		<PlaceholderContainer style={style}>
			<StyledImage
				ref={imgRef}
				src={placeholder}
				alt="WIP"
				style={imageStyle}
			/>
		</PlaceholderContainer>
	);
};

export default Placeholder;
