import React from "react";
import { User } from "@mightybot/core";
import ChatReply from "./ChatReply";
import ChatUserMessage from "./ChatUserMessage";
import { ChatContainer } from "./styled";

export interface ChatMessageProps {
	timestamp: string;
	question: string;
	reply: string;
	content?: string;
	author?: Partial<User> | null;
	isStreaming?: boolean;
}

const Chat: React.FC<ChatMessageProps> = ({
	timestamp,
	content,
	question,
	reply,
	author,
	isStreaming,
}) => {
	return (
		<ChatContainer direction="column" gap="6px" style={{ width: "100%" }}>
			<ChatUserMessage
				timestamp={timestamp}
				content={content}
				question={question}
				author={author}
			/>

			<ChatReply reply={reply} isStreaming={isStreaming} />
		</ChatContainer>
	);
};

export default Chat;
