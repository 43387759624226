import { Flex, Icon } from "@mightybot/web-ui";

import { styled } from "styled-components";

export const Dot = styled(Flex)`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--mb-color-light-gray-9);
`;

export const ChatUserMessageContainer = styled(Flex)`
	display: grid;
	grid-template-columns: auto 1fr auto;
	gap: 6px;
	align-items: flex-start;
	width: 100%;
`;

export const ChatReplyContainer = styled(Flex)`
	gap: 6px;
	background-color: var(--mb-color-light-blue-1);
	padding: 16px 20px 10px 20px;
	border-radius: 8px;
	margin-left: 38px;
`;

export const CopyIcon = styled(Icon.Copy)`
	cursor: pointer;
	&:hover {
		background-color: var(--gray-1);
	}
`;

export const ChatContainer = styled(Flex)`
	width: 100%;
`;

export const TypingIndicator = styled.span`
	display: inline-block;
	animation: blink 1s step-end infinite;
	margin-left: 2px;

	@keyframes blink {
		0%,
		100% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
	}
`;

export const MessageBackground = styled.div`
	padding: 8px 12px;
	border-radius: 12px;
	background-color: var(--mb-color-light-gray-3);
	width: fit-content;
	max-width: 100%;
	word-break: break-word;
	padding: 12px 20px;
`;

export const customMarkdownStyles = {
	content: {
		fontSize: "14px",
		fontWeight: "light",
		whiteSpace: "pre-wrap",
	},
	paragraph: {
		marginBottom: 0,
	},
};
