import React from "react";
import { Flex, Select } from "@mightybot/web-ui";

type PageSelectorProps = {
	value: number;
	data: number[];
	onChange: (newPage: number) => void;
	placeholder: string;
};

const PageSelector = ({
	value,
	data,
	onChange,
	placeholder,
}: PageSelectorProps) => {
	return (
		<Flex
			align="center"
			justify="between"
			style={{ width: "100%" }}
			id={`page-selector`}
		>
			<Select.Root
				// defaultValue={`${defaultValue}`}
				value={`${value}`}
				onValueChange={(value) => onChange(Number(value))}
			>
				<Select.Trigger
					// @ts-ignore
					placeholder={placeholder}
					id={`page-selector-trigger`}
					style={{
						width: "100%",
						boxShadow: "none",
						cursor: "pointer",
						border: "1px solid #E0E0E0",
						height: "30px",
					}}
				/>
				<Select.Content>
					<Select.Group>
						{data?.map((item) => (
							// @ts-ignore
							<Select.Item
								key={item}
								value={`${item}`}
								id={`page-selector-item-${item}`}
							>
								{item}
							</Select.Item>
						))}
					</Select.Group>
				</Select.Content>
			</Select.Root>
		</Flex>
	);
};

export default PageSelector;
