import React from "react";
import { Flex, Icon, Text, Popover } from "@mightybot/web-ui";
import {
	Container,
	AvatarContainer,
	Avatar,
	FounderButton,
	PopoverRoot,
	PopoverContent,
} from "./styled";
import { FounderAvatar, Gift } from "@mightybot/core-ui";
import {
	GiftCardButtonContainer,
	GiftCardText,
	GiftCardIconContainer,
} from "./styled";

const ContactUsOverlay: React.FC = () => {
	return (
		<PopoverRoot>
			<Popover.Trigger>
				<GiftCardButtonContainer>
					<GiftCardIconContainer>
						<img src={Gift} alt="Gift Card" />
					</GiftCardIconContainer>
					<GiftCardText>Claim $50 Gift Card</GiftCardText>
				</GiftCardButtonContainer>
			</Popover.Trigger>

			<PopoverContent side="right" align="center">
				<Container>
					<AvatarContainer>
						<Avatar src={FounderAvatar} alt="John Forrester" />
					</AvatarContainer>

					<Text
						style={{
							color: "var(--mb-gray-10)",
							fontSize: "18px",
							fontWeight: 500,
							lineHeight: "24px",
							marginTop: "12px",
						}}
					>
						We'd love your feedback!
					</Text>
					<Text
						style={{
							color: "var(--mb-gray-10)",
							fontSize: "18px",
							fontWeight: 400,
							lineHeight: "24px",
							marginBottom: "16px",
						}}
					>
						Get a $50 gift card for talking to a founder
					</Text>

					<FounderButton
						onClick={() =>
							window.open("https://calendly.com/mightybot-john/30min")
						}
					>
						<Flex align="center" style={{ flex: 1 }}>
							<Text style={{ color: "var(--mb-gray-10)" }}>
								John Forrester,&nbsp;
							</Text>
							<Text style={{ color: "var(--mb-gray-8)" }}>CEO</Text>
						</Flex>
						<Icon.CaretRight size={18} style={{ marginLeft: "auto" }} />
					</FounderButton>

					<FounderButton
						onClick={() => window.open("https://calendly.com/exafox/30min")}
					>
						<Flex align="center" style={{ flex: 1 }}>
							<Text style={{ color: "var(--mb-gray-10)" }}>
								Stefan Fox,&nbsp;
							</Text>
							<Text style={{ color: "var(--mb-gray-8)" }}>CTO</Text>
						</Flex>
						<Icon.CaretRight size={18} style={{ marginLeft: "auto" }} />
					</FounderButton>
				</Container>
			</PopoverContent>
		</PopoverRoot>
	);
};

export default ContactUsOverlay;
