import React, { useState } from "react";
import { Dialog, Flex, Text, Button } from "@mightybot/web-ui";
import { Log } from "@mightybot/core";

type LogsOutputDialogProps = {
	open: boolean;
	onClose: () => void;
	log: Log | null | undefined;
};

const LogsOutputDialog = ({ open, onClose, log }: LogsOutputDialogProps) => {
	if (!log) {
		return null;
	}
	return (
		<Dialog.Root open={open} onOpenChange={onClose}>
			<Dialog.Content style={{ maxWidth: 500, padding: 20 }}>
				<Dialog.Title>Output Details</Dialog.Title>
				<Flex direction="column" style={{ gap: "16px" }}>
					<Flex
						direction="column"
						style={{
							padding: "12px 26px",
							backgroundColor: "var(--mb-blue-1)",
						}}
					>
						<Text>
							<strong>Input:</strong> {log.input}
						</Text>
						<Text>
							<strong>Output:</strong> {log.output}
						</Text>
						<Text>
							<strong>Enterprise:</strong> {log.enterprise_name}
						</Text>
						<Text>
							<strong>Is Test:</strong> {log.is_test ? "Yes" : "No"}
						</Text>
						<Text>
							<strong>Trace ID:</strong> {log.trace_id || "N/A"}
						</Text>
						<Text>
							<strong>Trace URL:</strong> {log.trace_url || "N/A"}
						</Text>
						<Text>
							<strong>Extracted Data:</strong>
						</Text>
						{log.data && (
							<Flex direction="column" style={{ gap: "8px" }}>
								{Object.entries(log.data).map(([key, value]) => {
									const formattedKey = key
										.replace(/_/g, " ")
										.replace(/\b\w/g, (l) => l.toUpperCase());
									return (
										<Flex key={key} direction="column">
											<Text weight="medium">{formattedKey}:</Text>
											<Text
												style={{
													backgroundColor: "var(--mb-gray-1)",
													padding: "8px",
													borderRadius: "4px",
													overflowX: "auto",
													maxHeight: "100px",
													fontSize: "12px",
													whiteSpace: "pre-wrap",
													wordBreak: "break-word",
												}}
											>
												{typeof value === "object"
													? JSON.stringify(value, null, 2)
													: String(value)}
											</Text>
										</Flex>
									);
								})}
							</Flex>
						)}
					</Flex>
					<Flex justify="end" gap="3">
						{/* @ts-ignore */}
						<Button
							variant="soft"
							color="gray"
							onClick={onClose}
							style={{ cursor: "pointer" }}
						>
							Close
						</Button>
					</Flex>
				</Flex>
			</Dialog.Content>
		</Dialog.Root>
	);
};

export default LogsOutputDialog;
