import React from "react";
import Deals from "../components/Deals";
import Layout from "../components/Layout";

const DealsRoute: React.FC = () => {
	return (
		<Layout>
			<Deals />
		</Layout>
	);
};

export default DealsRoute;
