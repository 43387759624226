import React, { useRef, useEffect, useState } from "react";
import { Icon, Flex, TextArea, Tooltip, IconButton } from "@mightybot/web-ui";
import TruncatedMarkdown from "../TruncatedMarkdown";
import { ChatBoxInnerContainer } from "./styled";
import { ChatBoxOuterContainer } from "./styled";
import { useSearchSelection } from "@mightybot/core";
import { SelectedFilesPill } from "./styled";

interface ChatBoxProps {
	onSend: (message: string) => void;
	placeholder?: string;
	context?: {
		prompt: string;
		[key: string]: any;
	} | null;
	onContextClose?: () => void;
	initialHeight?: number;
	maxHeightVh?: number;
	className?: string;
	showSelectedCount?: boolean;
	disabled?: boolean;
	onSelectData?: (data: any) => void;
}

const ChatBox: React.FC<ChatBoxProps> = ({
	onSend,
	placeholder = "Type your message...",
	context,
	onContextClose,
	initialHeight = 77,
	maxHeightVh = 30,
	className,
	showSelectedCount = false,
	disabled = false,
	onSelectData,
}) => {
	const [input, setInput] = useState("");
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const maxHeight = window.innerHeight + 100;
	const { selectedResults } = useSearchSelection();

	useEffect(() => {
		if (textAreaRef.current) {
			adjustHeight(textAreaRef.current);
		}
	}, [context?.prompt, input]);

	const adjustHeight = (textarea: HTMLTextAreaElement) => {
		textarea.style.height = "auto";
		const newHeight = Math.min(
			textarea.scrollHeight < initialHeight
				? initialHeight
				: textarea.scrollHeight,
			maxHeight,
		);
		textarea.style.height = `${newHeight}px`;
	};

	const handleSend = () => {
		if (input.trim()) {
			onSend(input);
			setInput("");
			if (textAreaRef.current) {
				textAreaRef.current.style.height = `${initialHeight}px`;
			}
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === "Enter" && e.shiftKey) {
			e.preventDefault();
			const { selectionStart, selectionEnd } = e.currentTarget;
			const newValue =
				input.slice(0, selectionStart) + "\n" + input.slice(selectionEnd);
			setInput(newValue);

			setTimeout(() => {
				if (textAreaRef.current) {
					textAreaRef.current.selectionStart =
						textAreaRef.current.selectionEnd = selectionStart + 1;
				}
			}, 0);
		} else if (e.key === "Enter" && !e.shiftKey && input.trim()) {
			e.preventDefault();
			handleSend();
		}
	};

	return (
		<ChatBoxOuterContainer direction="column" className={className}>
			<ChatBoxInnerContainer>
				{context?.prompt && (
					<TruncatedMarkdown
						content={context.prompt}
						showCloseButton={true}
						handleCloseButton={onContextClose}
						singleLine
					/>
				)}

				<Flex direction="column" align="end" style={{ width: "100%" }}>
					<TextArea
						ref={textAreaRef}
						placeholder={placeholder}
						style={{
							width: "100%",
							border: "none",
							boxShadow: "none",
							minHeight: `${initialHeight}px`,
							maxHeight: `${maxHeightVh}vh`,
							height: "100%",
							overflow: "hidden",
							outline: "none",
							resize: "none",
						}}
						value={input}
						onChange={(e) => {
							setInput(e.target.value);
							adjustHeight(e.target);
						}}
						onKeyDown={handleKeyDown}
					/>
					<Flex justify="between" align="end" style={{ width: "100%" }}>
						{showSelectedCount && (
							<SelectedFilesPill
								showborder={selectedResults.length > 0 ? "true" : "false"}
								onClick={onSelectData}
							>
								<Icon.At />
								{selectedResults.length > 0 ? (
									<>
										{selectedResults.length}{" "}
										{selectedResults.length === 1 ? "file" : "files"} selected
									</>
								) : (
									<>Add data</>
								)}
							</SelectedFilesPill>
						)}
						<Tooltip content="Send">
							<IconButton
								id="chat-input-send"
								disabled={!input?.length || disabled}
								style={{
									width: 40,
									height: 34,
									borderRadius: 12,
									alignSelf: "flex-end",
								}}
								onClick={handleSend}
							>
								<Icon.PaperPlaneRight weight="fill" />
							</IconButton>
						</Tooltip>
					</Flex>
				</Flex>
			</ChatBoxInnerContainer>
		</ChatBoxOuterContainer>
	);
};

export default ChatBox;
