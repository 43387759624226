import React from "react";
import { User } from "@mightybot/core";
import MeetingChatReply from "./MeetingChatReply";
import MeetingChatUserMessage from "./MeetingChatUserMessage";
import { MeetingChatContainer } from "./styled";

export interface ChatMessageProps {
	timestamp: string;
	question: string;
	reply: string;
	content?: string;
	author?: Partial<User> | null;
	isStreaming?: boolean;
}

const MeetingChat: React.FC<ChatMessageProps> = ({
	timestamp,
	content,
	question,
	reply,
	author,
	isStreaming,
}) => {
	return (
		<MeetingChatContainer
			direction="column"
			gap="6px"
			style={{ width: "100%" }}
		>
			<MeetingChatUserMessage
				timestamp={timestamp}
				content={content}
				question={question}
				author={author}
			/>
			<MeetingChatReply reply={reply} isStreaming={isStreaming} />
		</MeetingChatContainer>
	);
};

export default MeetingChat;
