import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { store } from "@mightybot/core";
import { Provider as BaseProvider } from "@mightybot/web-ui";

import { createAppRouter } from "./router";
import { initializeAnalytics } from "./utils/initializeAnalytics";
import { initializeLogger } from "./utils/initializeLogger";
import reportWebVitals from "./utils/reportWebVitals";
import { ContentfulClient, ContentfulProvider } from "react-contentful";

// Initialize analytics
initializeAnalytics();

// Initialize logger
initializeLogger();

// Create the router
const router = createAppRouter();

// Render the app
const container = document.getElementById("root");

const contentfulClient = ContentfulClient({
	space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || "",
	accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || "",
});

if (container) {
	const root = createRoot(container as HTMLElement);
	root.render(
		<React.StrictMode>
			<ReduxProvider store={store}>
				<BaseProvider>
					<HelmetProvider>
						{/* @ts-ignore */}
						<ContentfulProvider client={contentfulClient}>
							<RouterProvider router={router} />
						</ContentfulProvider>
					</HelmetProvider>
				</BaseProvider>
			</ReduxProvider>
		</React.StrictMode>,
	);
}

// Report web vitals
reportWebVitals();
