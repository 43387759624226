import React from "react";
import { MeetingsPreviewSidebarContainer } from "./styled";
import MeetingsList from "./MeetingsList";
import { JoinMeetingDialog } from "../JoinMeetingDialog/JoinMeetingDialog";

interface MeetingsPreviewSidebarProps {
	isSidebarOpen: boolean;
	toggleSidebar: () => void;
}

const MeetingsPreviewSidebar: React.FC<MeetingsPreviewSidebarProps> = ({
	isSidebarOpen,
	toggleSidebar,
}) => {
	return (
		<MeetingsPreviewSidebarContainer
			style={{ maxWidth: isSidebarOpen ? "270px" : "80px" }}
		>
			<JoinMeetingDialog isSidebarOpen={isSidebarOpen} />
			<MeetingsList
				toggleSidebar={toggleSidebar}
				isSidebarOpen={isSidebarOpen}
			/>
		</MeetingsPreviewSidebarContainer>
	);
};

export default MeetingsPreviewSidebar;
