import { type Metric, onCLS, onFCP, onLCP, onTTFB, onINP } from "web-vitals";
import * as Sentry from "@sentry/react";

const reportWebVitals = (onPerfEntry?: (metric: Metric) => void) => {
	if (process.env.NODE_ENV !== "production") return;

	const captureWebVitals = (metric: Metric) => {
		Sentry.captureMessage("Web Vitals", {
			level: "info",
			extra: {
				metric: {
					id: metric.id,
					name: metric.name,
					value: metric.value,
				},
			},
		});

		if (onPerfEntry && onPerfEntry instanceof Function) {
			onPerfEntry(metric);
		}
	};

	onCLS(captureWebVitals);
	onFCP(captureWebVitals);
	onLCP(captureWebVitals);
	onTTFB(captureWebVitals);
	onINP(captureWebVitals);
};

export default reportWebVitals;
