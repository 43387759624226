import React, { useRef, useEffect } from "react";
import { SearchResultsContainer, SearchResultsList } from "./styled";
import { NLSearchResult } from "@mightybot/core";
import SearchResultGroup from "./SearchResultGroup";
import { Flex, Icon, Skeleton, Text } from "@mightybot/web-ui";
import { useSearchParams } from "react-router-dom";

interface SearchResultsProps {
	isLoading: boolean;
	searchResults: NLSearchResult[];
	onResultsScroll?: (scrolledOut: boolean) => void;
	isDialog: boolean;
	showSelectedOnly: boolean;
}

const renderSkeletonResults = () => {
	return Array(5)
		.fill(null)
		.map((_, index) => (
			<Flex
				key={index}
				direction="column"
				style={{
					padding: "12px",
					border: "1px solid var(--mb-gray-5)",
					borderRadius: index === 4 ? "0px 0px 8px 8px" : "0px",
					borderBottom: index === 4 ? "1px solid var(--mb-gray-5)" : "none",
					borderTop: index === 0 ? "none" : "1px solid var(--mb-gray-5)",
				}}
			>
				<Skeleton width="30%" height="20px" style={{ marginBottom: "8px" }} />
				<Skeleton width="20%" height="16px" style={{ marginBottom: "8px" }} />
				<Skeleton width="100%" height="16px" style={{ marginBottom: "4px" }} />
				<Skeleton width="80%" height="16px" />
			</Flex>
		));
};

const EmptySearchResults = ({ message }: { message: string }) => {
	return (
		<Flex
			height="100%"
			width="100%"
			justify="center"
			align="center"
			direction="column"
			gap="2"
		>
			<Icon.MagnifyingGlass width="32" height="32" />
			<Text weight="light" size="3">
				{message}
			</Text>
		</Flex>
	);
};

const SearchResults = ({
	isLoading,
	searchResults,
	onResultsScroll,
	isDialog,
	showSelectedOnly,
}: SearchResultsProps) => {
	const [searchParams] = useSearchParams();
	const activeSearchId = searchParams.get("searchId");
	const resultsRef = useRef<HTMLDivElement>(null);
	const observerRef = useRef<IntersectionObserver | null>(null);

	// Group results by parent_id
	const groupedResults = React.useMemo(() => {
		const groups: { [key: string]: NLSearchResult[] } = {};

		searchResults.forEach((result) => {
			const key = result.parent_id || result.id;
			if (!groups[key]) {
				groups[key] = [];
			}
			groups[key].push(result);
		});

		return Object.values(groups);
	}, [searchResults]);

	useEffect(() => {
		if (!resultsRef.current || !onResultsScroll) return;

		const handleScroll = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;
			if (entry) {
				// Using a small threshold to determine if element is out of view
				const isScrolledOut = entry.intersectionRatio < 0.5;
				onResultsScroll(isScrolledOut);
			}
		};

		// Get the scrollable container (SearchInnerContainer)
		const scrollContainer = resultsRef.current.closest(
			'[class*="SearchInnerContainer"]',
		);

		observerRef.current = new IntersectionObserver(handleScroll, {
			root: scrollContainer,
			rootMargin: "0px",
			threshold: [0, 0.5, 1], // Monitor multiple thresholds for smoother detection
		});

		// Observe the fourth result if it exists
		const targetElement = resultsRef.current.children[1];
		if (targetElement) {
			observerRef.current.observe(targetElement);
		}

		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
			}
		};
	}, [searchResults, onResultsScroll]);

	if (!isLoading && isDialog && !activeSearchId && searchResults.length === 0)
		return <EmptySearchResults message="No recent searched item" />;

	const isSearchActive = activeSearchId !== null;
	return (
		<SearchResultsContainer
			issearchactive={(isSearchActive || isLoading).toString()}
			isdialog={isDialog.toString()}
			hasresults={searchResults.length > 0 ? "true" : "false"}
		>
			<SearchResultsList ref={resultsRef}>
				{isLoading
					? renderSkeletonResults()
					: groupedResults.length > 0
						? groupedResults.map((group, index) => (
								<SearchResultGroup
									key={group[0].parent_id || group[0].id}
									results={group}
									index={index}
									isLast={index === groupedResults.length - 1}
									isDialog={isDialog}
									showSelectedOnly={showSelectedOnly}
								/>
							))
						: activeSearchId && (
								<EmptySearchResults message="No search results found" />
							)}
			</SearchResultsList>
		</SearchResultsContainer>
	);
};

export default SearchResults;
