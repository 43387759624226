import React from "react";

import { useEnterprise, useLogout, useMe } from "@mightybot/core";
import {
	Flex,
	Text,
	Icon,
	AvatarWrapper,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import { useNavigate } from "react-router-dom";
import { NavLinkItem } from "./NavBar";
import { ProfileOption } from "./styled";

type ProfileProps = {
	setIsPopoverOpen?: (isOpen: boolean) => void;
	isFlyout?: boolean;
	profileOptions?: NavLinkItem[];
};

const Profile = ({ setIsPopoverOpen, profileOptions }: ProfileProps) => {
	const { data: user } = useMe();
	const { enterpriseData } = useEnterprise();
	const [logout] = useLogout();
	const navigate = useNavigate();

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const handleLogout = async () => {
		await logout();
		navigate("/login");
	};

	const handleOptionClick = (option: NavLinkItem) => {
		if (option.type === "internal") {
			navigate(option.to);
		} else {
			window.open(option.to, "_blank");
		}
	};

	return (
		<React.Fragment>
			<Flex
				align="center"
				style={{
					borderBottom: "1px solid var(--border-primary, #E0E0E0)",
					padding: "8px 0px",
					width: "100%",
				}}
			>
				<AvatarWrapper
					alt={user?.name ?? "User"}
					src={user?.profile_pic ?? ""}
					radius="full"
					variant="solid"
					size="2"
				/>
				<Flex direction="column" align="start" style={{ padding: "0px 4px" }}>
					<Text
						size="2"
						align="center"
						weight="medium"
						style={{ textTransform: "capitalize" }}
					>
						{user?.name ?? "User"}
					</Text>
					<Text
						align="center"
						style={{
							color: "var(--gray-9)",
						}}
					>
						{enterpriseData?.name}
					</Text>
				</Flex>
			</Flex>
			{profileOptions?.map((option) => (
				<ProfileOption
					key={option.label}
					onClick={() => handleOptionClick(option)}
				>
					<option.Icon />
					<Text size="2">{option.label}</Text>
				</ProfileOption>
			))}
			<ProfileOption
				onClick={() => {
					handleLogout();
					setIsPopoverOpen?.(false);
				}}
				align="center"
			>
				<Icon.SignOut size={16} fill="#DC3D43" weight="bold" />
				<Text size="2" style={{ color: "#DC3D43" }}>
					Logout
				</Text>
			</ProfileOption>
		</React.Fragment>
	);
};

export default Profile;
