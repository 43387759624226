import styled from "styled-components";
import { Flex } from "@mightybot/web-ui";

export const WelcomeContainer = styled(Flex)`
	flex-direction: column;
	width: 100%;
`;

export const ResponsiveFlex = styled(Flex)`
	gap: 30px;
	padding: 0 50px;

	@media (max-width: 768px) {
		padding: 0 20px;
		gap: 20px;
	}
`;

export const LogoContainer = styled(Flex)`
	gap: 10px;
	margin-top: 30px;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
`;

export const Logo = styled.img`
	width: 200px;
	height: 50px;

	@media (max-width: 768px) {
		width: 150px;
		height: 38px;
	}
`;

export const Card = styled(Flex)`
	width: 800px;
	@media (max-width: 768px) {
		flex-direction: column;
		width: 100%;
	}
`;

export const CardImage = styled.img`
	border-radius: 20px 0 0 20px;
	width: 456px;
	height: 249px;

	@media (max-width: 768px) {
		width: 100%;
		height: auto;
		border-radius: 20px 20px 0 0;
	}
`;

export const CardContent = styled(Flex)`
	width: 50%;
	background-color: #edf3ff;
	padding: 30px;
	border-radius: 0 20px 20px 0;
	gap: 9px;

	@media (max-width: 768px) {
		width: 100%;
		border-radius: 0 0 20px 20px;
	}
`;

export const InstallCard = styled(Flex)`
	gap: 30px;
	border: 1px solid #e0e0e0;
	border-radius: 20px;
	width: 800px;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}
`;

export const InstallCardContent = styled(Flex)`
	width: 50%;
	border-radius: 20px 0 0 20px;
	padding: 30px;
	gap: 9px;

	@media (max-width: 768px) {
		width: 100%;
		border-radius: 20px 20px 0 0;
	}
`;

export const InstallCardImage = styled.img`
	border-radius: 0 20px 20px 0;
	padding: 30px;
	width: 361px;

	@media (max-width: 768px) {
		width: 100%;
		height: auto;
		border-radius: 0 0 20px 20px;
		padding: 20px;
	}
`;
