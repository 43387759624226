import React from "react";
import {
	Flex,
	Text,
	Icon,
	DropdownMenu,
	CustomReactMarkdown,
} from "@mightybot/web-ui";
import { formatDistanceToNow } from "date-fns";
import { TagContainer, Cube, FeedTileContainer } from "./styled";
import CategoryMapping from "./CategoryMapping.json";
import { useIntegrationLogos, MBLogoBlack } from "@mightybot/core-ui";
import { FeedItem, formatDescription, hasLinks } from "./Home";
import { FeedAction, useEngagement } from "@mightybot/core";
import FeedExtras from "./FeedExtras";
import { customMarkdownStyles } from "./styled";

interface FeedTileProps {
	feed: FeedItem;
	onDismiss: (feed: FeedItem) => void;
}

const DEFAULT_CATEGORY = {
	background: "#F0F0F0",
	icon: "#808080",
};

const FeedTile: React.FC<FeedTileProps> = ({ feed, onDismiss }) => {
	const { getIntegrationLogo } = useIntegrationLogos();
	const appIcon = getIntegrationLogo(feed.app.toLowerCase());
	const { handleEngagementAction } = useEngagement();
	const iconSrc = appIcon ? appIcon : MBLogoBlack;
	const isDefaultLogo = iconSrc === MBLogoBlack;
	const feedHasLinks = hasLinks(feed.description);

	const handleEngagement = (action: FeedAction) => {
		handleEngagementAction({
			signal_id: feed.id ?? null,
			action: action,
			source: "HOME_FEED",
		});
	};

	const handleFeedClick = (
		e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
	) => {
		if (feedHasLinks) {
			// If the description has links, don't navigate away from the page
			return;
		}
		e.preventDefault();
		feed.link && window.open(feed.link, "_blank", "noopener,noreferrer");
		handleEngagement("CLICK");
	};

	const handleDismiss = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		handleEngagement("DISMISS");
		onDismiss(feed);
	};

	const categoryStyle = CategoryMapping[feed.category] || DEFAULT_CATEGORY;

	return (
		<FeedTileContainer
			role={feed.link && !feedHasLinks ? "link" : "none"}
			onClick={handleFeedClick}
			tabIndex={0}
			onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
				if (e.key === "Enter") {
					handleFeedClick(e);
				}
			}}
			title={feed.link && !feedHasLinks ? feed.link : ""}
			style={{
				cursor: feedHasLinks ? "default" : "pointer",
			}}
		>
			<Flex justify="between" align="center">
				<Flex style={{ gap: 8 }}>
					<div
						style={{
							width: 24,
							height: 24,
							backgroundColor: isDefaultLogo ? "#C4E75A" : "transparent",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							borderRadius: "50%",
						}}
					>
						<img
							src={iconSrc}
							alt="icon"
							style={{
								width: isDefaultLogo ? 16 : 24,
								height: isDefaultLogo ? 16 : 24,
							}}
						/>
					</div>
					{feed.company_name && (
						<TagContainer
							justify="center"
							align="center"
							style={{
								backgroundColor: "var(--mb-blue-3)",
							}}
						>
							<Text>{feed.company_name}</Text>
						</TagContainer>
					)}
				</Flex>
			</Flex>
			{/* <Text weight="medium">{feed.title}</Text> */}
			<CustomReactMarkdown styles={customMarkdownStyles as any}>
				{formatDescription(feed.description)}
			</CustomReactMarkdown>
			<Text
				size="1"
				weight="light"
				style={{ flex: 1, color: "var(--mb-gray-9)" }}
			>
				{formatDistanceToNow(new Date(feed.timestamp))} ago
			</Text>
			<Flex justify="between" align="center" style={{ gap: 6, width: "100%" }}>
				{feed.category && (
					<TagContainer
						justify="center"
						align="center"
						key={feed.category}
						style={{
							backgroundColor: categoryStyle.background,
						}}
					>
						<Cube color={categoryStyle.icon} />
						<Text>{feed.category}</Text>
					</TagContainer>
				)}
				<Flex style={{ gap: 15 }}>
					{/* TODO: Implement thumbs up/down */}
					{/* <Icon.ThumbsUp />
					<Icon.ThumbsDown /> */}
					<DropdownMenu.Root>
						<DropdownMenu.Trigger onClick={(e) => e.stopPropagation()}>
							<Icon.DotsThree style={{ cursor: "pointer" }} />
						</DropdownMenu.Trigger>
						<DropdownMenu.Content>
							<FeedExtras onDismiss={handleDismiss} />
						</DropdownMenu.Content>
					</DropdownMenu.Root>
				</Flex>
			</Flex>
		</FeedTileContainer>
	);
};

export default FeedTile;
