import React, { useState } from "react";
import { Flex, Text, Icon, Select } from "@mightybot/web-ui";
import { Log } from "@mightybot/core";
import LogsOutputDialog from "./LogsOutputDialog";
import PageSelector from "../PageSelector";
import Pagination from "../Pagination";

type AutopilotLogsProps = {
	logs: Log[];
	total: number;
	page: number;
	perPage: number;
	onPageChange: (newPage: number) => void;
};

export const AutopilotLogs = ({
	logs,
	total,
	page,
	perPage,
	onPageChange,
}: AutopilotLogsProps) => {
	const [expandedLogs, setExpandedLogs] = useState<string[]>([]);
	const [open, setOpen] = useState(false);
	const [log, setLog] = useState<any>(null);
	const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

	const toggleLogDetails = (logId: string) => {
		const selectedLog = logs.find((log) => log.id === logId);
		setLog(selectedLog);

		setOpen(true);
		setExpandedLogs((prev) =>
			prev.includes(logId)
				? prev.filter((id) => id !== logId)
				: [...prev, logId],
		);
	};

	const onClose = () => {
		setOpen(false);
	};

	const handleSort = () => {
		setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
	};

	const sortedLogs = [...logs].sort((a, b) => {
		const dateA = new Date(a.execution_time ?? 0).getTime();
		const dateB = new Date(b.execution_time ?? 0).getTime();
		return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
	});

	return (
		<Flex direction="column" justify="center">
			<Flex justify="center" direction="column">
				<Text weight="bold" size="2" style={{ padding: "12px" }}>
					Previous Test Runs
				</Text>
				<div
					style={{
						width: "100%",
						borderColor: "#E0E0E0",
						height: "1px",
						backgroundColor: "#E0E0E0",
					}}
				/>
			</Flex>
			{logs && logs.length > 0 ? (
				<Flex direction="column">
					<Flex direction="column">
						<Flex
							align="center"
							style={{
								gap: "20px",
								background: "var(--gray-3)",
								padding: "12px 20px",
								width: "100%",
								flexBasis: "0",
							}}
						>
							<Flex
								style={{ gap: "6px", flex: 1, cursor: "pointer" }}
								align="center"
								onClick={handleSort}
							>
								<Text weight="bold">Time Stamp</Text>
								{sortOrder === "asc" ? (
									<Icon.ArrowUp size="15px" />
								) : (
									<Icon.ArrowDown size="15px" />
								)}
							</Flex>
							<Text weight="bold" style={{ flex: 1 }}>
								User Email
							</Text>
							<Text weight="bold" style={{ flex: 1 }}>
								Autopilot ID
							</Text>
							<Text weight="bold" style={{ flex: 1 }}>
								Status
							</Text>
							<Text weight="bold" style={{ flex: 1 }}>
								Apps
							</Text>
							<Text weight="bold" style={{ flex: 1 }}>
								Trace URL
							</Text>
							<Text weight="bold" style={{ flex: 0.5 }}>
								Output
							</Text>
						</Flex>
						<div
							style={{
								width: "100%",
								borderColor: "#E0E0E0",
								height: "1px",
								backgroundColor: "#E0E0E0",
							}}
						/>
					</Flex>
					{sortedLogs.map((log, index) => (
						<Flex key={log.id} direction="column">
							<Flex direction="column">
								<Flex
									align="center"
									style={{
										gap: "20px",
										padding: "8px 20px",
										cursor: "pointer",
										flexBasis: 0,
										width: "100%",
									}}
								>
									<Flex direction="column" style={{ gap: "4px", flex: 1 }}>
										<Text style={{ fontSize: "14px" }}>
											{new Date(log.execution_time ?? 0).toLocaleString()}
										</Text>
									</Flex>
									<Flex style={{ flex: 1 }}>
										<Text>{log.user_email}</Text>
									</Flex>
									<Flex style={{ flex: 1 }}>
										<Text>{log.autopilot_id}</Text>
									</Flex>
									<Flex style={{ flex: 1 }}>
										<Text>{log.status}</Text>
									</Flex>
									<Text
										style={{
											width: "180px",
											overflow: "hidden",
											whiteSpace: "normal",
											wordBreak: "break-word",
										}}
									>
										{log?.data?.apps?.join(", ") ||
											(log?.data?.apps && log.data.apps.length > 0
												? log.data.apps.join(", ")
												: "")}
									</Text>
									<Flex style={{ flex: 1, flexDirection: "row" }}>
										{log.trace_url && Array.isArray(log.trace_url) ? (
											log.trace_url.map((url, urlIndex) => (
												<a
													key={urlIndex}
													href={url}
													target="_blank"
													rel="noopener noreferrer"
													style={{
														color: "var(--mb-blue-8)",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
														maxWidth: "100%",
														padding: "0 5px",
													}}
													title={url}
												>
													{`Link ${urlIndex + 1}`}
												</a>
											))
										) : (
											<Text>No trace URL available</Text>
										)}
									</Flex>
									<Flex style={{ flex: 0.5, cursor: "pointer" }}>
										<Text
											onClick={() => toggleLogDetails(log.id ?? "")}
											style={{
												color: "var(--mb-blue-8)",
												textDecoration: "underline",
											}}
										>
											View
										</Text>
									</Flex>
								</Flex>
								{index !== logs.length - 1 && (
									<div
										style={{
											width: "100%",
											borderColor: "#E0E0E0",
											height: "1px",
											backgroundColor: "#E0E0E0",
										}}
									/>
								)}
							</Flex>
						</Flex>
					))}
				</Flex>
			) : (
				<Text
					style={{ textAlign: "center", padding: "20px 26px" }}
					weight="bold"
				>
					No Previous test runs available
				</Text>
			)}
			<Flex direction="column">
				<div
					style={{
						width: "100%",
						borderColor: "#E0E0E0",
						height: "1px",
						backgroundColor: "#E0E0E0",
					}}
				/>
				<Pagination
					page={page}
					total={total}
					perPage={perPage}
					onPageChange={onPageChange}
				/>
			</Flex>
			<LogsOutputDialog open={open} onClose={onClose} log={log} />
		</Flex>
	);
};
