import {
	SlackLogo,
	SalesforceLogo,
	HubspotLogo,
	LinearLogo,
	GoogleWorkspaceLogo,
	GoogleDriveLogo,
	GoogleDocsLogo,
	GoogleMeetLogo,
	GoogleSheetsLogo,
	GoogleCalendarLogo,
	GmailLogo,
} from "@mightybot/core-ui";

export const getIntegratoionById = (id: string) => {
	return IntegrationsAppData.find((integration) => integration.id === id);
};

export const IntegrationsAppData: {
	id: string;
	app_name: string;
	static_logo: React.ComponentType<React.SVGProps<SVGSVGElement>>;
	description: string;
}[] = [
	{
		id: "slack_app",
		app_name: "Slack",
		static_logo: SlackLogo,
		description: "Connect and collaborate with your team",
	},
	{
		id: "salesforce_app",
		app_name: "Salesforce",
		static_logo: SalesforceLogo,
		description:
			"Enriched CRM records, sync conversation data, including transcripts, sentiment, and topics, to boost sales productivity.",
	},
	{
		id: "hubspot_app",
		app_name: "Hubspot",
		static_logo: HubspotLogo,
		description:
			"Enrich your CRM data with valuable conversation insights, including transcripts, sentiment, and topics.",
	},
	{
		id: "linear_app",
		app_name: "Linear",
		static_logo: LinearLogo,
		description:
			"Streamline your team communication with AI-powered automation, notifications and insights.",
	},
	{
		id: "google",
		app_name: "Google Workspace",
		static_logo: GoogleWorkspaceLogo,
		description:
			"Enhance your workflow by enabling automated tasks, data analysis, and intelligent document generation.",
	},
	{
		id: "google_drive",
		app_name: "Google Drive",
		static_logo: GoogleDriveLogo,
		description:
			"Enrich your CRM data with valuable conversation insights, including transcripts, sentiment, and topics.",
	},
	{
		id: "google_docs",
		app_name: "Google Docs",
		static_logo: GoogleDocsLogo,
		description:
			"Enrich your CRM data with valuable conversation insights, including transcripts, sentiment, and topics.",
	},
	{
		id: "google_meet",
		app_name: "Google Meet",
		static_logo: GoogleMeetLogo,
		description:
			"Enrich your CRM data with valuable conversation insights, including transcripts, sentiment, and topics.",
	},
	{
		id: "google_sheets",
		app_name: "Google Sheets",
		static_logo: GoogleSheetsLogo,
		description:
			"Enrich your CRM data with valuable conversation insights, including transcripts, sentiment, and topics.",
	},
	{
		id: "google_calendar",
		app_name: "Google Calendar",
		static_logo: GoogleCalendarLogo,
		description:
			"Enrich your CRM data with valuable conversation insights, including transcripts, sentiment, and topics.",
	},
	{
		id: "gmail",
		app_name: "Gmail",
		static_logo: GmailLogo,
		description:
			"Enrich your CRM data with valuable conversation insights, including transcripts, sentiment, and topics.",
	},
];
