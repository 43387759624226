import React from "react";
import Chats from "../components/Chats";
import Layout from "../components/Layout";

const ChatsRoute: React.FC = () => {
	return (
		<Layout>
			<Chats />
		</Layout>
	);
};

export default ChatsRoute;
