import styled from "styled-components";
import { Flex, Icon } from "@mightybot/web-ui";

export const TruncatedMarkdownContainer = styled(Flex)`
	border-radius: 8px;
	gap: 5px;
`;

export const TruncatedMarkdownContent = styled(Flex)`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: var(--mb-gray-9);
	width: 100%;
`;

export const ShareButton = styled(Icon.ShareFat)`
	cursor: pointer;
	flex-shrink: 0;
	color: var(--mb-gray-9);
	transform: scaleX(-1);
	align-items: center;
`;
