import styled from "styled-components";

import { Flex } from "@mightybot/web-ui";

export const DatePickerWrapper = styled(Flex)`
	width: 100%;
	flex-direction: column;
	padding-bottom: 16px;
`;

export const PopoverContent = styled(Flex)`
	background: white;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const ActionButtons = styled(Flex)`
	justify-content: flex-end;
	gap: 8px;
	padding: 12px 0px 0px 0px;
	border-top: 1px solid var(--mb-gray-3);
`;

export const CalendarsContainer = styled(Flex)`
	gap: 16px;
`;
