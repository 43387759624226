import React, { useMemo } from "react";
import { Flex, Text } from "@mightybot/web-ui";

export const FilterDialog = ({ onDraftsClick, onEnterpriseClick }) => {
	const filterDialogData = [
		{
			title: "Enterprise",
			onClick: onEnterpriseClick,
		},
	];
	return (
		<Flex
			direction="column"
			align="start"
			style={{
				gap: "2px",
				border: "1px solid var(--mb-color-light-gray-5)",
				boxShadow:
					"0px 89px 25px 0px rgba(0, 0, 0, 0.00), 0px 57px 23px 0px rgba(0, 0, 0, 0.01), 0px 32px 19px 0px rgba(0, 0, 0, 0.05), 0px 14px 14px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.10)",
				borderRadius: "6px",
				padding: "6px 0px",
				width: "130px",
				backgroundColor: "#FFF",
			}}
		>
			<Text
				weight={"bold"}
				style={{
					fontSize: "12px",
					color: "var(--mb-color-dark-gray-6)",
					paddingLeft: "10px",
				}}
			>
				Filter
			</Text>
			{filterDialogData.map((item) => (
				<Flex
					key={item.title}
					justify="between"
					align="center"
					onClick={item.onClick}
					style={{
						cursor: "pointer",
						paddingLeft: "10px",
						gap: "4px",
						width: "100%",
						height: "30px",
					}}
				>
					<Text>{item.title}</Text>
				</Flex>
			))}
		</Flex>
	);
};
