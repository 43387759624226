export const CRON_KEYS = [
	{ id: "year", label: "Year" },
	{ id: "month", label: "Month" },
	{ id: "day", label: "Day" },
	{ id: "week", label: "Week" },
	{ id: "day_of_week", label: "Day of Week" },
	{ id: "hour", label: "Hour" },
	{ id: "minute", label: "Minute" },
	{ id: "second", label: "Second" },
];

export const INTERVAL_UNITS = [
	{
		id: "minutes",
		label: "minutes",
	},
	{
		id: "hours",
		label: "hours",
	},
	{
		id: "days",
		label: "days",
	},
	{
		id: "weeks",
		label: "weeks",
	},
	{
		id: "months",
		label: "months",
	},
	{
		id: "years",
		label: "years",
	},
];
