import { Flex, Text } from "@mightybot/web-ui";
import React from "react";

// UserInfoCard component
interface UserInfoCardProps {
	icon: React.ReactNode;
	label: string;
	value: string | undefined;
	link?: string;
}

const UserInfoCard: React.FC<UserInfoCardProps> = React.memo(
	({ icon, label, value, link }) => {
		const handleClick = React.useCallback(
			(e: React.MouseEvent<HTMLAnchorElement>) => {
				if (!link) {
					e.preventDefault();
				}
			},
			[link],
		);

		return (
			<Flex style={{ gap: "5px", width: "50%" }}>
				<Flex style={{ marginTop: "1px" }}>{icon}</Flex>
				<Flex direction="column">
					<Text
						size="2"
						weight="light"
						style={{ color: "var(--mb-color-light-gray-9)" }}
					>
						{label}
					</Text>
					{link ? (
						<a
							href={link}
							style={{ textDecoration: "none" }}
							onClick={handleClick}
							rel="noopener noreferrer"
							target="_blank"
							aria-label={`${label}: ${value}`}
						>
							<Text size="3" style={{ color: "var(--mb-color-light-blue-8)" }}>
								{value || "N/A"}
							</Text>
						</a>
					) : (
						<Text size="3" style={{ color: "var(--mb-color-light-blue-8)" }}>
							{value || "N/A"}
						</Text>
					)}
				</Flex>
			</Flex>
		);
	},
);

UserInfoCard.displayName = "UserInfoCard";

export default UserInfoCard;
