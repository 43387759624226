import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Flex, Text } from "@mightybot/web-ui";
import RecentEventsDialog from "./RecentEventsDialog";
import {
	AutopilotTestRunRequest,
	Event,
	useAutopilotConfig,
} from "@mightybot/core";
import { capitalize } from "../../utils/helpers";

interface RecentEventsViewProps {
	recentUserEvents: Event[];
	total: number;
	page: number;
	perPage: number;
	onTestRun: (data: AutopilotTestRunRequest) => Promise<boolean>;
	onPageChange: (page: number) => void;
}

export const RecentEventsView: React.FC<RecentEventsViewProps> = ({
	recentUserEvents,
	total,
	page,
	perPage,
	onTestRun,
	onPageChange,
}) => {
	const { data: config } = useAutopilotConfig();
	const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
	const [showModal, setShowModal] = useState(false);
	const { autopilotId } = useParams<{ autopilotId: string }>();

	const handleEventClick = (event: Event) => {
		setSelectedEvent(event);
		setShowModal(true);
	};

	const handleModalClose = () => {
		setShowModal(false);
		setSelectedEvent(null);
	};

	return (
		<Flex direction="column" style={{ gap: "8px" }}>
			<Text weight="medium" style={{ fontSize: "14px" }}>
				Select from recent events to test
			</Text>
			<Flex
				direction="column"
				style={{
					border: "1px solid var(--gray-5)",
					borderRadius: "4px",
					maxHeight: "400px",
					overflowY: "auto",
				}}
			>
				{recentUserEvents.map((event, index) => (
					<Flex direction="column" key={event.id}>
						<Flex
							style={{
								background: "#FFF",
								padding: "10px 12px",
								cursor: "pointer",
								flexBasis: "100%",
								borderRadius:
									index === 0
										? "4px 4px 0 0"
										: index === recentUserEvents.length - 1
											? "0 0 4px 4px"
											: "0",
							}}
							onClick={() => handleEventClick(event)}
						>
							<Text style={{ padding: "0 8px", flex: 1 }}>
								{capitalize(event?.event_data?.event_meeting_title)}
							</Text>
							<Text style={{ padding: "0 8px", flex: 1 }}>
								{config?.events?.find((e) => e.id === event?.event_name)?.label}
							</Text>
							<Text style={{ padding: "0 8px", flex: 1 }}>
								{new Date(event.created_ts).toLocaleString()}
							</Text>
						</Flex>
						{index !== recentUserEvents.length - 1 && (
							<div
								style={{
									width: "100%",
									borderColor: "var(--gray-5)",
									height: "1px",
									backgroundColor: "var(--gray-5)",
								}}
							/>
						)}
					</Flex>
				))}
			</Flex>
			<RecentEventsDialog
				showModal={showModal}
				handleModalClose={handleModalClose}
				selectedEvent={selectedEvent ?? ({} as Event)}
				onTestRun={onTestRun}
				autopilotId={autopilotId ?? ""}
			/>
		</Flex>
	);
};
