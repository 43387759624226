import React, { useState, useEffect } from "react";
import {
	Flex,
	Text,
	Icon,
	Checkbox,
	Button,
	Separator,
} from "@mightybot/web-ui";
import CategoriesMapping from "./CategoryMapping.json";
import { CategoryFilterContainer, FilterContainer } from "./styled";
import { IntegrationsAppData } from "../../constants";

interface FilterProps {
	onFilterChange: (
		selectedCategories: string[],
		selectedApps: string[],
		filterType?: "category" | "app",
		applyFilter?: boolean,
	) => void;
	initialSelectedCategories: string[];
	initialSelectedApps: string[];
	activeFilter: "category" | "app" | null;
}

const filterOptions = [
	// {
	// 	icon: Icon.SortAscending,
	// 	label: "Sort by",
	// },
	// {
	// 	icon: Icon.UserSquare,
	// 	label: "Lead Name",
	// },
	{
		icon: Icon.BellRinging,
		label: "Notification Type",
	},
	{
		icon: Icon.SquaresFour,
		label: "Apps",
	},
	// {
	// 	icon: Icon.EyeSlash,
	// 	label: "Hide read notifications",
	// },
];

const Filter: React.FC<FilterProps> = ({
	onFilterChange,
	initialSelectedCategories,
	initialSelectedApps,
	activeFilter,
}) => {
	const [selectedCategories, setSelectedCategories] = useState<string[]>(
		initialSelectedCategories,
	);
	const [selectedApps, setSelectedApps] =
		useState<string[]>(initialSelectedApps);

	useEffect(() => {
		setSelectedCategories(initialSelectedCategories);
		setSelectedApps(initialSelectedApps);
	}, [initialSelectedCategories, initialSelectedApps]);

	const toggleCategory = (category: string) => {
		setSelectedCategories((prev) =>
			prev.includes(category)
				? prev.filter((c) => c !== category)
				: [...prev, category],
		);
	};

	const toggleApp = (appId: string) => {
		setSelectedApps((prev) => {
			if (appId === "google") {
				const googleApps = [
					"gmail",
					"google_docs",
					"google_sheets",
					"google_drive",
					"google_meet",
					"google_calendar",
				];
				const allGoogleAppsSelected = googleApps.every((app) =>
					prev.includes(app),
				);
				return allGoogleAppsSelected
					? prev.filter((app) => !googleApps.includes(app))
					: [...new Set([...prev, ...googleApps])];
			}
			return prev.includes(appId)
				? prev.filter((a) => a !== appId)
				: [...prev, appId];
		});
	};

	const applyFilter = () => {
		onFilterChange(
			selectedCategories,
			selectedApps,
			activeFilter || undefined,
			true,
		);
	};

	const toggleAllCategories = () => {
		const newCategories =
			selectedCategories.length === Object.keys(CategoriesMapping).length
				? []
				: Object.keys(CategoriesMapping);
		setSelectedCategories(newCategories);
		onFilterChange(newCategories, selectedApps, "category");
	};

	const toggleAllApps = () => {
		const newApps =
			selectedApps.length === IntegrationsAppData.length
				? []
				: IntegrationsAppData.map((app) => app.id);
		setSelectedApps(newApps);
		onFilterChange(selectedCategories, newApps, "app");
	};

	const resetFilters = (type?: "category" | "app") => {
		if (type === "category") {
			setSelectedCategories([]);
			onFilterChange([], selectedApps, "category");
		} else if (type === "app") {
			setSelectedApps([]);
			onFilterChange(selectedCategories, [], "app");
		} else {
			setSelectedCategories([]);
			setSelectedApps([]);
			onFilterChange([], []);
		}
	};

	const isFilterApplied = () => {
		return selectedCategories.length > 0 || selectedApps.length > 0;
	};

	const renderCategoryFilter = () => (
		<CategoryFilterContainer>
			<Text size="3" weight="medium">
				Notification Type
			</Text>
			<Flex align="center" justify="between" style={{ marginBottom: "5px" }}>
				<Flex align="center" style={{ gap: "5px" }}>
					<Checkbox
						checked={
							selectedCategories.length ===
							Object.keys(CategoriesMapping).length
						}
						onCheckedChange={toggleAllCategories}
					/>
					<Text>Select All</Text>
				</Flex>
				{/* <Button variant="ghost" onClick={() => resetFilters("category")}>
					Reset
				</Button> */}
			</Flex>
			{Object.keys(CategoriesMapping).map((category) => (
				<Flex align="center" style={{ gap: "5px" }} key={category}>
					<Checkbox
						checked={selectedCategories.includes(category)}
						onCheckedChange={() => toggleCategory(category)}
					/>
					<Text>{category}</Text>
				</Flex>
			))}
			<Button onClick={applyFilter} style={{ marginTop: "10px" }}>
				Apply
			</Button>
		</CategoryFilterContainer>
	);

	const renderAppsFilter = () => (
		<CategoryFilterContainer>
			<Text size="3" weight="medium">
				Apps
			</Text>
			<Flex align="center" justify="between" style={{ marginBottom: "5px" }}>
				<Flex align="center" style={{ gap: "5px" }}>
					<Checkbox
						checked={selectedApps.length === IntegrationsAppData.length}
						onCheckedChange={toggleAllApps}
					/>
					<Text>Select All</Text>
				</Flex>
				{/* <Button variant="ghost" onClick={() => resetFilters("app")}>
					Reset
				</Button> */}
			</Flex>
			{IntegrationsAppData.map((app) => (
				<Flex align="center" style={{ gap: "5px" }} key={app.id}>
					<Checkbox
						checked={
							app.id === "google"
								? [
										"gmail",
										"google_docs",
										"google_sheets",
										"google_drive",
										"google_meet",
										"google_calendar",
									].every((googleApp) => selectedApps.includes(googleApp))
								: selectedApps.includes(app.id)
						}
						onCheckedChange={() => toggleApp(app.id)}
					/>
					<Text>{app.app_name}</Text>
				</Flex>
			))}
			<Button onClick={applyFilter} style={{ marginTop: "10px" }}>
				Apply
			</Button>
		</CategoryFilterContainer>
	);

	if (activeFilter === "category") {
		return renderCategoryFilter();
	}

	if (activeFilter === "app") {
		return renderAppsFilter();
	}

	return (
		<FilterContainer>
			{filterOptions.map((option) => (
				<Flex
					align="center"
					style={{ gap: "5px", cursor: "pointer" }}
					key={option.label}
					onClick={() => {
						if (option.label === "Notification Type") {
							onFilterChange(selectedCategories, selectedApps, "category");
						} else if (option.label === "Apps") {
							onFilterChange(selectedCategories, selectedApps, "app");
						}
					}}
				>
					<option.icon size={20} />
					<Text>{option.label}</Text>
					{option.label === "Notification Type" &&
						selectedCategories.length > 0 && (
							<Text
								size="1"
								style={{ marginLeft: "5px", color: "var(--mb-blue-9)" }}
							>
								({selectedCategories.length})
							</Text>
						)}
					{option.label === "Apps" && selectedApps.length > 0 && (
						<Text
							size="1"
							style={{ marginLeft: "5px", color: "var(--mb-blue-9)" }}
						>
							({selectedApps.length})
						</Text>
					)}
				</Flex>
			))}
			{isFilterApplied() && (
				<>
					<Separator style={{ margin: "2px 0", width: "100%" }} />
					<Flex align="center" justify="center">
						<Button variant="ghost" onClick={() => resetFilters()}>
							Reset All Filters
						</Button>
					</Flex>
				</>
			)}
		</FilterContainer>
	);
};

export default Filter;
