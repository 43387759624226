import React, { useState } from "react";
import { ChatReplyContainer, CopyIcon } from "./styled";
import { CustomReactMarkdown, Flex, Icon, Tooltip } from "@mightybot/web-ui";

type ChatReplyProps = {
	reply: string;
	isStreaming?: boolean;
};

const ChatReply: React.FC<ChatReplyProps> = ({ reply, isStreaming }) => {
	const [isCopied, setIsCopied] = useState(false);

	const displayReply = isStreaming ? `${reply}•` : reply;

	return (
		<ChatReplyContainer>
			<Flex direction="column" width="100%">
				<CustomReactMarkdown>{displayReply}</CustomReactMarkdown>
				<Flex align="center" justify="between">
					<Flex justify="center" align="center">
						<Tooltip content="Copy" side="top">
							{isCopied ? (
								<Icon.Check />
							) : (
								<CopyIcon
									onClick={() => {
										navigator.clipboard.writeText(reply);
										setIsCopied(true);
										setTimeout(() => {
											setIsCopied(false);
										}, 2000);
									}}
								/>
							)}
						</Tooltip>
					</Flex>
					{/* <Flex>
						<Icon.ThumbsUp style={{ cursor: "pointer", marginRight: "10px" }} />
						<Icon.ThumbsDown
							style={{ cursor: "pointer", marginRight: "2px" }}
						/>
					</Flex> */}
				</Flex>
			</Flex>
		</ChatReplyContainer>
	);
};

export default ChatReply;
