import React from "react";
import {
	StyledToast,
	StyledToastDescription,
	StyledToastTitle,
	StyledToastViewport,
} from "./styled";

type ToastProps = {
	open: boolean;
	setOpen: any;
	description: string;
	title: string;
	duration?: number;
	type?: "success" | "error" | "info";
};

const Toast = ({
	open,
	setOpen,
	description,
	title,
	duration = 5000,
	type = "success",
}: ToastProps) => {
	return (
		<>
			<StyledToast
				open={open}
				onOpenChange={setOpen}
				duration={duration}
				style={{
					background:
						type === "success"
							? "rgb(0, 0, 0, 0.85)"
							: type === "error"
								? "rgb(255, 0, 0, 0.85)"
								: "rgb(0, 0, 255, 0.85)",
					marginBottom: "5vw",
				}}
			>
				<StyledToastTitle>{title}</StyledToastTitle>
				<StyledToastDescription>{description}</StyledToastDescription>
			</StyledToast>
			<StyledToastViewport />
		</>
	);
};

export default Toast;
