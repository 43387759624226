import React, { useEffect, useRef, useState } from "react";
import {
	ChatsContainer,
	ChatsContentContainer,
	ChatsInnerContainer,
} from "./styled";
import Placeholder from "../../utils/Placeholder";
import { PlaceholderChatsPage } from "@mightybot/core-ui";
import { CHATS_PAGE_FEATURE, useFeatureFlag } from "@mightybot/core";
import ChatsMessages from "./ChatsMessages";
import ChatsInput from "./ChatsInput";
import ChatsSidebar from "./ChatsSidebar";
import ChatsHeader from "./ChatsHeader";
import { StreamingProvider } from "../../contexts/StreamingContext";
import { useParams } from "react-router-dom";

const Chats: React.FC = () => {
	const showChatsPage = useFeatureFlag(CHATS_PAGE_FEATURE);
	const contentRef = useRef<HTMLDivElement>(null);
	const { chatId } = useParams();
	const [showGoToEnd, setShowGoToEnd] = useState(false);

	const scrollToBottom = (behavior: ScrollBehavior = "auto") => {
		const container = contentRef.current;
		if (!container) return;

		const scrollHeight = container.scrollHeight;
		const height = container.clientHeight;
		const maxScrollTop = scrollHeight - height;
		container.scrollTo({
			top: maxScrollTop > 0 ? maxScrollTop : 0,
			behavior,
		});
	};

	const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
		const container = event.currentTarget;
		const { scrollTop, scrollHeight, clientHeight } = container;
		const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
		const isAtBottom = distanceFromBottom < 100;

		setShowGoToEnd(!isAtBottom);
	};

	// Handle mutations for auto-scroll
	useEffect(() => {
		const container = contentRef.current;
		if (!container) return;

		const isNearBottom = () => {
			const { scrollTop, scrollHeight, clientHeight } = container;
			const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
			return distanceFromBottom < 100;
		};

		const observer = new MutationObserver(() => {
			// Only auto-scroll if we're already near the bottom
			if (isNearBottom()) {
				scrollToBottom();
			}
		});

		observer.observe(container, {
			childList: true,
			subtree: true,
			characterData: true,
		});

		scrollToBottom();

		return () => observer.disconnect();
	}, [chatId]);

	const handleGoToEnd = () => {
		scrollToBottom("smooth");
		setShowGoToEnd(false);
	};

	if (!showChatsPage) {
		return (
			<ChatsContainer>
				<Placeholder
					placeholder={PlaceholderChatsPage}
					style={{ justifyContent: "center" }}
				/>
			</ChatsContainer>
		);
	}

	return (
		<ChatsContainer>
			<StreamingProvider>
				<ChatsSidebar />
				<ChatsContentContainer
					id="chats-content-container"
					ref={contentRef}
					data-testid="chat-scroll-container"
					onScroll={handleScroll}
					newchat={!chatId ? "true" : "false"}
				>
					<ChatsHeader />
					<ChatsInnerContainer>
						<ChatsMessages />
						<ChatsInput
							handleGoToEnd={handleGoToEnd}
							showGoToEnd={showGoToEnd}
						/>
					</ChatsInnerContainer>
				</ChatsContentContainer>
			</StreamingProvider>
		</ChatsContainer>
	);
};

export default Chats;
