import styled from "styled-components";
import { Flex, Text } from "@mightybot/web-ui";
import { mediaMaxWidth, mediaQueries } from "@mightybot/web-ui";

export const MeetingDetailsSidebarContainer = styled(Flex)`
	flex-direction: column;
	gap: 10px;
	width: 100%;
	transition: max-width 0.3s ease-in-out;
	padding: 10px 0px;
`;

export const MeetingOutlineContainer = styled(Flex)`
	width: 100%;
	flex-direction: column;
	background-color: var(--mb-color-light-blue-1);
	border-radius: 20px;
	padding-bottom: 16px;
	height: 100vh;
	gap: 3px;
	overflow: scroll;
	transition: all 0.2s ease;
`;

export const MeetingOutlineContentContainer = styled(Flex)`
	overflow-y: hidden;
	flex-direction: column;
`;

export const MeetingOutlineItemText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 80%;
	&:hover {
		overflow: visible;
		white-space: normal;
		word-break: break-word;
	}
	${mediaQueries.tablet} {
		&:hover {
			white-space: unset;
		}
	}
`;

export const MeetingOutlineHeaderContainer = styled(Flex)`
	padding: 0px 0px 10px 16px;
	position: sticky;
	top: 0;
	gap: 8px;
	background-color: var(--mb-color-light-blue-1);
	padding: 20px 10px 10px 26px;
	align-items: center;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const MeetingOutlineItem = styled(Flex)<{ selected: string }>`
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
	gap: 10px;
	padding: 8px 16px;
	border-radius: var(--radius-pill);
	height: 40px;
	flex-shrink: 0;
	transition: background-color 0.2s ease;
	background-color: ${({ selected }) =>
		selected === "true" ? "var(--mb-color-light-blue-2)" : "transparent"};

	white-space: nowrap;
	overflow: hidden;

	&:hover {
		background-color: var(--mb-color-light-blue-2);
		color: var(--mb-color-light-blue-8);
		div {
			background-color: var(--mb-color-light-blue-7);
		}
	}
`;

export const Cube = styled(Flex)<{ color: string }>`
	flex-shrink: 0;
	width: 8px;
	height: 8px;
	background-color: ${({ color }) =>
		color === "blue"
			? "var(--mb-color-light-blue-9)"
			: "var(--mb-color-light-blue-1)"};
	margin-right: 6px;
	border-radius: 2px;
	transition: background-color 0.2s ease;
`;

export const MeetingOutlineSidebarToggleButton = styled(Flex)`
	background-color: var(--mb-color-light-blue-2);
	border-radius: 50%;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 14px;
`;

export const MeetingOutlineItemsContainer = styled(Flex)`
	gap: 2px;
	padding: 0px 10px;
	overflow: scroll;

	/* Webkit browsers (Chrome, Safari, etc.) */
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 5px;
	}

	/* Firefox */
	scrollbar-width: thin;
	scrollbar-color: #888 transparent;
	${mediaQueries.tablet} {
		height: 100%;
		align-items: center;
	}
`;

export const MobileSidebarContainer = styled.div`
	position: sticky;
	top: 0;
	z-index: 10;
	width: 100%;
	background-color: var(--mb-gray-1);
	max-height: 100px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

export const MobileOutlineBar = styled(Flex)`
	align-items: center;
	justify-content: space-between;
	padding: 12px;
	background-color: var(--mb-gray-1);
	cursor: pointer;
	height: 50px;
	border-bottom: 1px solid var(--mb-gray-5);
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

export const MobileOutlineContent = styled(Flex)<{ isOpen: boolean }>`
	max-height: ${({ isOpen }) => (isOpen ? "50px" : "0")};
	height: 50px;
	overflow: scroll;
	transition: max-height 0.3s ease;
	background: linear-gradient(
		to bottom,
		var(--mb-gray-2) 0%,
		var(--mb-gray-1) 100%
	);
	height: 50px;
	align-items: center;
	box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
	${mediaQueries.tablet} {
		scrollbar-width: none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}
		padding: 0px 10px;
	}
`;

export const MobileMeetingOutlineItem = styled(Flex)<{ selected: string }>`
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	background-color: ${({ selected }) =>
		selected === "true" ? "var(--mb-color-light-blue-2)" : "transparent"};

	${mediaMaxWidth(480)`
		flex-shrink: 0;
		padding: 8px 12px;
		border-bottom: 1px solid var(--mb-gray-7);
		border-radius: 16px;
		margin-right: 8px;
	`}
`;
