import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useEffect,
} from "react";
import { MeetingContentItem, Context } from "@mightybot/core";

interface HoverData {
	type: "title" | "paragraph";
	content: string;
	item: MeetingContentItem;
}

interface FollowUpContext extends Context {
	prompt?: string;
}

interface MeetingContentHoverContextType {
	highlightedItem: string | null;
	setHighlightedItem: (id: string | null) => void;
	hoverData: HoverData | null;
	setHoverData: (data: HoverData | null) => void;
	followUpClicked: boolean;
	handleFollowUp: () => void;
	followUpContext: FollowUpContext | null;
	setFollowUpContext: (data: FollowUpContext | null) => void;
}

const MeetingContentHoverContext = createContext<
	MeetingContentHoverContextType | undefined
>(undefined);

export const MeetingContentHoverProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [highlightedItem, setHighlightedItem] = useState<string | null>(null);
	const [hoverData, setHoverData] = useState<HoverData | null>(null);
	const [followUpClicked, setFollowUpClicked] = useState<boolean>(false);
	const [followUpContext, setFollowUpContext] =
		useState<FollowUpContext | null>(null);

	const handleFollowUp = () => {
		setFollowUpClicked(true);
	};

	useEffect(() => {
		if (followUpClicked) {
			const contextType = highlightedItem
				? "plain-text"
				: hoverData?.type === "title"
					? "contentIds"
					: "plain-text";
			const contextValue = highlightedItem
				? highlightedItem
				: hoverData?.type === "title"
					? [hoverData.item.id]
					: hoverData?.content;
			const prompt =
				hoverData?.type === "title" ? hoverData.item.title : hoverData?.content;
			setFollowUpContext({
				type: contextType,
				value: contextValue ?? "",
				prompt: prompt ?? "",
			});
		}
		setFollowUpClicked(false);
	}, [followUpClicked, hoverData]);

	return (
		<MeetingContentHoverContext.Provider
			value={{
				highlightedItem,
				setHighlightedItem,
				hoverData,
				setHoverData,
				followUpClicked,
				followUpContext,
				setFollowUpContext,
				handleFollowUp,
			}}
		>
			{children}
		</MeetingContentHoverContext.Provider>
	);
};

export const useMeetingContentHover = () => {
	const context = useContext(MeetingContentHoverContext);
	if (context === undefined) {
		throw new Error(
			"useMeetingContentHover must be used within a MeetingContentHoverProvider",
		);
	}
	return context;
};
