import React from "react";
import { CompaniesContainer } from "./styled";
import Placeholder from "../../utils/Placeholder";
import { PlaceholderCompaniesPage } from "@mightybot/core-ui";

const Companies: React.FC = () => {
	return (
		<CompaniesContainer>
			<Placeholder
				placeholder={PlaceholderCompaniesPage}
				style={{ alignItems: "flex-start" }}
				imageStyle={{ height: "auto" }}
			/>
		</CompaniesContainer>
	);
};

export default Companies;
