import React from "react";
import { useCompletionsHistory } from "@mightybot/core";
import MeetingChat from "../MeetingChat";
import { MeetingChatsContainer } from "./styled";
import { useParams } from "react-router-dom";
import { formatTimestamp } from "../../utils/helpers";
import MeetingChatStreaming from "../MeetingChatStreaming";

interface MeetingChatsProps {}

const MeetingChats: React.FC<MeetingChatsProps> = () => {
	const meetingId = useParams().meetingId;

	const {
		completionHistory,
		currentCompletions,
		updateCurrentCompletionsItem,
	} = useCompletionsHistory(meetingId ?? "", "MEETING");

	return (
		<MeetingChatsContainer>
			{completionHistory?.map((item, index) => (
				<MeetingChat
					key={index}
					author={item.author}
					timestamp={formatTimestamp(item.created_at)}
					content={item.context?.prompt ?? ""}
					question={item.user_question ?? ""}
					reply={item.response ?? ""}
				/>
			))}
			{currentCompletions?.map((item, index) => (
				<MeetingChatStreaming
					completionItem={item}
					updateCurrentCompletionsItem={updateCurrentCompletionsItem}
				/>
			))}
		</MeetingChatsContainer>
	);
};

export default MeetingChats;
