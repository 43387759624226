import React from "react";
import { Outlet } from "react-router-dom";
import MetaTags from "../utils/MetaTags";

function RootRoute() {
	return (
		<React.Fragment>
			<MetaTags />
			<Outlet />
		</React.Fragment>
	);
}

export default RootRoute;
