import { useMe, isPublicPage as isCorePublicPage } from "@mightybot/core";
import { posthog } from "posthog-js";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ErrorFallback from "../components/ErrorFallback";
import Loading from "../components/Loading";

export default function AuthenticatedRoute() {
	const { data: user, isLoading, isError } = useMe();
	const location = useLocation();
	const isPublicPage = isCorePublicPage(location.pathname);
	const navigate = useNavigate();

	const isAdminPage = location.pathname.includes("/admin");

	useEffect(() => {
		if (user) {
			posthog.identify(user.id, {
				email: user.email,
			});
		}
	}, [user]);

	useEffect(() => {
		if (!isLoading && (!user || isError)) {
			// Store the current path in localStorage before redirecting
			!isPublicPage &&
				localStorage.setItem(
					"intendedDestination",
					location.pathname + location.search,
				);
			navigate("/login");
		}
	}, [isLoading, user, isError, location, navigate]);

	if (isLoading) {
		return <Loading />;
	}

	if (isAdminPage && user?.is_admin !== true) {
		return <ErrorFallback errorType="notAdmin" />;
	}

	if (user) {
		return (
			<React.Fragment>
				<Outlet />
			</React.Fragment>
		);
	}

	return null;
}
