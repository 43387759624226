import React from "react";
import { useMetaTags } from "@mightybot/core";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const MetaTags: React.FC = () => {
	const { pathname } = useLocation();

	const metaTags = useMetaTags(pathname);

	return (
		<Helmet>
			{/* Basic Meta Tags */}
			<title>{metaTags.title}</title>
			<meta name="description" content={metaTags.description} />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta charSet="utf-8" />
			<meta name="theme-color" content="#000000" />
			<link rel="canonical" href={`https://www.mightybot.ai${pathname}`} />

			{/* Open Graph / Facebook */}
			<meta property="og:type" content="website" />
			<meta property="og:url" content={`https://www.mightybot.ai${pathname}`} />
			<meta property="og:title" content={metaTags.ogTitle} />
			<meta property="og:description" content={metaTags.ogDescription} />
			{/* TODO: Add og image */}
			{/* <meta property="og:image" content={metaTags.ogImage} /> */}

			{/* Twitter */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta property="twitter:domain" content="mightybot.ai" />
			<meta
				property="twitter:url"
				content={`https://www.mightybot.ai${pathname}`}
			/>
			<meta name="twitter:title" content={metaTags.twitterTitle} />
			<meta name="twitter:description" content={metaTags.twitterDescription} />
			{/* TODO: Add twitter image */}
			{/* <meta name="twitter:image" content={metaTags.twitterImage} /> */}

			{/* Additional SEO tags */}
			<meta name="robots" content="index, follow" />
			<meta name="author" content="MightyBot" />

			{/* Favicon */}
			<link rel="icon" href="/favicon.ico" />
			<link rel="apple-touch-icon" href="/apple-touch-icon.png" />
		</Helmet>
	);
};

export default MetaTags;
