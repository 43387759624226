import React from "react";
import {
	ShareButton,
	TruncatedMarkdownContainer,
	TruncatedMarkdownContent,
} from "./styled";
import { CustomReactMarkdown, Icon } from "@mightybot/web-ui";

type TruncatedMarkdownProps = {
	content: string;
	showCloseButton?: boolean;
	handleCloseButton?: () => void;
	isPreview?: boolean;
	singleLine?: boolean;
};

const TruncatedMarkdown: React.FC<TruncatedMarkdownProps> = ({
	content,
	showCloseButton = false,
	handleCloseButton,
	isPreview = false,
	singleLine = false,
}) => {
	const truncatedContent = String(content).split("\n\n")[0];

	const handleClick = () => {
		try {
			let element = document.querySelector(
				`[data-content="${truncatedContent}"]`,
			);

			if (!element) {
				element = document.querySelector(`[data-content="${content}"]`);
			}
			if (element) {
				// Remove highlight from previously focused element
				document
					.querySelector(".focused-content")
					?.classList.remove("focused-content");
				// Add highlight to the new focused element
				element.classList.add("focused-content");
				element.scrollIntoView({ behavior: "smooth", block: "center" });
			} else {
				console.info("No matching element found for scrolling");
			}
		} catch (error) {
			console.error("Error while trying to scroll to content:", error);
		}
	};

	const handleClose = (e: React.MouseEvent<SVGSVGElement>) => {
		e.stopPropagation();
		handleCloseButton?.();
	};

	return (
		<TruncatedMarkdownContainer
			align="center"
			style={{
				width: "100%",
				padding: isPreview ? "0px" : "10px",
				background: isPreview
					? "none"
					: "linear-gradient(90deg, #f3f6e9 0%, #e4edfc 100%)",
			}}
			onClick={isPreview ? undefined : handleClick}
		>
			{!isPreview && <ShareButton size={20} style={{ flexShrink: 0 }} />}
			<TruncatedMarkdownContent style={{ cursor: "pointer" }}>
				<CustomReactMarkdown
					styles={{
						content: {
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							width: "100%",
						},
						paragraph: {
							margin: 0,
							display: "inline",
						},
						heading: {
							margin: 0,
							fontWeight: 400,
							fontSize: 14,
						},
					}}
				>
					{truncatedContent}
				</CustomReactMarkdown>
			</TruncatedMarkdownContent>
			{showCloseButton && (
				<Icon.XCircle
					size={32}
					weight="thin"
					style={{ flexShrink: 0, marginLeft: "8px", cursor: "pointer" }}
					onClick={handleClose}
				/>
			)}
		</TruncatedMarkdownContainer>
	);
};

export default TruncatedMarkdown;
