import styled from "styled-components";
import { Flex } from "@mightybot/web-ui";

export const WidgetContainer = styled(Flex)<{ $isVisible: boolean }>`
	position: absolute;
	padding: 3px;
	border-radius: 7px;
	max-width: 44px;
	max-height: 158px;
	background: linear-gradient(180deg, #0b265c 0%, #1851c2 100%);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	z-index: 1000;
	flex-direction: column;
	gap: 4px;
	opacity: 0;
	transform: translateX(-10px);
	transition:
		opacity 0.2s ease-out,
		transform 0.2s ease-out;

	${({ $isVisible }) =>
		$isVisible &&
		`
		opacity: 1;
		transform: translateX(0);
	`}
`;
