import React, { useRef, useEffect, useState } from "react";
import { Icon, Flex, TextArea, Tooltip, IconButton } from "@mightybot/web-ui";
import {
	MeetingContentPromptInnerContainer,
	MeetingContentPromptOuterContainer,
} from "./styled";
import { useMeetingChat } from "../../contexts/MeetingChatContext";
import TruncatedMarkdown from "../TruncatedMarkdown";
import { useParams } from "react-router-dom";
import { useCompletionsHistory, useMe } from "@mightybot/core";
import { useMeetingContentHover } from "../../contexts/MeetingContentHoverContext";

interface MeetingContentPromptProps {}

const INITIAL_HEIGHT = 77;

const MeetingContentPrompt: React.FC<MeetingContentPromptProps> = ({}) => {
	const { data: user } = useMe();
	const meetingId = useParams().meetingId;
	const { addCurrentCompletionsItems } = useCompletionsHistory(
		meetingId ?? "",
		"MEETING",
	);
	const { triggerStreamReply } = useMeetingChat();
	const { followUpContext, setFollowUpContext } = useMeetingContentHover();
	const [input, setInput] = useState("");

	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const maxHeight = window.innerHeight + 100;

	useEffect(() => {
		if (textAreaRef.current) {
			adjustHeight(textAreaRef.current);
		}
	}, [followUpContext?.prompt, input]);

	const adjustHeight = (textarea: HTMLTextAreaElement) => {
		textarea.style.height = "auto";
		const newHeight = Math.min(
			textarea.scrollHeight < INITIAL_HEIGHT
				? INITIAL_HEIGHT
				: textarea.scrollHeight,
			maxHeight,
		);
		textarea.style.height = `${newHeight}px`;
	};

	const handleSend = () => {
		if (input.trim()) {
			// Add temporary completion item
			const completionCurrentItem = {
				id: Date.now().toString(),
				user_question: input.replace(/\n/g, "\\n"), // Replace newlines with \n
				timestamp: new Date().toISOString(),
				context: followUpContext,
				prompt: null,
				author: user,
			};

			addCurrentCompletionsItems(completionCurrentItem);
			setFollowUpContext(null);
			triggerStreamReply();
			setInput("");
			if (textAreaRef.current) {
				textAreaRef.current.style.height = `${INITIAL_HEIGHT}px`;
			}
		}
	};

	const handleCloseButton = () => {
		setFollowUpContext(null);
		if (textAreaRef.current) {
			textAreaRef.current.style.height = `${INITIAL_HEIGHT}px`;
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === "Enter" && e.shiftKey) {
			e.preventDefault();
			const { selectionStart, selectionEnd } = e.currentTarget;
			const newValue =
				input.slice(0, selectionStart) + "\n" + input.slice(selectionEnd);
			setInput(newValue);

			// Set cursor position after the inserted newline
			setTimeout(() => {
				if (textAreaRef.current) {
					textAreaRef.current.selectionStart =
						textAreaRef.current.selectionEnd = selectionStart + 1;
				}
			}, 0);
		} else if (e.key === "Enter" && !e.shiftKey && input.trim()) {
			e.preventDefault();
			handleSend();
		}
	};

	return (
		<MeetingContentPromptOuterContainer direction="column" id="content-prompt">
			<MeetingContentPromptInnerContainer>
				{followUpContext && followUpContext.prompt && (
					<TruncatedMarkdown
						content={followUpContext.prompt}
						showCloseButton={true}
						handleCloseButton={handleCloseButton}
						singleLine
					/>
				)}

				<Flex direction="row" align="end" style={{ width: "100%" }}>
					<TextArea
						ref={textAreaRef}
						placeholder="Ask - Deep dive and provide Revenue and Sales growth of the company"
						style={{
							width: "100%",
							border: "none",
							boxShadow: "none",
							minHeight: `${INITIAL_HEIGHT}px`,
							maxHeight: `30vh`,
							height: "100%",
							overflow: "hidden",
							outline: "none",
							resize: "none",
						}}
						value={input}
						onChange={(e) => {
							setInput(e.target.value);
							adjustHeight(e.target);
						}}
						onKeyDown={handleKeyDown}
					/>
					<Tooltip content="Send">
						<IconButton
							id="chat-input-send"
							disabled={!input?.length}
							style={{
								width: 40,
								height: 34,
								borderRadius: 12,
							}}
							onClick={handleSend}
						>
							<Icon.PaperPlaneRight weight="fill" />
						</IconButton>
					</Tooltip>
				</Flex>
			</MeetingContentPromptInnerContainer>
		</MeetingContentPromptOuterContainer>
	);
};

export default MeetingContentPrompt;
